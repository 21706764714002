import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";

export type JoinEvent = { type: "join" | "new"; id: number };

@Component({
    selector: "app-register-join-committee",
    templateUrl: "./register-join-committee.component.html",
    styleUrls: ["./register-join-committee.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class RegisterJoinCommitteeComponent implements OnInit {
    @Input() disabled: boolean = false;
    @Input() showCoveredOfficial: boolean = false;
    @Output() CommJoinNewEmit = new EventEmitter<JoinEvent>();

    ngOnInit(): void { }

    committeeNewJoinEvent(type: "join" | "new", id: number) {
        this.CommJoinNewEmit.emit({ type, id });
    }
}
