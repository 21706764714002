import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    Output,
    Renderer2,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { TranslocoService } from "@ngneat/transloco";

@Component({
    selector: "app-toggleable-password-field",
    templateUrl: "./toggleable-password-field.component.html",
    styleUrls: ["./toggleable-password-field.component.scss"],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToggleablePasswordFieldComponent),
            multi: true,
        },
    ],
})
export class ToggleablePasswordFieldComponent implements ControlValueAccessor {
    @ViewChild("innerInput", { static: true })
    input: ElementRef<HTMLInputElement>;

    @Input() minlength = 8;
    @Input() maxlength = 16;

    @Input() placeholder = this.i18n.translate(
        "manageAccount.setPassword.password"
    );

    @Input() showPassword: boolean;
    @Output() showPasswordChange = new EventEmitter<boolean>();

    @Output() keyup = new EventEmitter<unknown>();

    constructor(
        private readonly i18n: TranslocoService,
        private readonly renderer: Renderer2
    ) {}

    toggle(): void {
        this.showPassword = !this.showPassword;
        this.showPasswordChange.emit(this.showPassword);
    }

    focus(): void {
        this.input.nativeElement?.focus();
    }

    forwardKey(event: unknown): void {
        this.keyup.emit(event);
    }

    writeValue(value: any): void {
        this.renderer.setProperty(this.input.nativeElement, "value", value);
    }

    private onChangeCallback: (_: any) => void;

    onChange(ev: any) {
        this.onChangeCallback(ev.target.value);
    }

    registerOnChange(fn: (_: any) => void): void {
        this.onChangeCallback = fn;
    }

    private onTouchedCallback: (_: any) => void;

    onTouched(ev: any) {
        this.onTouchedCallback(ev);
    }

    registerOnTouched(fn: (_: any) => void): void {
        this.onTouchedCallback = fn;
    }

    setDisabledState(value: boolean): void {
        this.renderer.setProperty(this.input.nativeElement, "disabled", value);
    }
}
