import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable, of, partition, merge, Subject } from 'rxjs';
import { catchError, filter, mapTo, switchMap, shareReplay, tap, startWith, share } from 'rxjs/operators';
import { SnackbarService } from 'src/app/core';
import { UserService } from 'src/app/core/services/user-service';

@Component({
  selector: 'app-confirm-email-form',
  templateUrl: './confirm-email-form.component.html',
  styleUrls: ['./confirm-email-form.component.scss']
})
export class ConfirmEmailFormComponent implements OnInit {
  @Input() email: string;
  @Output() submit: EventEmitter<void> = new EventEmitter<void>();

  form: FormControl;
  private confirmEmailSubject$: Subject<boolean> = new Subject<boolean>();
  sendingConfirmRequest$: Observable<boolean>;

  private resendCodeSubject$: Subject<boolean> = new Subject<boolean>();
  sendingResendCodeRequest$: Observable<boolean>;

  constructor(
    private readonly userService: UserService,
    private readonly snackbarService: SnackbarService,
    private readonly translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.form = new FormControl(null, [Validators.required]);

    const confirmingEmail$ = this.confirmEmailSubject$.pipe(
      filter(x => x),
      switchMap(this.sendConfirmation),
      share()
    );

    const [success$, fail$] = partition(confirmingEmail$, x => x);

    this.sendingConfirmRequest$ = merge(
      success$.pipe(tap(_ => this.submit.emit()), mapTo(false)),
      fail$,
      this.confirmEmailSubject$.pipe(filter(x => x))
    ).pipe(startWith(false), share());

    const resendCode$ = this.resendCodeSubject$.pipe(
      filter(x => x),
      switchMap(this.resendConfirmationCode),
      share()
    );

    const [resendSuccess$, resendFail$] = partition(resendCode$, x => x);

    this.sendingResendCodeRequest$ = merge(
      resendSuccess$.pipe(
        tap(_ => this.snackbarService.snackbarInfo(this.translocoService.translate('confirmEmail.pleaseCheckYourEmail'))), 
        mapTo(false)
      ),
      resendFail$,
      this.resendCodeSubject$.pipe(filter(x => x))
    ).pipe(startWith(false), share());
  }

  confirmEmail = () => this.confirmEmailSubject$.next(true);

  sendConfirmation = () => this.userService.confirmRegistration(this.form.value.trim(), this.email).pipe(
    mapTo(true),
    catchError(_ => {
      this.snackbarService.snackbarError(this.translocoService.translate('confirmEmail.invalidVerificationCode'));
      return of(false);
    })
  )

  resendCode = () => this.resendCodeSubject$.next(true);

  resendConfirmationCode = () => this.userService.resendConfirmationCode(this.email).pipe(
    mapTo(true),
    catchError(_ => {
      this.snackbarService.snackbarError(this.translocoService.translate('confirmEmail.errorWhileSendingTheConfirmationCode'));
      return of(false);
    })
  )
}
