import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-select-list-independent-expenditure',
  templateUrl: './select-list-independent-expenditure.component.html',
  styleUrls: ['./select-list-independent-expenditure.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectListIndependentExpenditureComponent implements OnInit {  
  @Input() independentExpenditureList: any = [];
  @Output() deleteSelectedEmitter: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  removeIndependentExpenditure(data: any, idx:any) {
    this.deleteSelectedEmitter.emit({data , idx});
  }
}
