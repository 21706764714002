import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from "@angular/core";
import {
    ClearEvent,
    FileInfo,
    RemoveEvent,
    SelectEvent,
    SuccessEvent,
    UploadComponent,
} from "@progress/kendo-angular-upload";
import { MasterUrlService } from "src/app/core";
import { FillingService } from "../../core/api-services/filling.service";
import { map } from "rxjs/operators";
import { HttpHeaders } from "@angular/common/http";
import { UserService } from "src/app/core/services/user-service";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

export class MyFileInfo implements FileInfo {
    name: any;
    uid?: any;
    myUid?: any;
}

@Component({
    selector: "my-upload",
    template: `
        <kendo-upload
            [saveUrl]="uploadSaveUrl"
            [removeUrl]="uploadRemoveUrl"
            [autoUpload]="false"
            [(ngModel)]="myuploadedFiles"
            (select)="selectEventHandler($event)"
            (remove)="removeEventHandler($event)"
            (success)="successEventHandler($event)"
            (clear)="clear($event)"
            [saveHeaders]="headers$ | async"
        >
        </kendo-upload>
    `,
})
export class MapLightUploadComponent {
    constructor(
        private urlService: MasterUrlService,
        private fillingservice: FillingService,
        private user: UserService
    ) {
        this.headers$ = this.user.getAccessToken().pipe(map(token => new HttpHeaders({Authorization: `Bearer ${token}`})));
    }
    @Output() sendUID: EventEmitter<any> = new EventEmitter();
    @Output() removeUID: EventEmitter<any> = new EventEmitter();
    @Input() myuploadedFiles: Array<FileInfo> = [];
    @ViewChild(UploadComponent) uploader: UploadComponent;

    uploadSaveUrl = environment.baseUrl + this.urlService.uploadSave; // should represent an actual API endpoint
    uploadRemoveUrl = environment.baseUrl + this.urlService.uploadRemove; // should represent an actual API endpoint
    headers$: Observable<HttpHeaders>;

    clearFiles() {
        this.myuploadedFiles = [];
    }
    clear(ev: ClearEvent) {
        console.log(ev);
    }
    successEventHandler(e: SuccessEvent) {
        if (e.operation == "upload") {
            let file = <MyFileInfo>e.files[0];
            e.response.body.forEach((uid) => {
                file.myUid = uid;
                this.sendUID.emit(uid);
            });
        }
    }
    selectEventHandler(e: SelectEvent) {
        e.files.forEach((file) => this.myuploadedFiles.push(file));
    }
    removeEventHandler(e: RemoveEvent) {
        const myFile: MyFileInfo = <MyFileInfo>e.files[0];
        if (myFile.myUid > 0) {
            e.preventDefault();

            this.fillingservice
                .delete(
                    this.urlService.uploadRemove +
                        "?attachmentId=" +
                        myFile.myUid
                )
                .subscribe((res: any) => {
                    if (res && res.length > 0 && res[0].code == 200) {
                        this.removeUID.emit(myFile.myUid);
                    }
                });
            this.myuploadedFiles = this.myuploadedFiles.filter(
                (f) => f.uid !== myFile.myUid
            );
        }
    }
}
