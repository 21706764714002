import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { ComboBoxComponent } from "@progress/kendo-angular-dropdowns";
import { BehaviorSubject, Observable, of, Subject, combineLatest } from "rxjs";
import { catchError, filter, switchMap, share, map } from "rxjs/operators";
import { SimpleFilerModel } from "src/app/app.model";
import { ClientService } from "src/app/core";

export interface JoinOptions {
    heading: string;
    description: string;
    subheading: string;
    showNoteArea: boolean;
    searchUrl: string;
}

@Component({
    selector: "app-joinable-entity-selector",
    templateUrl: "./joinable-entity-selector.component.html",
    styleUrls: ["./joinable-entity-selector.component.css"],
    encapsulation: ViewEncapsulation.None
})
export class JoinableEntitySelectorComponent implements OnInit, JoinOptions {
    @Input() heading: string;
    @Input() description: string;
    @Input() subheading: string;
    @Input() showNoteArea: boolean = true;
    @Input() searchUrl: string;

    private filterOptionsSubject$: BehaviorSubject<void> = new BehaviorSubject<void>(null);
    private searchFilersSubject$: Subject<string> = new Subject<string>();
    options$: Observable<SimpleFilerModel[]>;

    selection: SimpleFilerModel[] = [];
    @Output() selectionChange: EventEmitter<SimpleFilerModel[]> = new EventEmitter<SimpleFilerModel[]>();

    noteContent: string = "";

    constructor(
        private readonly clientService: ClientService
    ) {}

    ngOnInit(): void {
        const applicableFilers$ = this.searchFilersSubject$.pipe(
            map(x => x.trim()),
            filter(x => x.length > 0),
            switchMap(this.searchFilers),
            share()
        );

        this.options$ = combineLatest([this.filterOptionsSubject$, applicableFilers$]).pipe(
            map(([_, arr]) => arr.filter(x => !this.selection.some(y => y.filerId === x.filerId)))
        );
    }

    searchFilers = (name): Observable<SimpleFilerModel[]> => this.clientService.getData(this.searchUrl + name).pipe(
        catchError(_ => of([]))
    );

    updateOptions = (e) => this.searchFilersSubject$.next(e);

    select(item: SimpleFilerModel, search: ComboBoxComponent): void {
        if (item) this.selection = [item, ...this.selection];
        this.selectionChange.emit(this.selection);

        search.value = null;
        this.filterOptionsSubject$.next();
    }

    unselect(item: SimpleFilerModel): void {
        this.selection = this.selection.filter(x => x.filerId != item.filerId);
        this.selectionChange.emit(this.selection);
        this.filterOptionsSubject$.next();
    }
}
