import { NgModule } from "@angular/core";
import { PipesModule } from "./pipes.module";
import { ListViewModule } from "@progress/kendo-angular-listview";
import { InfoListComponent } from "../components/info-list/info-list.component";

@NgModule({
    declarations: [InfoListComponent],
    imports: [
        PipesModule,
        ListViewModule,
    ],
    exports: [InfoListComponent]
})
export class InfoListModule {}
