import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation, Input, EventEmitter, Output } from '@angular/core';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { Subject } from 'rxjs';
import { FilerInformationService, MasterDataService, MasterUrlService, SharedService } from 'src/app/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Contributions } from '../../../app.model';
import { ClientService } from '../../../core/api-services/client.service';

export interface ExpenditureTransactionsResponseModel extends Contributions {
  payeeName: string;
  payeeId: number;
  payeeType: string;
  payeeTypeId: number;
  filerTypeId: number;
  purpose: string;
  occupation: string;
  expenditureCategoryId: number;
  expendituresCategory: string;
  fairElectionFund: string;
  fairElectionFundFlag: boolean;
}
@Component({
  selector: 'app-campaign-expenditure',
  templateUrl: './campaign-expenditure.component.html',
  styleUrls: ['./campaign-expenditure.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignExpenditureComponent implements OnInit, OnDestroy {
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  @Input() data: ExpenditureTransactionsResponseModel[] = [];
  @Input() expenditureUnitemizedTotal: number = 0;
  @Input() hideActions: boolean = false;
  @Input() fefparticipationstatus: number;

  @Output() onSaveNote: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();

  mySelection: string[] = []
  public expandedDetailKeys: any[] = [];
  total: number = 0;
  isAdmin = false;
  isPublicView: boolean = false;
  filerTypeId: any;
  entityId: any;
  addNoteForm: UntypedFormGroup;
  noteWindow: boolean = false;
  private _unsubscribeAll: Subject<any>;
    displayAttachment: string = "";
    showPopup: boolean = false;
    file: any;

  constructor(
    public formatting: MasterDataService,
    private urlService: MasterUrlService,
    private sharedService: SharedService,
    private clientService: ClientService,
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private readonly filer: FilerInformationService
  ) {
    this.addNoteForm = this.formBuilder.group({
      recordId: new UntypedFormControl(''),
      notetext: new UntypedFormControl('', Validators.required)
    });
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.total = this.expenditureUnitemizedTotal;
    this.isPublicView = this.sharedService.isPublicView();

    this.isAdmin = this.filer.isAdmin;
  }

  navigateById(e) {
    this.router.navigate(['/filing/Transactions'], { queryParams: { id: e, type: 'expenditure' } });
  }

  public expandDetailsBy = (dataItem: ExpenditureTransactionsResponseModel): any => {
    return dataItem.transactionId;
  }

  saveNote(){
    this.onSaveNote.emit(this.addNoteForm.value);
  }

  editTransaction(transactionId: number) {
    this.onEdit.emit({id: transactionId, type:'expenditure'});
  }

  deleteTransaction(transactionId: number) {
    this.onDelete.emit({id: transactionId, type:'monetary'});
  }

  addNote(event: any) {
    this.addNoteForm.controls.recordId.setValue(event.transactionId);
    this.noteWindow = true;
  }
  closeNote() {
    this.noteWindow = false;
    this.addNoteForm.reset();
  }

  ngOnDestroy() {
    this._unsubscribeAll.unsubscribe();
  }

  downloadFile(data: ExpenditureTransactionsResponseModel) {
    return this.clientService.downloadBlobAttachment(this.urlService.blobAttachment +
            "?attachmentId=" + data.attachmentId).subscribe();
  }
  onViewReceipt(data: ExpenditureTransactionsResponseModel): void {
      this.clientService.getBlobAttachment(this.urlService.blobAttachment +
          "?attachmentId=" + data.attachmentId).subscribe(res => {
              this.showPopup = true;
              this.file = res;   
          });
  }
}
