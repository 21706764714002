import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { SnackbarService } from "src/app/core";
import { CreateAccountService } from "src/app/core/services/create-account.service";
import { FileShareService } from "src/app/core/services/file-share.service";
import {
  SelectEvent,
  RemoveEvent,
  FileRestrictions,
} from "@progress/kendo-angular-upload";
import { TranslocoService } from "@ngneat/transloco";

@Component({
  selector: "app-modify",
  templateUrl: "./modify.component.html",
  styleUrls: ["./modify.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ModifyComponent implements OnInit {
  @Input() modifyLabels: any;
  @Input() showButtons: boolean;
  @Input() customFileRestrictions: FileRestrictions;
  @Input() customFileRestrictionsHint: String;
  @Output() imageIndex: EventEmitter<any> = new EventEmitter<any>();
  @Output() files: EventEmitter<any> = new EventEmitter<any>();
  modifyItems: any[];
  imageArray = [];
  modifyData: any[];
  newArray: any[];
  fileArray: File[] = [];
  showSubmit: boolean = true;
  disableUpload: boolean = true;
  public myFiles: Array<any>;
  public modify_form: UntypedFormGroup;
  public fileRestrictions: FileRestrictions = {
    allowedExtensions: [".jpg", ".png"],
    maxFileSize: 1000000,
  };
  public fileRestrictionsHint: String;
  constructor(
    public file:FileShareService,
    public snackbar: SnackbarService,
    public signIn: CreateAccountService,
    private fb: UntypedFormBuilder,
    private translocoService: TranslocoService
  ) {
    this.modify_form = this.fb.group({
      select_0: this.fb.control,
      select_1: this.fb.control,
      select_2: this.fb.control,
      select_3: this.fb.control,
      select_4: this.fb.control
    });
  }

  ngOnInit(): void {
    this.getModifyItems();
    this.toggleSubmitButtons();
    this.setCustomFileRestrictions();
    this.fileRestrictionsHint = this.customFileRestrictionsHint ?? this.translocoService.translate('systemManagement.fileRestrictionsHint');
  }

  private setCustomFileRestrictions() {
    if (this.customFileRestrictions != null) {
      this.fileRestrictions = this.customFileRestrictions;
    }
  }

  private toggleSubmitButtons() {
    if (!this.showButtons) {
      this.showSubmit = false;
    }
  }

  getModifyItems() {
    this.modifyItems = [];
    for(let item of this.modifyLabels){
      if(item.imageSource == ""){
        item.imageSource = null
      }
    }
    this.modifyItems = this.modifyLabels;
  }
  selectImage(image, index, file) {
    this.modifyItems[index].imageSource = image.src;
    this.imageArray[index] = image.src;
    if(file !== null){
      this.fileArray[index] = file.rawFile;
      this.disableUpload = false;
    }
  }
  removeImage(index){
    this.modifyItems[index].imageSource = null;
    this.imageArray[index] = null;
    this.fileArray[index] = null;
    this.disableUpload = true;
  }
  save(){
    this.newArray = this.modifyItems.map((o, index) => {
      return  this.modifyItems[index].imageSource = o.imageSource ;
    });
    this.imageIndex.emit({save:this.imageArray,update:this.newArray});
  }

  getFiles(){
    this.disableUpload = true;
    this.files.emit(this.fileArray);

    this.modifyItems = [];
    this.imageArray = [];
    this.fileArray = [];
  }

  public removeEventHandler(e: RemoveEvent, idx: any): void {
    this.removeImage(idx);
  }

  public selectEventHandler(e: SelectEvent, idx: any): void {
    const that = this;
    e.files.forEach((file) => {
      if (!file.validationErrors) {
        const reader = new FileReader();
        reader.onload = function (ev) {
          const image = {
            src: ev.target["result"],
            uid: file.uid,
            name: file.name,
            size: file.size,
          };
          that.selectImage(image, idx, file)
        };
        reader.readAsDataURL(file.rawFile);
      }
    });
  }
}
