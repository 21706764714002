import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { CommonMethods, SnackbarService } from "src/app/core";
import { ForgotPasswordInitFlowComponent } from "../forgot-password-init-flow/forgot-password-init-flow.component";
import { ForgotPasswordCompleteFlowComponent } from "../forgot-password-complete-flow/forgot-password-complete-flow.component";
import { finalize } from "rxjs/operators";
import { AccountService } from "../../services/account.service";

@Component({
    selector: "app-forgot-password",
    templateUrl: "./forgot-password.component.html",
    styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
    @ViewChild(ForgotPasswordInitFlowComponent)
    initForgotPasswordFlow: ForgotPasswordInitFlowComponent;
    @ViewChild(ForgotPasswordCompleteFlowComponent)
    completeForgotPasswordFlow: ForgotPasswordCompleteFlowComponent;

    protected email: string;
    protected loading = false;
    protected step: "1" | "2" = "1";

    constructor(
        public commonMethods: CommonMethods,
        private fb: UntypedFormBuilder,
        public router: Router,
        private readonly snackbarService: SnackbarService,
        private accountService: AccountService
    ) {}

    ngOnInit(): void {}

    initFlow(email: string) {
        this.loading = true;

        this.accountService
            .initForgotPasswordFlow(email)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe(
                (_) => {
                    this.step = "2";
                    this.email = email;
                },
                (error) => {
                    this.snackbarService.snackbarError(error.message);
                }
            );
    }

    completeFlow({ code, password }) {
        this.loading = true;

        this.accountService
            .completeForgotPasswordFlow(code, this.email, password)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe(
                (_) => {
                    // noinspection JSIgnoredPromiseFromCall
                    this.router.navigate(["/authentication/login"]);
                },
                (error) => {
                    this.snackbarService.snackbarError(error.message);
                }
            );
    }

    submit() {
        if (this.step == "1") {
            this.initForgotPasswordFlow.submit();
        } else {
            this.completeForgotPasswordFlow.submit();
        }
    }

    back() {
        if (this.step === "1") {
            this.router.navigateByUrl("authentication/login");
        } else if (this.step === "2") {
            this.step = "1";
        }
    }
}
