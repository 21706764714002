import { Injectable, OnDestroy } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { NotificationService } from '@progress/kendo-angular-notification';
import { environment } from '../../../environments/environment';
import { Subject, timer }  from "rxjs";
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class SnackbarService implements OnDestroy{
    readonly _unsub = new Subject<void>();

    closeSubject$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private readonly notificationService: NotificationService,
        private readonly translocoService: TranslocoService,
    ) { }

    ngOnDestroy(): void {
        this._unsub.next();
    }

    snackbarSuccess(message: any, manualClose: boolean = false) {
        const ref = this.notificationService.show({
            content: message,
            cssClass: 'success-snackbar',
            animation: { type: "fade", duration: 300 },
            position: { horizontal: "center", vertical: "bottom" },
            type: { style: "info", icon: true },
            hideAfter: environment.snackBarDuration,
            closable: manualClose,
        });

        if(manualClose){
            timer(environment.snackBarDuration * 12)
            .pipe(takeUntil(this._unsub))
            .subscribe(() => ref?.hide())
        }
    }

    snackbarAddUpdate(moduleName: string, isAdded: boolean = true) {
        this.snackbarSuccess(this.translocoService.translate(isAdded ? 'snackbarAdd' : 'snackbarUpdate', { item: moduleName }));
    }

    snackbarError(message: string, manualClose: boolean = false) {
        const ref = this.notificationService.show({
            content: message,
            cssClass: 'error-snackbar',
            animation: { type: "fade", duration: 300 },
            position: { horizontal: "center", vertical: "bottom" },
            type: { style: "error", icon: true },
            hideAfter: manualClose ? undefined : environment.snackBarDuration,
            closable: manualClose,
        });

        ref.afterHide.subscribe(() => {
            this.closeSubject$.next(true);
        });    
    }

    snackbarInfo = (message: string) => this.snackbarSuccess(message);

    snackbarWarning(message: any) {
        if (message) {
            this.notificationService.show({
                content: message,
                cssClass: 'wraning-snackbar',
                animation: { type: "fade", duration: 300 },
                position: { horizontal: "center", vertical: "bottom" },
                type: { style: "warning", icon: true },
                hideAfter: environment.snackBarDuration
            });
        }
    }

    snackbarDefault(message: any) {
        this.notificationService.show({
            content: message,
            cssClass: 'default-snackbar',
            animation: { type: "fade", duration: 300 },
            position: { horizontal: "center", vertical: "bottom" },
            type: { style: "none", icon: true },
            hideAfter: environment.snackBarDuration
        });
    }

    snackbarBulkUpload = (message: string) => this.snackbarSuccess(message, true);

}
