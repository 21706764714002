import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GridModule } from "@progress/kendo-angular-grid";
import { PipesModule } from "./pipes.module";

// directives
import { SelectCreateCommiteeLobbyComponent } from "./../../shared/components/select-create-commitee-lobby/select-create-commitee-lobby.component";
import { SearchCommitteeLobbyistComponent } from "./../../shared/components/search-committee-lobbyist/search-committee-lobbyist.component";
import { SelectListCommitteeLobbyistComponent } from "./../../shared/components/select-list-committee-lobbyist/select-list-committee-lobbyist.component";
import { SelectCreateIndependentExpenditureComponent } from "./../../shared/components/select-create-independent-expenditure/select-create-independent-expenditure.component";
import { SearchIndependentExpenditureComponent } from "./../../shared/components/search-independent-expenditure/search-independent-expenditure.component";
import { SelectListIndependentExpenditureComponent } from "./../../shared/components/select-list-independent-expenditure/select-list-independent-expenditure.component";
import { KeyPreventDirective } from "../directives/prevent-key-typing.directive";

import { DeleteModalComponent } from "../components/delete-modal/delete-modal.component";
import { ConfirmationScreenComponent } from "../components/confirmation-screen/confirmation-screen.component";
import { ConfirmPopupComponent } from "../components/confirm-popup/confirm-popup.component";
import { ClickOutsideDirective } from "../directives/clicked-outside.directive";
import { RegisterJoinCommitteeComponent } from "../components/register-join-committee/register-join-committee.component";
import { CurrentCommitteeInformationComponent } from "../components/current-committee-information/current-committee-information.component";
import { CurrentCommitteeContactInformationComponent } from "../components/current-committee-contact-information/current-committee-contact-information.component";
import { CurrentCommitteeBankInformationComponent } from "../components/current-committee-bank-information/current-committee-bank-information.component";
import { CurrentCommitteeOfficerInformationComponent } from "../components/current-committee-officer-information/current-committee-officer-information.component";

import { NavigatescreenComponent } from "../components/navigatescreen/navigatescreen.component";
import { ModifyComponent } from "../components/modify/modify.component";
import { CurrentCommitteeComponent } from "../components/current-committee/current-committee.component";
import { NumericDirective } from "../directives/numeric.directive";
import { LobbyistContactInformationComponent } from "../components/lobbyist-information/lobbyist-contact-information.component";
import { EmployeesGridComponent } from "../components/employees-grid/employees-grid.component";
import { ClientGridComponent } from "../components/client-grid/client-grid.component";
import { RelationshipsGridComponent } from "../components/relationships-grid/relationships-grid.component";
import { LoansGridComponent } from "../components/loans-grid/loans-grid.component";
import { CampaignExpenditureComponent } from "../components/campaign-expenditure/campaign-expenditure.component";
import { FilingRefundsComponent } from "../components/filing-refunds/filing-refunds.component";
import { FairElectionFundingComponent } from "../components/fair-election-funding/fair-election-funding.component";
import { FefQualifyingContributionsComponent } from "../components/fef-qualifying-contributions/fef-qualifying-contributions.component";
import { InKindContributionsComponent } from "../components/in-kind-contributions/in-kind-contributions.component";
import { MonetaryContributionsComponent } from "../components/monetary-contributions/monetary-contributions.component";
import { UnpaidObligationsComponent } from "../components/unpaid-obligations/unpaid-obligations.component";
import { NavigationModule } from "@progress/kendo-angular-navigation";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { MenusModule } from "@progress/kendo-angular-menu";
import { FilingNotesComponent } from "../components/filing-notes/filing-notes.component";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { LabelModule } from "@progress/kendo-angular-label";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { ListViewModule } from "@progress/kendo-angular-listview";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { DatePickerModule } from "@progress/kendo-angular-dateinputs";
import { ChartModule } from "@progress/kendo-angular-charts";
import { IconsModule } from "@progress/kendo-angular-icons";
import { UploadsModule } from "@progress/kendo-angular-upload";

import { AppFooter } from "../components/app-footer/app-footer.component";
import { UniqueDropDownFilterComponent } from "../gridfilters/unique-drop-down-filter/unique-drop-down-filter.component";
import { MapLightUploadComponent } from "../upload/upload.component";
import { DateRangeFilterComponent } from "../gridfilters/date-range-filter.component";
import { CampaignIndependentExpenditureComponent } from "../components/campaign-independent-expenditures/campaign-independent-expenditure.component";
import { DialogModule, WindowModule } from "@progress/kendo-angular-dialog";
import { TransactionUploadComponent } from "../upload/transaction-upload.component";
import { TransactionBulkUploadComponent } from "../upload/transaction-bulkupload.component";
import { SearchContactBoxComponent } from "../components/search-contact/search-contact-box.component";
import { CommitteeSummaryComponent } from "../components/committee-summary/committee-summary.component";
import { PaymentUploadComponent } from "../upload/payment-upload.component";
import { LobbyistFilingInfoComponent } from "../components/lobbyist-filing-info/lobbyist-filing-info.component";
import { InfoListModule } from "./info-list.module";
import { TooltipModule } from "./tooltip.module";
import { SectionHeadingModule } from "./section-heading.module";
import { AttachmentDisplayModule } from "./attachment-display.module";
import { TranslocoModule } from "@ngneat/transloco";
import { ContactUpdateCheckComponent } from "../components/contact-update-check/contact-update-check.component";
import { UploadSignatureComponent } from "../components/upload-signature/upload-signature.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { CurrentCommitteeBusinessInformationComponent } from "../components/current-committee-business-information/current-committee-business-information.component";
import { CurrentCommitteePersonalContactInformationComponent } from "../components/current-committee-personal-contact-information/current-committee-personal-contact-information.component";

@NgModule({
    declarations: [
        AppFooter,
        FilingRefundsComponent,
        FairElectionFundingComponent,
        FefQualifyingContributionsComponent,
        InKindContributionsComponent,
        MonetaryContributionsComponent,
        UnpaidObligationsComponent,
        SelectCreateCommiteeLobbyComponent,
        SearchCommitteeLobbyistComponent,
        SelectListCommitteeLobbyistComponent,
        SelectCreateIndependentExpenditureComponent,
        SearchIndependentExpenditureComponent,
        SelectListIndependentExpenditureComponent,
        KeyPreventDirective,
        ClickOutsideDirective,
        NumericDirective,
        DeleteModalComponent,
        ConfirmationScreenComponent,
        ConfirmPopupComponent,
        RegisterJoinCommitteeComponent,
        CurrentCommitteeInformationComponent,
        CurrentCommitteeContactInformationComponent,
        CurrentCommitteeBankInformationComponent,
        CurrentCommitteeOfficerInformationComponent,
        NavigatescreenComponent,
        ModifyComponent,
        CurrentCommitteeComponent,
        LobbyistContactInformationComponent,
        EmployeesGridComponent,
        ClientGridComponent,
        RelationshipsGridComponent,
        LoansGridComponent,
        CampaignExpenditureComponent,
        CampaignIndependentExpenditureComponent,
        FilingNotesComponent,
        UniqueDropDownFilterComponent,
        MapLightUploadComponent,
        TransactionUploadComponent,
        PaymentUploadComponent,
        TransactionBulkUploadComponent,
        DateRangeFilterComponent,
        SearchContactBoxComponent,
        CommitteeSummaryComponent,
        LobbyistFilingInfoComponent,
        ContactUpdateCheckComponent,
        UploadSignatureComponent,
        CurrentCommitteeBusinessInformationComponent,
        CurrentCommitteePersonalContactInformationComponent,
    ],
    imports: [
        InfoListModule,
        PipesModule,
        DialogModule,
        NavigationModule,
        DateInputsModule,
        DatePickerModule,
        MenusModule,
        DropDownsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GridModule,
        LayoutModule,
        LabelModule,
        ButtonsModule,
        ListViewModule,
        InputsModule,
        IconsModule,
        UploadsModule,
        TooltipModule,
        DropDownsModule,
        ChartModule,
        WindowModule,
        SectionHeadingModule,
        AttachmentDisplayModule,
        TranslocoModule,
        FlexLayoutModule,
    ],
    exports: [
        LobbyistFilingInfoComponent,
        PipesModule,
        AppFooter,
        WindowModule,
        FilingRefundsComponent,
        FairElectionFundingComponent,
        FefQualifyingContributionsComponent,
        InKindContributionsComponent,
        MonetaryContributionsComponent,
        UnpaidObligationsComponent,
        IconsModule,
        UploadsModule,
        GridModule,
        KeyPreventDirective,
        ClickOutsideDirective,
        NumericDirective,
        LayoutModule,
        InputsModule,
        ButtonsModule,
        LabelModule,
        DateInputsModule,
        DatePickerModule,
        DropDownsModule,
        ChartModule,
        TooltipModule,
        ListViewModule,
        SectionHeadingModule,
        AttachmentDisplayModule,
        InfoListModule,
        SelectCreateCommiteeLobbyComponent,
        SearchCommitteeLobbyistComponent,
        SelectListCommitteeLobbyistComponent,
        SelectCreateIndependentExpenditureComponent,
        SearchIndependentExpenditureComponent,
        SelectListIndependentExpenditureComponent,
        ConfirmationScreenComponent,
        RegisterJoinCommitteeComponent,
        CurrentCommitteeInformationComponent,
        CurrentCommitteeContactInformationComponent,
        CurrentCommitteeBankInformationComponent,
        CurrentCommitteeOfficerInformationComponent,
        NavigatescreenComponent,
        ModifyComponent,
        CurrentCommitteeComponent,
        LobbyistContactInformationComponent,
        EmployeesGridComponent,
        ClientGridComponent,
        RelationshipsGridComponent,
        LoansGridComponent,
        CampaignExpenditureComponent,
        CampaignIndependentExpenditureComponent,
        FilingNotesComponent,
        UniqueDropDownFilterComponent,
        MapLightUploadComponent,
        TransactionUploadComponent,
        PaymentUploadComponent,
        TransactionBulkUploadComponent,
        DateRangeFilterComponent,
        SearchContactBoxComponent,
        CommitteeSummaryComponent,
        FlexLayoutModule,
        CurrentCommitteeBusinessInformationComponent,
        CurrentCommitteePersonalContactInformationComponent,
    ],
})
export class SharedModule {}
