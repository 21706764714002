import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MasterDataService, LocalstorageService, ErrorMessageService, SnackbarService } from '../../../core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class HomeLayoutComponent implements OnInit, OnDestroy {
  showProfile: boolean = false;
  hideSideBar = false;
  public selectedindex = 0;
  noentity: any;
  currenturl: string = '';
  showNoentityMsg: any;
  localrole: any;
  isAdmin = false;
  islobby: any;
  iscover = false;
  showmsg = false;
  isnoentity: any;
  hidesidenav: any;
  public index = 0;
  isCalendarPage = false;
  constructor(
    private localStore: LocalstorageService,
    public formatting: MasterDataService,
    public router: Router,
    public errorService: ErrorMessageService,
    public snackbar: SnackbarService,
  ) {

  }
  currentRoute: any;
  ngOnInit() {
  }

  closedrpdwn() {
    this.showProfile = true;
  }

  logout() {
    this.localStore.clearAllStorage();
    this.snackbar.snackbarSuccess("Logged out Successfully!");
    this.router.navigate(['/authentication/login']);
  }
  goToHelp() {
    this.router.navigate(['/help']);
  }

  switchCommittee() {
    this.router.navigate([this.formatting.switchScreenNav])
  }

  checkRouter() {
    const splittedRoute = this.router.url?.split('/');
    const splittedCommitteeRoute = this.currenturl?.split('?');
    if (splittedRoute && splittedRoute[1] == 'dashboard') {
      this.showmsg = true;
    }
    if (splittedRoute && (splittedRoute[1] == 'switch' || splittedRoute[2] == 'committee-registration' || splittedRoute[2] == 'createlobbyist' || splittedRoute[2] == 'independent-expenditure-registration')) {
      this.hidesidenav = true;
    }
    if (splittedCommitteeRoute[0] == '/committee/committee-registration' || splittedCommitteeRoute[0] == '/lobbyist/createlobbyist' || splittedCommitteeRoute[0] == '/independent-expenditure/independent-expenditure-registration') {
      this.hidesidenav = true;
    }

    if (splittedRoute && (splittedRoute[1] == "profile" && splittedRoute[2] == "changepassword")) {
      this.hidesidenav = true;
    }
    if (splittedRoute && (splittedRoute[1] == "ethics")) {
      this.hidesidenav = true;
    }
  }

  changeProfile() {
    this.showProfile = !this.showProfile;
  }

  ngOnDestroy() {
  }
}
