import { NgModule } from "@angular/core";
import { TooltipComponent } from "../components/tooltip/tooltip.component";
import { TooltipModule as KendoTooltip } from "@progress/kendo-angular-tooltip";

@NgModule({
    declarations: [TooltipComponent],
    imports: [KendoTooltip],
    exports: [TooltipComponent]
})
export class TooltipModule {}
