import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    ViewEncapsulation,
    Input,
    EventEmitter,
    Output,
} from "@angular/core";
import { DataBindingDirective } from "@progress/kendo-angular-grid";
import { Subject } from "rxjs";
import {
    MasterDataService,
    MasterUrlService,
    SharedService,
} from "src/app/core";
import { FillingService } from "src/app/core/api-services/filling.service";
import { Router } from "@angular/router";
import { FilingMappingRecordNoteRequestApiModel } from "src/app/modules/Filing/filing.models";
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { FilingMappingRecordTypeEnum } from "../../models";
import { ExpenditureIndependentTransactions } from "../../../app.model";
import { ClientService } from '../../../core/api-services/client.service';

@Component({
    selector: "app-campaign-independent-expenditure",
    templateUrl: "./campaign-independent-expenditure.component.html",
    styleUrls: ["./campaign-independent-expenditure.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class CampaignIndependentExpenditureComponent
    implements OnInit, OnDestroy
{
    @Input() filingId: number = 0;
    @Input() hideActions: boolean = false;

    IEs = [];
    gridView: any[];

    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
    @Input() data: ExpenditureIndependentTransactions[] = [];
    @Input() expenditureUnitemizedTotal: number = 0;
    @Output() onSaveNote: EventEmitter<any> = new EventEmitter<any>();
    @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
    @Input() fefparticipationstatus: number;
    mySelection: string[] = [];
    public expandedDetailKeys: any[] = [];
    total: number = 0;
    isAdmin = false;
    isPublicView: boolean = false;
    filerTypeId: any;
    entityId: any;
    addNoteForm: UntypedFormGroup;
    noteWindow: boolean = false;
    displayAttachment: string = "";
    showPopup: boolean = false;
    file: any;

    private _unsubscribeAll: Subject<any>;
    constructor(
        public formatting: MasterDataService,
        private urlService: MasterUrlService,
        private filingService: FillingService,
        public router: Router,
        private sharedService: SharedService,
        private formBuilder: UntypedFormBuilder,
        private clientService: ClientService,
    ) {
        this._unsubscribeAll = new Subject();
        this.addNoteForm = this.formBuilder.group({
            recordId: new UntypedFormControl(""),
            notetext: new UntypedFormControl("", Validators.required),
        });
    }

    ngOnInit(): void {
        this.isPublicView = this.sharedService.isPublicView();

        let filter;
        if (this.isPublicView) {
            filter = this.sharedService.getCurrentSelectedPublicFiler();
        } else {
            filter = this.sharedService.getCurrentSelectedUserFiler();
        }

        if (filter) {
            this.isAdmin = filter.isAdmin;
            this.filerTypeId = filter.selectedFiler?.filerTypeId;
            this.entityId = filter.selectedFiler?.entityId;
        }
    }

    navigateById(e) {
        this.router.navigate(["/filing/Transactions"], {
            queryParams: { id: e, type: "expenditure" },
        });
    }

    getTransactionsByFilter = () => {
        this.filingService
            .getData(
                `${this.urlService.getIndependentExpenditureFilerTypesList}?entityId=${this.entityId}&filerTypeId=${this.filerTypeId}`
            )
            .subscribe((res: any) => {});
    };
    public expandDetailsBy = (dataItem: any): any => {
        return dataItem.transactionId;
    };

    editTransaction(transactionId: number) {
        this.router.navigateByUrl(
            `/filing/Transactions/${transactionId}/expenditure`
        );
    }

    addNote(event: any) {
        this.addNoteForm.controls.recordId.setValue(event.transactionId);
        this.noteWindow = true;
    }
    closeNote() {
        this.noteWindow = false;
        this.addNoteForm.reset();
    }

    saveNote() {
        let saveNoteRequest: FilingMappingRecordNoteRequestApiModel = {
            filingId: this.filingId,
            filingMappingRecordTypeId: FilingMappingRecordTypeEnum.Transaction,
            recordId: this.addNoteForm.controls.recordId.value,
            notes: this.addNoteForm.controls.notetext.value,
        };
        this.filingService
            .postData(this.urlService.addFillingNote, saveNoteRequest)
            .subscribe((res: any) => {
                this.closeNote();
            });
    }

    deleteTransaction(transactionId: number) {
        this.onDelete.emit({ id: transactionId, type: "ie" });
    }

    downloadFile(data: ExpenditureIndependentTransactions) {
        return this.clientService.downloadBlobAttachment(this.urlService.blobAttachment +
                "?attachmentId=" + data.attachmentId).subscribe();
    }

      onViewReceipt(data: ExpenditureIndependentTransactions): void {
          this.clientService.getBlobAttachment(this.urlService.blobAttachment +
              "?attachmentId=" + data.attachmentId).subscribe(res => {
                  this.showPopup = true;
                  this.file = res;   
              });
      }

    onCloseReceipt(showPopup: boolean): void {
        this.showPopup = showPopup;
    }

    ngOnDestroy() {
        this._unsubscribeAll.unsubscribe();
    }
}
