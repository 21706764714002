import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { MasterDataService, SharedService } from "src/app/core";
import { Contributions } from "../../../app.model";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

export interface FilingRefundResponseModel extends Contributions {
    refundReason: string;
    qualifyingContribution: boolean;
    refundOrPaidDate?: Date;
}

@Component({
    selector: "app-filing-refunds",
    templateUrl: "./filing-refunds.component.html",
    styleUrls: ["./filing-refunds.component.css"],
})
export class FilingRefundsComponent implements OnInit {
    @Input() hideActions: boolean = false;
    
    @Output() onSaveNote: EventEmitter<any> = new EventEmitter<any>();
    @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
    @Input() fefparticipationstatus: number;
    @Input() data: FilingRefundResponseModel[] = [];
    public expandedDetailKeys: any[] = [];
    isAdmin: boolean = false;
    isPublicView: boolean = false;
    entityId: number;
    filerTypeId: number;
    noteWindow: boolean = false;
    addNoteForm: UntypedFormGroup;
    displayAttachment: string = "";
    showPopup: boolean = false;

    constructor(
        private readonly sharedService: SharedService,
        private readonly formBuilder: UntypedFormBuilder,
        readonly formatting: MasterDataService
    ) {}

    ngOnInit(): void {
        this.isPublicView = this.sharedService.isPublicView();

        const filer = this.isPublicView
            ? this.sharedService.getCurrentSelectedPublicFiler()
            : this.sharedService.getCurrentSelectedUserFiler();

        this.isAdmin = filer?.isAdmin;

        this.addNoteForm = this.formBuilder.group({
            recordId: this.formBuilder.control(""),
            notetext: this.formBuilder.control("", Validators.required),
        });
        this.filerTypeId = filer.selectedFiler.filerTypeId;
    }

    public expandDetailsBy = (dataItem: FilingRefundResponseModel): any => {
        return dataItem.transactionId;
    };

    onViewReceipt(receiptImageUrl: string): void {
        this.displayAttachment = receiptImageUrl;
        this.showPopup = true;
    }

    onCloseReceipt(showPopup: boolean): void {
        this.showPopup = showPopup;
    }

    addNote(event: any) {
        this.addNoteForm.controls.recordId.setValue(event.transactionId);
        this.noteWindow = true;
    }

    closeNote() {
        this.noteWindow = false;
        this.addNoteForm.reset();
    }

    saveNote() {
        this.onSaveNote.emit(this.addNoteForm.value);
    }

    editTransactions(transactionId: number, type="refund") {
        this.onEdit.emit({ id: transactionId, type: type });
    }

    deleteTransaction(transactionId: number) {
        this.onDelete.emit({id: transactionId, type:'monetary'});
    }
    onClick(dataItem)
    {
        if (this.isAdmin) this.addNote(dataItem)
        else if (dataItem.parentTransactionId) this.editTransactions(dataItem.parentTransactionId, "monetary")
        else this.editTransactions(dataItem.transactionId);
    }
    
}
