import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { FilerInformationService } from "../../../core";
import { combineLatest, Observable } from "rxjs";
import { filter, map, startWith } from "rxjs/operators";
import { NavigationEnd, Router } from "@angular/router";
import { FilerTypeEnum, UserApiModel } from "../../models";
import { TranslocoService } from "@ngneat/transloco";
import { UserService } from "src/app/core/services/user-service";
import { IUserSession } from "src/app/core/api-services/authorization/auth.service";

type MenuItem = { text: string; path: string };
type Menu = { text: string; items: MenuItem[] };

@Component({
    selector: "app-header",
    templateUrl: "./app-header.component.html",
    styleUrls: ["./app-header.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeader implements OnInit {
    authenticated$: Observable<boolean>;
    menuItems$: Observable<Menu[]>;
    dashboardLink$: Observable<string[]>;
    showMenu: boolean = false;

    constructor(
        private readonly user: UserService,
        private readonly router: Router,
        private readonly filer: FilerInformationService,
        private readonly translocoService: TranslocoService
    ) {}

    getStandardItems(manage: string, log_out: string) {
        return [
            { text: manage, path: "/account/manage/profile" },
            { text: log_out, path: "/account/logout" },
        ];
    }

    getDefaultMenu(log_in: string, register: string): Menu[] {
        return [
            {
                text: log_in,
                items: [
                    { text: register, path: "/account/signup" },
                    { text: log_in, path: "/account/login" },
                ],
            },
        ];
    }

    private readonly dashboardRouting: Record<FilerTypeEnum, string[]> = {
        [FilerTypeEnum.Admin]: ["manage", "committee"],
        [FilerTypeEnum.AllFilers]: ["/"],
        [FilerTypeEnum.Campaign]: ["committee-info-dashboard"],
        [FilerTypeEnum.IndependentExpenditure]: ["dashboard", "ie-home"],
        [FilerTypeEnum.Lobbyists]: ["lobbyist"],
        [FilerTypeEnum.Unaffiliated]: ["/switch"],
        [FilerTypeEnum.EthicsFiler]: ["ethics-dashboard"],
    };

    private readonly buildMenu = (
        data: UserApiModel,
        hideSwitch: boolean = true,
        switch_committee: string,
        log_in: string,
        register: string,
        manage: string,
        log_out: string
    ): Menu[] => {
        if (!data) return this.getDefaultMenu(log_in, register);

        const text =
            [data.firstName ?? "", data.lastName ?? ""].join(" ").trim() ||
            data?.email?.trim() ||
            "Admin";

        const items = hideSwitch
            ? this.getStandardItems(manage, log_out)
            : this.getStandardItems(manage, log_out).concat({
                  text: switch_committee,
                  path: "/switch",
              });

        return [{ text, items }];
    };

    ngOnInit() {
        this.authenticated$ = this.user.isAuthenticated();

        const userData$ = this.user.getUserStatus().pipe(
            map((s) => {
                if (s.status != "LoggedIn") return null;
                else if (!!s.user)
                    return {
                        firstName: s.user.firstName,
                        lastName: s.user.lastName,
                    };
                else return { email: (s.content as IUserSession).email };
            })
        );

        const route$ = this.router.events.pipe(
            filter((_) => _ instanceof NavigationEnd),
            map((e: NavigationEnd) => e.url)
        );

        const switch_committee$ = this.translocoService.selectTranslate(
            "headerSection.switchCommittee"
        );
        const log_in$ = this.translocoService.selectTranslate(
            "headerSection.logIn"
        );
        const register$ = this.translocoService.selectTranslate(
            "headerSection.createAccount"
        );
        const manage$ = this.translocoService.selectTranslate(
            "headerSection.manage"
        );
        const log_out$ = this.translocoService.selectTranslate(
            "headerSection.logOut"
        );

        this.menuItems$ = combineLatest([
            userData$,
            route$,
            switch_committee$,
            log_in$,
            register$,
            manage$,
            log_out$,
        ]).pipe(
            map(
                ([
                    data,
                    url,
                    switch_committee,
                    log_in,
                    register,
                    manage,
                    logout,
                ]) =>
                    this.buildMenu(
                        data,
                        url.indexOf("switch") >= 0,
                        switch_committee,
                        log_in,
                        register,
                        manage,
                        logout
                    )
            )
        );

        this.dashboardLink$ = combineLatest([
            this.user.isAuthenticated(),
            this.filer.current$,
        ]).pipe(
            map(([authenticated, filer]) => {
                if (!authenticated) return FilerTypeEnum.AllFilers;

                const hasAdmin = this.filer.isAdmin && FilerTypeEnum.Admin;

                return (filer?.filerTypeId || hasAdmin) as FilerTypeEnum;
            }),
            map((types) => this.dashboardRouting[types] ?? ["/"]),
            startWith(["/"])
        );
    }

    public onSelect({ item }): void {
        if (!item.items) {
            this.router.navigate([item.path]);
        }
    }

    switchLanguage() {
        if (this.translocoService.getActiveLang() === "en") {
            this.translocoService.setActiveLang("es");
        } else {
            this.translocoService.setActiveLang("en");
        }
    }

    electionHomepage() {
        window.open("https://vote.minneapolismn.gov/candidates/campaign-finance/");
    }

    public onToggle(): void {
        this.showMenu = !this.showMenu;
    }
}
