import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UserService } from "../services/user-service";

@Injectable({
    providedIn: "root",
})
export class OnlyPublicGuard implements CanActivate {
    constructor(
        private readonly user: UserService,
        private readonly router: Router
    ) {}

    canActivate(
        _next: ActivatedRouteSnapshot
    ): Observable<boolean | UrlTree> {
        return this.user.isAuthenticated().pipe(map(x => x ? this.router.createUrlTree(["switch"]) : true));
    }
}
