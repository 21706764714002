import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoaderService {
    public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public loginStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isLoader: boolean;
    // private loaderCount = 0;
    setLoggedInStatus(value: boolean) {
        this.loginStatus.next(value);
    }

    display(value: boolean) {
        this.isLoader = value;
        this.status.next(value);
    }
}