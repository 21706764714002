import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from "@angular/core";
import { MasterUrlService } from "src/app/core";
import { exhaustMap, filter, map, tap } from "rxjs/operators";
import { ClientService } from "../../../core/api-services/client.service";
import { TxnContactDetailResponseApiModel as Contact } from "../../../modules/public/public.models";
import { IContactSearchApiModel } from "../../models/app-shared.model";
import { Observable, Subject, Subscription } from "rxjs";
export interface ResultPage {
    total: number;
    items: Contact[];
}


@Component({
    selector: "app-search-contact-box",
    styleUrls: ["./search-contact-box.component.scss"],
    templateUrl: "./search-contact-box.component.html",
})
export class SearchContactBoxComponent implements OnInit, OnDestroy {
    @Input() minimumLenght: any;
    @Input() searchContactModel: IContactSearchApiModel;
    @Input() events: Observable<IContactSearchApiModel>;

    @Output() resultEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() nameEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() loading = new EventEmitter<boolean>();
    private readonly subs = new Subscription();
    private readonly search$ = new Subject<IContactSearchApiModel>();

    constructor(
        private urlService: MasterUrlService,
        private service: ClientService
    ) {}

    ngOnInit(): void {
        const eventsSub = this.events.subscribe(
            (searchContactModel: IContactSearchApiModel) => {
                this.searchContactModel = searchContactModel;
                this.getContacts();
            }
        );

        this.subs.add(eventsSub);

        const searchSub = this.search$
            .pipe(
                filter(this.check),
                exhaustMap(this.doSearch),
                tap(() => this.loading.emit(false))
            )
            .subscribe(this.resultEmitter);

        this.subs.add(searchSub);

        this.getContacts();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    private readonly check = (_: IContactSearchApiModel): boolean => {
        if (!_.name || _.name.length >= this.minimumLenght) {
            return true;
        }

        this.loading.emit(false);
        return false;
    };

    private readonly doSearch = (
        _: IContactSearchApiModel
    ): Observable<ResultPage> => {
        this.setName(_.name);

        this.loading.emit(true);

        return this.service.postData(this.urlService.searchContacts, _);
    };

    getContacts() {
        this.search$.next(this.searchContactModel);
    }

    setValue(name: string): void {
        this.setName(name);
        this.nameEmitter.emit(name);
    }

    private setName(name: string): void {
        this.searchContactModel.name = name ? name : "";
    }
}
