import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
    Profile,
    User,
    UserManager,
    UserManagerSettings,
    WebStorageStateStore,
} from "oidc-client";
import { from, fromEventPattern, Observable, of } from "rxjs";
import {
    catchError,
    distinctUntilChanged,
    map,
    shareReplay,
    switchMap,
} from "rxjs/operators";
import { environment } from "src/environments/environment";
import { LocalstorageService } from "../../localstorage/localstorage.service";
import { ApplicationPaths } from "./api-authorization.constants";

const oidcConfigUrl = ApplicationPaths.ApiAuthorizationClientConfigurationUrl;

export enum AuthStatus {
    Success,
    Redirect,
    Fail,
}

export type AuthFailed = { status: AuthStatus.Fail; message: string };
export type AuthSuccess = { status: AuthStatus.Success; state: any };
export type AuthRedirect = { status: AuthStatus.Redirect };

export type AuthResult = AuthFailed | AuthSuccess | AuthRedirect;

@Injectable({
    providedIn: "root",
})
export class AuthorizeService {
    private readonly manager$: Observable<UserManager>;

    readonly user$: Observable<Profile>;
    readonly authenticated$: Observable<boolean>;
    readonly userId$: Observable<string>;
    readonly accessToken$: Observable<string>;

    constructor(
        private readonly router: Router,
        private readonly storage: LocalstorageService
    ) {
        const issuerUrl = `https://cognito-idp.${environment.cognito.region}.amazonaws.com/${environment.cognito.userPoolId}`;
        const authorizationUrl = `https://${environment.cognito.host}.auth.${environment.cognito.region}.amazoncognito.com`;
        const logoutUrl = `${window.location.origin}/authentication/logout-callback`;
        const settings: UserManagerSettings = {
            authority: issuerUrl,
            client_id: environment.cognito.clientId,
            redirect_uri: `${window.location.origin}/authentication/login-callback`,
            post_logout_redirect_uri: logoutUrl,
            response_type: "code",
            scope: "openid",
            automaticSilentRenew: true,
            includeIdTokenInSilentRenew: true,
            revokeAccessTokenOnSignout: true,
            metadata: {
                issuer: issuerUrl,
                jwks_uri: `${issuerUrl}/.well-known/jwks.json`,
                authorization_endpoint: `${authorizationUrl}/oauth2/authorize`,
                token_endpoint: `${authorizationUrl}/oauth2/token`,
                userinfo_endpoint: `${authorizationUrl}/oauth2/userInfo`,
                end_session_endpoint: `${authorizationUrl}/logout?client_id=${environment.cognito.clientId}&logout_uri=${logoutUrl}`,
            },
        };

        this.manager$ = of(settings).pipe(
            map(this.buildManager),
            shareReplay(1)
        );

        const user$ = this.manager$.pipe(
            switchMap(this.setupUserEvents),
            distinctUntilChanged((a, b) => a?.profile?.sub === b?.profile?.sub),
            shareReplay(1)
        );

        this.user$ = user$.pipe(
            map((_) => _?.profile),
            shareReplay(1)
        );

        this.authenticated$ = user$.pipe(
            map((_) => !!_),
            shareReplay(1)
        );

        this.userId$ = this.user$.pipe(
            map((_) => _?.sub),
            shareReplay(1)
        );

        this.accessToken$ = user$.pipe(
            map((_) => _?.access_token),
            shareReplay(1)
        );
    }

    private readonly buildManager = (_: UserManagerSettings): UserManager =>
        new UserManager({
            ..._,
            userStore: new WebStorageStateStore({
                store: window.localStorage,
            }),
        });

    private readonly setupUserEvents = (_: UserManager): Observable<User> =>
        fromEventPattern((handler) => {
            _.startSilentRenew();
            _.events.addUserLoaded(handler);
            _.events.addSilentRenewError(() =>
                _.removeUser().then(() => {
                    this.storage.clearAllStorage();
                    this.router.navigateByUrl("/");

                    handler(null);
                })
            );

            _.getUser()
                .then(handler)
                .catch(() => handler(null));
        });

    isAuthenticated() {
        return this.manager$.pipe(
            switchMap((_) => _.getUser().then((u) => !!u))
        );
    }

    getUser() {
        return this.manager$.pipe(
            switchMap((_) => _.getUser().then((u) => u?.profile))
        );
    }

    getUserId() {
        return this.getUser().pipe(map((_) => _?.sub));
    }

    getAccessToken() {
        return this.manager$.pipe(
            switchMap((_) => _.getUser().then((u) => u?.access_token))
        );
    }

    getIdToken() {
        return this.manager$.pipe(
            switchMap((_) => _.getUser().then((u) => u?.id_token))
        );
    }

    private success(state: any): AuthResult {
        return { status: AuthStatus.Success, state };
    }

    private redirect(): AuthResult {
        return { status: AuthStatus.Redirect };
    }

    private failure(): AuthResult {
        return { status: AuthStatus.Fail, message: "" };
    }

    private buildArgs(state?: any) {
        return { useReplaceToNavigate: true, state };
    }

    signIn(state: any): Observable<AuthResult> {
        return this.manager$.pipe(
            switchMap(async (manager) => {
                try {
                    await manager.signinSilent(this.buildArgs());
                    return this.success(state);
                } catch (err) {
                    console.log(err);
                }
                try {
                    await manager.signinRedirect(this.buildArgs(state));
                    return this.redirect();
                } catch (err) {
                    console.log(err);
                }

                return this.failure();
            })
        );
    }

    completeSignIn(url: string): Observable<AuthResult> {
        return this.manager$.pipe(
            switchMap(async (manager) => {
                try {
                    return this.success(
                        (await manager.signinCallback(url))?.state
                    );
                } catch (err) {
                    console.log(err);
                    return this.failure();
                }
            })
        );
    }

    signOut(state: any): Observable<AuthResult> {
        return this.manager$.pipe(
            switchMap(async (manager) => {
                try {
                    await manager.signoutRedirect(this.buildArgs(state));
                    return this.redirect();
                } catch (err) {
                    return this.failure();
                }
            })
        );
    }

    completeSignOut(url: string): Observable<AuthResult> {
        return this.manager$.pipe(
            switchMap(async (manager) => {
                try {
                    return this.success(
                        (await manager.signoutCallback(url))?.state
                    );
                } catch (err) {
                    console.log(err);
                    return this.failure();
                }
            })
        );
    }
}
