import { NgModule } from "@angular/core";
import { AgeCalculatorPipe } from "../pipes/age-calculator.pipe";
import { TargetTimePipe } from "../pipes/denver-time.pipe";
import { PhonePipe } from "../pipes/format-phone.pipe"

@NgModule({
    declarations: [
        TargetTimePipe,
        AgeCalculatorPipe,
        PhonePipe,
    ],
    exports: [
        TargetTimePipe,
        AgeCalculatorPipe,
        PhonePipe
    ]
})
export class PipesModule {}