import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ErrorMessageService, MasterDataService, MasterUrlService, SnackbarService } from 'src/app/core';
import { ClientService } from 'src/app/core/api-services/client.service';
import { CommitteeTypeEnums } from '../../models';

@Component({
  selector: 'app-current-committee',
  templateUrl: './current-committee.component.html',
  styleUrls: ['./current-committee.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CurrentCommitteeComponent implements OnInit {
  committeeDetails:any;
  committeeName:any;
  committeeDetail:any;
  cId:any;
  committeeType:any;
  candidateName:any;
  treasurerName:any;
  electionDate:any;
  publicStatus:any;
  district:any;
  office: any;
  @Input() Id:any;
  committeeId: any;
    purpose: any;
    ballotIssueNo: any;
    positionTypeName: any;

  constructor(
    public formatting: MasterDataService,
    public errorService: ErrorMessageService,
    private service: ClientService,
    private urlService: MasterUrlService,
    public snackbar: SnackbarService,
) { }

  ngOnInit(): void {
    this.getCommitteeInfoDetails();
  }

    getCommitteeInfoDetails() {
    this.service.getData(this.urlService.GetCommitteeDetailsByFiler + this.Id).subscribe((res) => {
        this.committeeId = res.committeeId;       
      this.committeeDetail = res;
      if (this.committeeDetail.length > 0) {
        this.committeeName = this.committeeDetail.committeeName;
        this.cId = this.committeeDetail.filerId;
        this.district = this.committeeDetail.district;
        this.office = this.committeeDetail.office;
        this.committeeType = this.committeeDetail.committeeType;
        this.candidateName = this.committeeDetail.candidateName;
        this.treasurerName = this.committeeDetail.treasurerName;
        this.electionDate = this.committeeDetail.electionDate;
          this.publicStatus = this.committeeDetail.publicFund;         
        }
       
        if (this.committeeDetail.committeeTypeId === CommitteeTypeEnums.IssueCommittee) {
            this.purpose = this.committeeDetail.purposeDesc
            // TODO: What to do when there is more than one ballot_issue
            if (this.committeeDetail.ballotIssues.length === 0) {
                this.ballotIssueNo = this.committeeDetail.ballotIssueNotes;
                this.positionTypeName = this.committeeDetail.positionName;
            } else {
                this.committeeDetail?.ballotIssues.forEach((x) => {
                    this.ballotIssueNo = x.ballotIssue;
                    this.positionTypeName = x.positionType;
                });
            }
        }
    })
  }
  }
