import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CommonMethods, SharedService } from "src/app/core";
import { SharedModule } from "./shared/modules/shared.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { LoaderService } from "src/app/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CoreModule } from "./core/core.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HomeLayoutComponent } from "./shared/components/home-layout/home-layout.component";
import { SideNavComponent } from "./shared/sidenavcomponent/sidenavcomponent.component";
import { DataService, TransferDataService } from "./core/services/data-service";
import { HelpsModule } from "./modules/helps/helps.module";
import { AccountModule } from "src/app/modules/account/account.module";
import { AuthorizeInterceptor } from "src/app/core/interceptor/authorize.interceptor";
import { SimpleLayoutComponent } from "./shared/components/simple-layout/simple-layout.component";
import {
    AppBarModule,
    NavigationModule,
} from "@progress/kendo-angular-navigation";
import { AppHeader } from "./shared/components/app-header/app-header.component";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { MenusModule } from "@progress/kendo-angular-menu";
import { SchedulerModule } from "@progress/kendo-angular-scheduler";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { ListViewModule } from "@progress/kendo-angular-listview";
import { IconsModule } from "@progress/kendo-angular-icons";
import { FilerLayoutComponent } from "./shared/components/filer-layout/filer-layout.component";
import { TranslocoRootModule } from "./transloco-root.module";
import { NotificationModule } from "@progress/kendo-angular-notification";
import { PopupModule } from "@progress/kendo-angular-popup";

@NgModule({
    declarations: [
        AppComponent,
        HomeLayoutComponent,
        FilerLayoutComponent,
        SideNavComponent,
        AppHeader,
        SimpleLayoutComponent,
    ],
    imports: [
        AppBarModule,
        ButtonsModule,
        MenusModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        SharedModule,
        FlexLayoutModule,
        HelpsModule,
        AccountModule,
        NavigationModule,
        SchedulerModule,
        LayoutModule,
        ListViewModule,
        IconsModule,
        TranslocoRootModule,
        NotificationModule,
        BrowserAnimationsModule,
        PopupModule,
    ],
    providers: [
        DataService,
        LoaderService,
        TransferDataService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizeInterceptor,
            multi: true,
        },
        CommonMethods,
        SharedService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
