import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-manage-personal-data",
    templateUrl: "./manage-personal-data.component.html",
    styleUrls: ["./manage-personal-data.component.scss"],
})
export class ManagePersonalDataComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
