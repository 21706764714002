import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  FilterService,
  BaseFilterCellComponent,
} from "@progress/kendo-angular-grid";

@Component({
  selector: 'app-unique-drop-down-filter',
  templateUrl: './unique-drop-down-filter.component.html',
  styleUrls: ['./unique-drop-down-filter.component.scss']
})
export class UniqueDropDownFilterComponent extends BaseFilterCellComponent implements OnInit {

  @Input() public filter: any;
  @Input() public data: any[];
  @Input() public textField: string;
  @Input() public valueField: string;
  @Input() public isMultiselect?: boolean;
  defaultSelected: any;
  defaultMultiSelected: any[];
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  
  selectedValue(): any {
    const filter = this.filterByField(this.valueField);
    return filter ? filter.value : null;
  }
  constructor(filterService: FilterService) {
    super(filterService);
    this.defaultSelected = "All";
    this.defaultMultiSelected = ["All"];
  }
  ngOnInit(): void {
    
  }
  onChange(value: any): void {    
    this.applyFilter(
      value === "All" // value of the default item
        ? this.removeFilter(this.valueField) // remove the filter
        : this.updateFilter({
          // add a filter for the field with the value
          field: this.valueField,
          operator: "eq",
          value: value,
        })
    ); // update the root filter
    this.valueChange.next(value);
  }
  onChangeMultifilter(values: any[]): void {
    let all = values.filter(function (x) { return x == "All"; });
    if (all.length > 0) {
      this.filterService.filter({ filters: [], logic: "and" });
      this.defaultMultiSelected = ["All"];
    }
    else {
      this.filterService.filter({
        filters: values.map((value) => ({
          field: this.valueField,
          operator: "eq",
          value: value,
        })),
        logic: "or",
      });
    }
    
  }

}
