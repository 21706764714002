import { Injectable } from "@angular/core";
import { MessageThreadFilter } from "../shared/models/app-message.models";

export type IEContributionRoute = "MonetaryContribution" | "InKindContributions" | "RefundTransactions" | "IE";

@Injectable()
export class MasterUrlService {
    getNotificationInstance(filerId: number, notificationId: number): string {
        return `api/messaging/${filerId}/notification/${notificationId}`;
    }

    getAccessEthicsFiledReports(filerId: number): string {
        return `api/Filing/PreviousAccessEthicsReportsByFilerId?filerId=${filerId}`;
    }

    getUnreadNotificationCount(filerId: number = 0): string {
        return !filerId
            ? "api/Messaging/unread/admin"
            : `api/Messaging/unread/${filerId}`;
    }

    checkContributionLimits(data: {
        filerId: number;
        contactId: number;
        contributionAmount: number;
        contactTypeId: number;
        previousValue?: number;
        refundAmount?: number;
        previousRefundAmount?: number;
        transactionDate: string;
        fairElections?: boolean;
        electionCycleId: number;
    }): string {
        const options = Object.entries(data)
            .filter(([_, value]) => value !== undefined && value !== null)
            .map(([key, value]) => `${key}=${value}`)
            .join("&");

        return `${this.checkContributionLimit}?${options}`;
    }

    getMessageThreadsOf(
        id: string | number,
        filter: MessageThreadFilter
    ): string {
        const _ = new URLSearchParams();

        Object.entries(filter)
            .filter(([key, value]) => !!value)
            .forEach(([key, value]) => _.append(key, value));

        const queryParams = _.toString();

        return `api/messaging/${id}${
            queryParams.length > 0 ? `?${queryParams}` : ""
        }`;
    }

    constructor() {}
    loginUrl = "api/UserManagement/Authenticate";
    getUserDetailUrl = "api/UserManagement/GetLoggedInUserInfo";
    blobAttachment = "api/Attachment";
    user = "api/UserManagement/user";

    storageDocuments = "api/Attachment/Storage?fileType=";

    // API Token getting details
    grantType = "password";
    clientId = "ro.client";
    clientSecret = "secret";
    scope = "api1";
    TokenUrl = "/identityServer/connect/token";

    private applyParams(route: string, bundle: any): string {
        const params = Object.entries(bundle)
            .map(([key, value]) => `${key}=${value}`)
            .join("&");
        return `${route}?${params}`;
    }

    //User Management
    deleteFilerEntityUser = (_: {
        userId: string | number;
        entityId: number;
        filerTypeId: number;
    }): string =>
        this.applyParams("api/UserManagement/DeleteFilerEntityUser", _);

    requestAffiliation = "api/filer/affiliation";
    getFilerEntitiesByType = "api/filer/type";
    createCoe = "api/Committee/createCoveredOfficials?contactId=";
    CreateContactInformation = "api/UserManagement/CreateContactInformation";
    loginInformation = "api/UserManagement/LoginInformation";
    committeeGetList = "api/LookUp/getLookUps";
    ballotInformation = "api/Committee/ballotissue/getList";
    lookupGetList = "api/LookUp/getLookUps";
    getallUserType = "api/UserManagement/GetAllUserType";
    getFilerTypes = "api/LookUp/getLookUps?lookUpTypeCode=";
    chooseUserType = "api/UserManagement/ChooseUserType";
    committeeInformation = "api/committee/createDetails";
    createSmallDonorCommittee = "api/Committee/createSmallDonorCommittee";
    createPACCommittee = "api/Committee/createPACCommittee";
    createIssueCommittee = "api/Committee/createIssueCommittee";
    GetBankInfo = "api/Committee/GetBankInfo?committeeId=";
    GetCommitteeContactInfo = "api/Committee/GetCommitteeContactInfo";

    deleteOfficer = "api/Committee/DeleteOfficer?contactid=";
    lobbyistInformation = "api/Lobbyist/createLobbyist";
    iefAdditionalInfo = "api/UserManagement/UpdateIEFAdditionalInfo";
    confirmSubmit = "api/UserManagement/GetUserAccountConfirmAndSubmit";
    getClients = "api/Lobbyist/Getclient";
    AllLobbyists = "api/Lobbyist";
    validateEmailCheck = "api/UserManagement/VaidateEmailCheck?UserEmailID=";
    validateAspNetUserEmailCheck =
        "api/UserManagement/ValidateAspNetUserEmailCheck?UserEmailID=";
    getUserByEmail = "api/UserManagement/User";
    validateFilerName = "api/UserManagement/ValidateFilerName";
    AddNewAspNetUser = "api/UserManagement/User";
    UpdateAspNetUser = "api/UserManagement/User";
    getOfficers = "api/UserManagement/getOfficers?name=";
    getEmployees = "api/UserManagement/getEmployees?name=";
    getEmployeesByEntity(name: string, entityId: number = 0) {
        return `api/UserManagement/getEmployeesByEntity?name=${name}&entityId=${entityId}`;
    }

    getRegistrationDetails(entityId: number = 0, filerTypeId: number = 0) {
        return `api/UserManagement/GetRegistrationDetails?entityId=${entityId}&filerTypeId=${filerTypeId}`;
    }

    //Public
    getCandidatesElectionCycleList =
        "api/LookUp/getCandidatesElectionCycleList";
    getElectionCyclesByFiler = "api/Lookup/getElectionCyclesByFiler?filerId=";
    getBallotIssuesElectionCycleList =
        "api/LookUp/getBallotIssuesElectionCycleList";
    getOfficeByElectionCycle =
        "api/LookUp/getOfficesByElectionCycle?electionCycleId=";
    getDistrictsByElectionCycle =
        "api/LookUp/GetDistrictsByElectionCycle?electionCycleId=";
    getBallotChartByElectionCycle =
        "api/Committee/GetBallotIssuesGraphByElectionCycle?electionCycleId=";
    getUnaffiliatedCommitteesByElectionCycle =
        "api/Committee/GetUnaffiliatedCommitteeByElectionCycle?electionCycleId=";
    getCandidateGraph(
        electionCycleId: number = 0,
        officeId: number = 0,
        districtId = 0
    ) {
        return `api/Committee/GetCandidateGraphByElectionCycleAndOffice?electionCycleId=${electionCycleId}&officeId=${officeId}&districtId=${districtId}`;
    }
    // officer
    getOfficerTableList = "api/Committee/GetOfficersList?committeeid=";
    getFileExtensionWhiteList = "api/LookUp/GetFileExtensionWhiteList";

    //Lobbyist
    saveLobbyistNotes = "api/Lobbyist/Notes";
    getLookuplist = "api/LookUp/getLookUps?lookUpTypeCode=";
    getEmployeeLobbyist = "api/Lobbyist/getLobbyistEntities";
    getLobbyistEmployees = "api/Lobbyist/Employees";
    getLobbyistSearchEmployees = "api/Lobbyist/SearchEmployees";
    getLobbyistSubcontractors = "api/Lobbyist/Subcontractors";
    getLobbyistClients = "api/Lobbyist/Clients";
    getLobbyistRelationships = "api/Lobbyist/Relationships";
    getLobbyistPenalties = "api/Lobbyist/Penalties";
    getLobbyistYears = "api/Lobbyist/LobbyistYears";
    getExpenseFilingPeriods = "api/Lobbyist/FilingPeriods";
    getLobbyistExpenses = "api/Lobbyist/Expenses";
    getAllLobbyistClients = "api/Lobbyist/AllClients?filerId=";

    getPublicFilingInfo = "api/Filing";

    getLobbiestById = "api/Lobbyist/GetLobbyist?lobbyistId=";
    getLobbyist = "api/Lobbyist/GetLobbyist";
    getSearchLobbyist = "api/Lobbyist/getLobbyistEntitiesbyName";
    GetLobbyistListByUserId = "api/Lobbyist/GetLobbyistListByUserId";
    getLobbyisAlltListByUserIdFilerId =
        "api/Lobbyist/getLobbyisAlltListByUserIdFilerId";
    getLobbyistEmployee = "api/Lobbyist/GetLobbyistEmployee";

    //calendar
    saveElectionCycle = "api/Calendar/saveElectionCycle";
    deleteElectionCycle = "api/Calendar/deleteElectionCycle?electionCycleId=";
    getElectionCycleById = "api/Calendar/getElectionCycleById?electionCycleId=";
    getElectionCycles = "api/Calendar/getElectionCycles";
    getCandidateElectionCycles =
        "api/Calendar/getElectionCycles/type=Candidate";
    saveEvents = "api/Calendar/SaveEvents";
    deleteEvents = "api/Calendar/DeleteEvents?eventId=";
    getEvents = "api/Calendar/GetEvents";
    saveFilingPeriod = "api/Calendar/SaveFilingPeriod";
    deleteFilingPeriod = "api/Calendar/DeleteFilingPeriod";
    getFilingPeriods = "api/Calendar/GetFilingPeriods";
    getFilingPeriodsById = "api/Calendar/GetFilingPeriodById?filingperiodid=";
    getEventsById = "api/Calendar/GetEventsById?eventId=";
    getCityItemFilingPeriods = "api/CityItem/FilingPeriods";
    getCityItemFilingInformation = "api/CityItem/FilingInformation";

    //switchCommittee
    getUserEntitiesDetails = "api/UserManagement/getUserEntityDetails";
    getCommiteesListNew = "api/Committee/GetCommitteeByName";
    addCommitteeAffiliation = "api/Committee/SaveCommitteeAffiliation";

    //Calendar API's
    getCalendarList = "api/Calendar/getCalendarEvents?";
    getPublicCalendarList = "api/Calendar/getPublicCalendarEvents?";
    getFilerCalendarList = "api/Calendar/getFilerCalendarEvents?";
    latefine = "api/Payments/SaveFine";
    deleteFine = "api/Payments/DeleteFine";
    getFines = "api/Payments/GetFines";
    addfees = "api/Payments/SaveFees";
    getfees = "api/Payments/GetFees";
    deleteFees = "api/Payments/DeleteFees";

    //download calendar
    downloadCalendar = "api/Calendar/DownloadCalendar";

    // paymenyts and Waivers
    getAdminOutStandingFeeSummary =
        "api/Payments/GetAdminOutStandingFeeSummary";
    getOustandingSummary = "api/Payments/GetOutStandingFeeSummary?filerid=";
    getOutstandingGrid = "api/Payments/GetInvoices";
    updateStatus = "api/Payments/UpdateInvoiceStatus";
    getFilerInvoices = "api/Payments/GetFilerInvoices";
    savePenalty = "api/Payments/SavePenalty";
    saveTransaction = "api/Payments/SaveTransaction";
    getpaymentHistory = "api/Payments/getPaymentHistory";
    dwnldPaymentHistory = "api/Payments/DownloadPaymentHistory";
    getInvoiceFromId = "api/Payments/GetInvoiceInfoFromId?invoiceid=";
    getEntityPaymentHistory = "api/Payments/getEntityPaymentHistory";
    getExpenditures = "api/Filing/getTransactionsByFiler";
    getloans = "api/Filing/GetfilerLoandetails";
    getInvoiceStatus = "api/Payments/GetInvoiceStatus";
    // Transaction
    saveContribution = "api/Transaction/saveContribution";
    saveIe = "api/Transaction/saveIE";
    saveUnitemizedTotal = "api/Transaction/saveUnitemizedTotal";
    downloadUploadTransactionsTemplate =
        "api/transaction/DownloadUploadTransactionsTemplate";
    uploadTransactionsTemplate = "api/transaction/uploadTransaction";
    getMonetaryContributionsByFiler =
        "api/transaction/GetMonetaryContributionTransactionsByFiler";
    getMonetaryContributionTransactionsByFiler =
        "api/transaction/monetary-contributions/filer/";
    getInKindContributionsByFiler =
        "api/transaction/GetInKindContributionTransactionsByFiler";
    getInKindContributions = "api/transaction/in-kind-contributions/filer/";
    getFairElectionsFundingTransactionsByFiler =
        "api/transaction/GetFairElectionsFundingTransactionsByFiler";
    getFefTransactionsByFiler = "api/transaction/fef-transactions/filer/";
    getRefundTransactionsByFiler =
        "api/transaction/GetRefundTransactionsByFiler";
    getRefundsByFiler = "api/transaction/refunds/filer/";
    getExpenditureTransactionsByFiler =
        "api/transaction/GetExpenditureTransactionsByFiler";
    getExpendituresByFiler = "api/transaction/expenditure-transactions/filer/";
    getUnpaidObligationTransactionsByFiler =
        "api/transaction/GetUnpaidObligationTransactionsByFiler";
    getUnpaidObligationsByFiler = "api/transaction/unpaid-obligations/filer/";
    getFEFQualifyingContributionTransactionsByFiler =
        "api/transaction/GetFEFQualifyingContributionTransactionsByFiler";
    getFefQualifyingContributionTransactionsByFiler =
        "api/transaction/fef-contributions/filer/";
    getLoanTransactionsByFiler = "api/transaction/GetLoanTransactionsByFiler";
    getLoansByFiler = "api/transaction/loan-transactions/filer/";
    getMonetaryContributionUnitemizedTotal =
        "api/transaction/getCampaignFinanceReportMonetaryContributionUnitemizedTotalByFilerAndFilingPeriod";
    getInKindContributionUnitemizedTotal =
        "api/transaction/getCampaignFinanceReportInKindContributionUnitemizedTotalByFilerAndFilingPeriod";
    getExpendituresUnitemizedTotal =
        "api/transaction/getCampaignFinanceReportExpenditureUnitemizedTotalByFilerAndFilingPeriod";
    deleteTransaction = "api/transaction/DeleteTransaction?transactionid=";
    deleteTransactionRefund =
        "api/transaction/DeleteTransactionRefund?parentTransactionId=";

    deleteLoan = "api/transaction/DeleteLoan?loanId=";
    //transactions-loan
    saveLoan = "api/Transaction/SaveLoan";
    saveExpenditure = "api/Transaction/saveExpenditure";
    saveUnpaidObligation = "api/Transaction/saveUnpaidObligation";
    saveIETransaction = "api/Transaction/saveIE";
    GetFilingSearchTransactions = "api/Transaction/GetFilingSearchTransactions";

    //FEF Withdrawal
    getAdminFEFWithdrawalInfoByFiler =
        "api/FEFWithdrawal/getAdminFEFWithdrawalQualifyForPublicFundingUserEntityDetailsByFiler?filerId=";
    getFEFWithdrawalInfoByFiler =
        "api/FEFWithdrawal/getFEFWithdrawalQualifyForPublicFundingUserEntityDetailsByFiler?filerId=";
    saveFEFWithdrawalQualifyForPublicFunding =
        "api/FEFWithdrawal/SaveFEFWithdrawalQualifyForPublicFunding";
    saveFEFWithdrawalQualifyForPublicFundingRecordDetail =
        "api/FEFWithdrawal/SaveFEFWithdrawalQualifyForPublicFundingRecordDetail";

    // FEF Qualifying-contributions
    linkFEFDisbursementWithContributions =
        "api/FEFQualifyingContributions/linkFEFDisbursementWithContributions";
    saveFEFDisbursement = "api/FEFDisbursement/";
    getFEFDisbursementInfoByFiler = "api/FEFDisbursement/";
    deleteFEFDisbursement = "api/FEFDisbursement/";

    //FEFApplication
    getFEFQualifyingContributions = "api/FEFQualifyingContributions/";
    getFEFReport = "api/FEFQualifyingContributions/report?filingId=";
    getFEFReportPDF = "api/FEFQualifyingContributions/PDFreport?filingId=";

    getFEFApplicationFilingInfouserDetailsByFilerandElection(
        filerId: number,
        filingId: number = null
    ) {
        if (filingId) {
            return `api/FEFApplication/getFEFApplicationQualifyingContributionFilingInfoUserEntityDetailsByFiler?filerId=${filerId}&filingId=${filingId}`;
        }
        return `api/FEFApplication/getFEFApplicationQualifyingContributionFilingInfoUserEntityDetailsByFiler?filerId=${filerId}`;
    }
    getFEFApplicationSummaryInfo =
        "api/FEFApplication/getFEFApplicationSummaryInfoByFiler?filerId=";
    getFEFApplicationSummaryInfoSubmitted =
        "api/FEFApplication/getFEFApplicationSummaryInfoByFilerSubmitted?filerId=";
    getFEFApplicationTransactionsByFiler =
        "api/FEFApplication/getFEFApplicationTransactionsByFiler?filerId=";
    saveFEFApplicationQualifyForPublicFunding =
        "api/FEFApplication/SaveFEFApplicationQualifyForPublicFunding";
    GetFEFApplicationFilingbyFilerId =
        "api/FEFApplication/GetFEFAdminApplicationFilingbyFilerId?filerId=";
    GetFEFAdminApplicationFilingInformationbyFilingId =
        "api/FEFApplication/getFEFAdminApplicationFilingInformationbyFilingId?filingId=";
    GetFEFApplicationSummaryInfoByFiling =
        "api/FEFApplication/getFEFApplicationSummaryInfoByFiling?filingId=";
    GetFEFApplicationTransactionsByFiling =
        "api/FEFApplication/getFEFApplicationTransactionsByFiling?filingId=";
    GetAdminFEFApplicationTransactionsByFiling =
        "api/FEFApplication/getAdminFEFApplicationTransactionsByFiling?filingId=";

    //fefparticipationstatus
    getParticipationStatuscurrentbyFilingId =
        "api/fefparticipationstatus/currentbyFilingId";

    //FEF Qualifying Contributions
    GetFEFQualifyingContributions = "api/FEFQualifyingContributions/";
    GetFEFQualifyingContributionsDownload =
        "api/FEFQualifyingContributions/download";

    //FEF FEFTransactionStatus
    updateFEFTransactionStatus = "api/FEFTransactionStatus/";

    //Admin manage users
    getAdministratorUser = "api/UserManagement/getCurrentAdministratorUserList";
    getFilerDetailsByName = "api/UserManagement/GetFilerDetailsByName";
    getUserAffiliations = "api/UserManagement/GetUserAffiliations";
    adminInviteUser = "api/UserManagement/AdminInviteUser";
    addNewAdministratorAspNetUser =
        "api/UserManagement/AddNewAdministratorAspNetUser";
    revokeAdministratorUserAccess =
        "api/UserManagement/RevokeAdministratorUserAccess";
    updateUserStatus = "api/UserManagement/UpdateUserStatus";
    getUserList = "api/UserManagement/UserList";
    getManageUsers = "api/UserManagement/getManageUsers";
    getApplicationUserStatus = "api/LookUp/getApplicationUserStatusesList";
    approveFilerFEFParticipationStatus = "api/fefparticipationstatus/approve";
    revokeFilerFEFParticipationStatus = "api/fefparticipationstatus/revoke";
    denyFilerFEFParticipationStatus = "api/fefparticipationstatus/deny";

    //Filing
    changePublicationStatus = "api/Filing/PublicationStatus";
    GetTransactionDetailsById = "api/Filing/GetTransactionDetailsById?txnId=";
    getLoanById = "api/Filing/EditLoan?loanId=";
    getFilingInfo = "api/Filing/getFilingInfo";
    SubmitFiling = "api/Filing/SaveFilingCampaignFinanceReport";
    SaveSpecialFilingCampaignFinanceReport =
        "api/Filing/SaveSpecialFilingCampaignFinanceReport";
    SubmitContributionReport =
        "api/Filing/SaveFillingQualifyingContributionReport";
    updateFilingStatus = "api/Filing/UpdateFilingStatus";
    updateFilingNoteOrStatus = "api/Filing/UpdateFilingNoteOrStatus";
    updateFilingNote = "api/Filing/UpdateFilingNote";
    getFilingCyclById = "api/Filing/GetFilingPeriodsByElectionCycle";
    getSummaryInfo = "api/Filing/GetSummaryInfo";
    getSummaryInfoByFiling = "api/filing/GetSummaryInfoByFiling";
    getSummaryInfoByFiler = "api/filing/summary/filer/";
    getTransactionsByFiler = "api/Filing/getTransactionsByFiler";
    getMonetaryContributionsByFiling =
        "api/transaction/GetMonetaryContributionTransactionsByFiling";
    getInKindContributionsByFiling =
        "api/transaction/GetInKindContributionTransactionsByFiling";
    getIEContributionsByFiling =
        "api/transaction/GetIEContributionTransactionsByFiling";
    getFEFQualifyingContributionTransactionsByFiling =
        "api/transaction/GetFEFQualifyingContributionTransactionsByFiling";
    getFairElectionsFundingTransactionsByFiling =
        "api/transaction/GetFairElectionsFundingTransactionsByFiling";
    getRefundTransactionsByFiling =
        "api/transaction/GetRefundTransactionsByFiling";
    getExpenditureTransactionsByFiling =
        "api/transaction/GetExpenditureTransactionsByFiling";
    getCampaignFinanceReportExpenditureUnitemizedTotalByFiling =
        "api/transaction/GetCampaignFinanceReportExpenditureUnitemizedTotalByFiling";
    getCampaignFinanceReportMonetaryContributionUnitemizedTotalByFiling =
        "api/transaction/GetCampaignFinanceReportMonetaryContributionUnitemizedTotalByFiling";
    getCampaignFinanceReportInKindContributionUnitemizedTotalByFiling =
        "api/transaction/GetCampaignFinanceReportInKindContributionUnitemizedTotalByFiling";
    getUnpaidObligationTransactionsByFiling =
        "api/transaction/GetUnpaidObligationTransactionsByFiling";
    getLoanTransactionsByFiling = "api/transaction/GetLoanTransactionsByFiling";
    getFillingCampaignFinanceExistingReports =
        "api/transaction/GetExistingReport";
    addFillingNote = "api/filing/SaveFilingMappingRecordNote";
    getUserEntityDetailsByFiling =
        "api/UserManagement/GetUserEntityDetailsByFiling";
    getCampaignFinanceReportFilingInfoByFiler =
        "api/filing/getCampaignFinanceReportFilingInfoByFiler?filerId=";
    getCampaignFinanceReportFilingInfoByFiling =
        "api/filing/information/filing/";
    getCampaignFinanceReportCommitteeInfoByFiling =
        "api/filing/committee-information/filing/";
    getCommitteeInformationByCommitteeId = "api/committee/information/";
    GetMCRTransactionsbyFiler = "api/transaction/GetMCRTransactions";
    getCampaignFinanceReportPDFDownload = "api/Filing/PDFReport?";
    getSpecialCampaignFinanceReportPDFDownload = "api/Filing/special-report/";
    //Upload
    uploadSave = "api/Attachment/upload";
    uploadSaveAttachments = "api/Attachment/SaveAttachments";
    uploadRemove = "api/Attachment/delete";

    // Filings
    GetReviewFilingsCampaignFinanceReport =
        "api/Filing/GetReviewFilingsCampaignFinanceReport";
    getReviewFilingsCampaignFinanceReportByFilerIdbyGroupFilings(
        filerId: number,
        filingTypeId: number = 0
    ) {
        if (filingTypeId > 0) {
            return `api/Filing/ReviewFilingCampaignFinanceReport/Filer/?filerId=${filerId}&filingTypeId=${filingTypeId}`;
        }
        return `api/Filing/ReviewFilingCampaignFinanceReport/Filer/?filerId=${filerId}`;
    }
    getCampaignFilingByCommittee =
        "api/Filing/GetCampaignFilingByCommittee/Committee/?committeeId=";
    GetReviewFilingsLobbyingReport =
        "api/Filing/GetReviewFilingsLobbyingReport";
    getReviewFilingsLateReport = "api/Filing/GetReviewFilingsLateReport";
    getReviewFilingsRepeatLateOffendersReport =
        "api/Filing/GetReviewFilingsRepeatLateOffendersReport";
    getReviewFilingsEthicsReport = "api/Filing/GetReviewFilingsEthicsReport";
    downloadEthicsBoard = "api/filing/EthicsBoard";
    getFEFParticipationStatusByFiling =
        "api/Filing/GetFEFParticipationStatusByFiling";

    // City
    getCityItemRecords = "api/CityItem/records";
    saveCityItemReport = "api/CityItem/Report";

    //chat
    GetUserChatList = "api/Chat/GetUserChatList";

    //Access Ethics
    getAccessEthicsByFilerId =
        "api/AccessEthics/GetAccessEthicsByFilerId?filerId=";
    GetAccessEthicsById = "api/AccessEthics/GetAccessEthicsById";
    saveAccessEthicsRegistration =
        "api/AccessEthics/saveAccessEthicsRegistration";
    joinAccessEthicsRegistration =
        "api/AccessEthics/joinAccessEthicsRegistration";
    saveEconomicInterestFiler = "api/AccessEthics/economic-interest";
    getEconomicInterestRoles = "api/AccessEthics/roles/";
    editEconomicInterestRoles = "api/AccessEthics/roles";

    //Gift
    saveGift = "api/Gift/saveGift";
    deleteGift = "api/Gift/deleteGift?giftId=";
    getGiftById = "api/Gift/getGiftById?giftId=";

    saveGiftRefund = "api/Gift/saveGiftRefund";
    getGiftRefundList = "api/Gift/GetGiftRefunds";
    deleteGiftRefund = "api/Gift/deleteGiftRefund?giftRefundId=";
    getGiftRefundById = "api/Gift/getGiftRefundById?giftRefundId=";

    getFEFParticipationStatusTypesList =
        "api/LookUp/getFEFParticipationStatusTypesList";
    SaveFEFIntentQualifyForPublicFundingRecordNoteAndStatus =
        "api/FEFIntent/SaveFEFIntentQualifyForPublicFundingRecordNoteAndStatus";

    getContactInformationByFiler =
        "api/FinancialDisclosure/contact-information/filer/";
    getContactInformationByFiling =
        "api/FinancialDisclosure/contact-information/filing/";
    getFinancialDisclosureReport = "api/FinancialDisclosure/report";
    submitFinancialDisclosureReport = "api/FinancialDisclosure/submit";
    saveFinancialDisclosureReport = "api/FinancialDisclosure/save";
    getReportTypesByFiler = "api/FinancialDisclosure/getReportTypesByFiler";

    getCurrentFEFParticipationStatus = "api/fefparticipationstatus/current";
    getCurrentFEFParticipationStatusByDate =
        "api/fefparticipationstatus/currentbydate";
    GetFairElectionFundingByFilerId =
        "api/filing/getFairElectionFundingByFilerId?filerId=";
    getFEFIntentQualifyForPublicFundingUserEntityDetailsByFilerId =
        "api/FEFIntent/getFEFIntentQualifyForPublicFundingUserEntityDetailsByFiler?filerId=";
    saveIntent = "api/FEFIntent/SaveFEFIntentQualifyForPublicFunding";
    verifyFEFAddress = "api/FEFAddressVerification/VerifyAddress";

    //TransactionSearch
    SearchExpenditureTransactions =
        "api/Transaction/SearchExpenditureTransactions";
    SearchContributionTransactions =
        "api/Transaction/SearchContributionTransactions";
    SearchPenalties = "api/Transaction/SearchPenalties";

    //Transactions
    updateTransactionAdminNotes = "api/Transaction/notes/admin/";

    //AdminDocuments
    adminDocumentsApi = "api/AdminDocument";
    adminDocumentsViewer = "api/AdminDocument/Viewer?adminDocumentId=";
    adminDocumentsByFilerIdApi = "api/AdminDocument/ByFilerId";
    getComplaintStatuses = "api/AdminDocument/GetComplaintStatuses";
    getAdminDocTypes = "api/AdminDocument/GetAdminDocTypes";

    //Candidate
    candidateApi = "api/Candidate";
    searchCandidateApi = "api/Candidate?name=";
    searchCandidateApiByElectionCycleId(name: string, electionCycleId: number) {
        return `${this.searchCandidateApi}${name}&electionCycleId=${electionCycleId}`;
    }
    candidateApiByElectionCycleId(electionCycleId: number) {
        return `${this.candidateApi}?electionCycleId=${electionCycleId}`;
    }

    //Filer
    filerApi = "api/Filer";
    getFilerById = "api/Filer/filer/";
    filingSnapshot = "api/filer/snapshot";
    terminateFiler = "api/Filer/terminate";

    getFilerIEInfoFiler = "api/filer/getFilerIEInfoFiler";

    //FilerTypes
    Lobbyists = 1;
    IE = 2;
    Committee = 3;
    EthicsFiler = 4;

    // Local Storage Keys
    UserFilers = "userfilers";
    AdminSelectedUserFilers = "adminSelectedUserFiler";
    PublicSelectedUserFilers = "publicSelectedUserFiler";
    UserEntity = "userEntity";
    UserData = "UserData";
    CurrentCommittee = "CurrentCommittee";

    //Election Type
    Candidate = 1;
    Issue = 2;
    Special = 3;

    //Election Type
    ElectionCycleStatusActive = 1;
    ElectionCycleStatusCompleted = 2;
    ElectionCycleStatusCanceled = 3;

    fairElectionParticipantByElectionCycle =
        "api/Committee/GetFairElectionsParticipatingCandidatesByElectionCycle?electionCycleId=";
    getContributionLocationByElectionCycle =
        "api/Committee/GetContributionsLocationByElectionCycle?electionCycleId=";
    getFairElectionDistributionsByElectionCycle =
        "api/Committee/GetFairElectionsDistributionsByElectionCycle?electionCycleId=";
    getElectionCycleByTypeId =
        "api/LookUp/getElectionCycleByType?electionTypeId=";
    getElectionCycleByTypes =
        "api/LookUp/getElectionCycleByTypes?electionTypeIds=";
    getElectionCyclesFromCommitteeByFiler =
        "api/LookUp/getElectionCyclesFromCommitteeByFiler?filerId=";
    dashboardItems = "api/LookUp/dashboard-items";

    //Committee
    committeeList = "api/Committee/getListByName";
    committeeAditionalInfo = "api/committee/UpdateCommitteeAdditionalInfo";
    committeeByName = "api/Committee/GetCommitteeByName";
    getCurrentCommitteeLastedCreatedForFiler =
        "api/Committee/getCurrentCommitteeLastedCreatedForFiler";
    SearchCommitteeByName =
        "api/Committee/SearchCommitteeByName?searchCommittee=";
    SearchCommitteeByNameAndType =
        "api/Committee/SearchCommitteeByNameAndType?searchCommittee=";
    SearchLobbyistByName = "api/Lobbyist/SearchLobbyistByName?searchLobbyist=";
    SearchLobbyistClientByName =
        "api/Lobbyist/SearchLobbyistClientByName?client=";
    getCommitteeById = "api/Committee/GetCommitteeById?filerId=";
    saveCommitteeAffiliation = "api/Committee/SaveCommitteeAffiliation";
    getAllCandidate = "api/Committee/getAllCandidate?search=";

    getRejectedAffiliationRequests = (_: {
        entityId: number;
        filerTypeId: number;
        filerId: number;
    }): string =>
        this.applyParams(
            "api/UserManagement/GetRejectedAffiliationRequests",
            _
        );

    getAffiliationRequests = (_: {
        entityId: number;
        filerTypeId: number;
        filerId: number;
    }): string =>
        this.applyParams("api/UserManagement/GetAffiliationRequests", _);

    getPendingInvitationsByFiler = (_: { filerId: number }): string =>
        this.applyParams("api/UserManagement/GetPendingInvitationsByFiler", _);

    getInvitationDetails = (_: { token: string }): string =>
        this.applyParams("api/UserManagement/GetInvitationDetails", _);

    resolveInvitation = "api/UserManagement/ResolveInvitation";
    setAsPrimary = "api/UserManagement/MakePrimaryUser";
    adminSetAsPrimary = "api/UserManagement/AdminSetAsPrimaryUser";

    getFilerDashboardTotals = "api/committee/Totals";
    getCurrentCommitteeByFiler = "api/committee/getcurrentbyfiler";
    getCurrentCommitteeFefstatusIdByFiler =
        "api/committee/GetCurrentCommitteeFefstatusIdByFiler";
    approveDenyAffiliation = "api/UserManagement/ApproveDenyAffiliation";
    GetCommitteeByUserId = "api/Committee/GetCommitteeByUserId?userId=";
    editCommittee = "api/Committee/EditCommittee";
    GetAllUserEntityListByUserId =
        "api/UserManagement/GetAllUserEntityListByUserId?userId=";
    getCommitteeUserTypeList = "api/LookUp/getCommitteeUserTypeList";
    GetCommitteeRegistrationById =
        "api/Committee/GetCommitteeRegistrationById/";
    GetCurrentByFiler = "api/Committee/GetCurrentByFiler?filerId=";

    //Contact

    editContactRedactionStatus = "api/Contact/redaction-status";
    getAllContacts = "api/Contact";
    getContacts = "api/Contact/getContacts?name=";
    searchContacts = "api/Contact/SearchContacts";
    getContactDetailsById = "api/Contact/getContactDetailsById?contactId=";
    getCandidatesByElectionCycle =
        "api/contact/GetCandidatesByElectionCycle?electionCycleId=";
    getOpposingCandidates(
        electionCycleId: number,
        filerId: number,
        office: string,
        district: string
    ) {
        return `api/contact/GetOpposingCandidates?electionCycleId=${electionCycleId}&filerId=${filerId}&office=${office}&district=${district}`;
    }
    editContact = "api/contact/EditContact";
    editContactRecordStatusId = "api/contact/editContactRecordStatusId";
    //Committee Office
    getStatelist = "api/common/state/getList";
    getStatusList = "api/LookUp/getStatusList";
    getOfficerTypeList = "api/LookUp/getLookUps";
    getAllOfficeList = "api/LookUp/getLookUps?lookUpTypeCode=OFF";
    getList = "api/LookUp/getLookUps";
    getCommitteeList = "api/Committee/getList";
    getOfficeSearchByName = "api/Committee/GetOfficersListByName?officerName=";
    getCommitteeDetails = "api/Committee/GetCommitteeDetail";
    getManageCommitteeList = "api/UserManagement/GetManageFilerDetail";
    downloadCSV = "api/Committee/DownloadManageFilerList";
    // getOfficerTypesList = "api/LookUp/getOfficerTypesList";
    saveOfficers = "api/Officer/saveOfficers";
    addNewCommitteeUser = "api/Committee/addNewCommitteeUser";
    // Districts
    getDistrictsList = "api/District/";
    addDistrict = "api/District/";
    deleteDistrict = "api/District/";
    //Ballot Issue
    getBallotIssueById = "api/Committee/GetBallotIssuedById?ballotIssueId=";
    getSupportingCommitteesByBallotIssued =
        "api/Committee/GetSupportingCommitteesByBallotIssued?ballotIssueId=";
    getOpposingCommitteesByBallotIssued =
        "api/Committee/GetOpposingCommitteesByBallotIssued?ballotIssueId=";
    getSupportingContributorsByBallotIssued =
        "api/Committee/GetSupportingContributorsByBallotIssued?ballotIssueId=";
    getLinkedIssueCommitteesBallots =
        "api/SystemManagement/GetLinkedIssueCommitteesBallots?searchKeyword=";
    updateLinkedIssueCommitteeBallot =
        "api/SystemManagement/UpdateLinkedIssueCommitteeBallot";
    //getSupportingContributorsByCommittee = 'api/Committee/GetSupportingContributorsByCommittee?filerId=';
    getSupportingContributorsByCommittee(
        filerId: number,
        electionCycleId: number
    ) {
        return `api/Committee/GetSupportingContributorsByCommittee?filerId=${filerId}&electionCycleId=${electionCycleId}`;
    }
    getOpposingContributorsByBallotIssued =
        "api/Committee/GetOpposingContributorsByBallotIssued?ballotIssueId=";
    getSupportingIndependentSpendersByBallotIssued =
        "api/Committee/GetSupportingIndependentSpendersByBallotIssued?ballotIssueId=";
    //getSupportingIndependentSpendersByCommittee = 'api/Committee/GetSupportingorOpposingIndependentSpendersByCommittee?comitteeId=';
    getSupportingIndependentSpendersByCommittee(
        filerId: number,
        electionCycleId: number,
        positionType: number
    ) {
        return `api/Committee/GetSupportingorOpposingIndependentSpendersByCommittee?filerId=${filerId}&electionCycleId=${electionCycleId}&positionType=${positionType}`;
    }
    getOpposingIndependentSpendersByCommittee(
        filerId: number,
        electionCycleId: number,
        positionType: number
    ) {
        return `api/Committee/GetSupportingorOpposingIndependentSpendersByCommittee?filerId=${filerId}&electionCycleId=${electionCycleId}&positionType=${positionType}`;
    }

    getOpposingIndependentSpendersByBallotIssued =
        "api/Committee/GetOpposingIndependentSpendersByBallotIssued?ballotIssueId=";

    getSupportingExpendituresByBallotIssued =
        "api/Committee/GetSupportingExpendituresByBallotIssued?ballotIssueId=";
    //getExpendituresByCommittee = 'api/Committee/getExpendituresByCommittee?committeeId=';
    getExpendituresByCommittee(filerId: number, electionCycleId: number) {
        return `api/Committee/getExpendituresByCommittee?filerId=${filerId}&electionCycleId=${electionCycleId}`;
    }
    getOpposingExpendituresByBallotIssued =
        "api/Committee/GetOpposingExpendituresByBallotIssued?ballotIssueId=";
    getSupportingExpendituresTotalByBallotIssued =
        "api/Committee/GetSupportingExpendituresTotalByBallotIssued?ballotIssueId=";
    //getExpendituresTotalByCommittee = 'api/Committee/GetExpendituresTotalByCommittee?committeeId=';
    getExpendituresTotalByCommittee(filerId: number, electionCycleId: number) {
        return `api/Committee/getExpendituresTotalByCommittee?filerId=${filerId}&electionCycleId=${electionCycleId}`;
    }

    getContributionsTotalByCommittee(filerId: number, electionCycleId: number) {
        return `api/Committee/getContributionsTotalByCommittee?filerId=${filerId}&electionCycleId=${electionCycleId}`;
    }
    getFEFContributionsTotalByCommittee(
        filerId: number,
        electionCycleId: number
    ) {
        return `api/Committee/GetFEFContributionTotalByCommittee?filerId=${filerId}&electionCycleId=${electionCycleId}`;
    }

    getFEFExpendituresTotalByCommittee(
        filerId: number,
        electionCycleId: number
    ) {
        return `api/Committee/getFEFExpendituresTotalByCommittee?filerId=${filerId}&electionCycleId=${electionCycleId}`;
    }
    getOpposingExpendituresTotalByBallotIssued =
        "api/Committee/GetOpposingExpendituresTotalByBallotIssued?ballotIssueId=";
    getFinancialOverviewByBallotIssued =
        "api/committee/GetFinancialOverviewByBallotIssued?ballotIssueId=";

    getFinancialOverviewByCandidate(filerId: number, electionCycleId: number) {
        return `api/committee/getFinancialOverviewByCandidate?filerId=${filerId}&electionCycleId=${electionCycleId}`;
    }
    getCashOnHand = "api/committee/GetCashOnHand?filerId=";

    //Committee  to support , Second Committee ,Neighbors Opposed
    getCampaignFilingFilersByBallotIssue =
        "api/Committee/GetCampaignFilingFilerIds?ballotIssueId=";
    getCampaignFilingFiler =
        "api/Committee/GetCampaignFilingFilerByFilerId?filerId=";
    getCampaignFilingsByFiling =
        "api/Committee/GetCampaignFilingByFilingId?filingId=";
    getCampaignFilingByBallotIssue =
        "api/Committee/GetCampaignFilingByBallotIssueId?ballotIssueId=";

    //lobbyist
    updatelobbyList = "api/Lobbyist/UpdateLobbyistAdditionalInfo";
    lobbyListDetails = "api/Lobbyist/GetLobbyistDetail?lobbyistId=";
    lobbyEntities = "api/Lobbyist/getLobbyistEntities?lobbyistId=";
    updateLobbyistStatus = "api/Lobbyist/UpdateLobbyistStatus";
    updateCommitteeOrLobbyistStatus =
        "api/Committee/UpdateCommitteeorLobbyistStatus";
    saveLobbyistAffiliation = "api/Lobbyist/SaveLobbyistAffiliation";
    addNewLobbyistUser = "api/Lobbyist/addNewLobbyistUser";
    editLobbyist = "api/Lobbyist";

    //Indipendent expenduture
    SearchIndependentExpenditureByName =
        "api/IndependentExpenditure/SearchIndependentExpenditureByName?searchIndependentExpenditure=";
    saveNoteIe = "api/IndependentExpenditure/saveNoteIe";
    GetIEReportSummaryInfo =
        "api/IndependentExpenditure/GetIEReportSummaryInfo";
    GetIeReportSummaryInformationByFiler =
        "api/IndependentExpenditure/ie-report-summary/filer/";

    GetIEReportFilingInfoUserEntityDetailsByFiling = (_: {
        filingId: number;
    }): string =>
        this.applyParams(
            "api/IndependentExpenditure/getIEReportFilingInfoUserEntityDetailsByFiling",
            _
        );

    GetIEReportSummayInfoByFiling = (_: { filingId: number }): string =>
        this.applyParams(
            "api/IndependentExpenditure/GetIEReportSummaryInfoByFiling",
            _
        );

    readonly GetIEContributionByFiling = (
        type: IEContributionRoute,
        _: { filingId: number }
    ) =>
        this.applyParams(
            `api/IndependentExpenditure/GetIndependentExpenditure${type}ContributionTransactionsByFiling`,
            _
        );
    GetIndependentExpenditureMonetaryContributionTransactions =
        "api/IndependentExpenditure/GetIndependentExpenditureMonetaryContributionTransactions";
    GetIndependentExpenditureInKindContributionTransactions =
        "api/IndependentExpenditure/GetIndependentExpenditureInKindContributionTransactions";
    GetIndependentExpenditureRefundContributionTransactions =
        "api/IndependentExpenditure/GetIndependentExpenditureRefundContributionTransactions";
    SaveFillingIEReport = "api/IndependentExpenditure/SaveFillingIEReport";
    GetIndependentExpenditureIEContributionTransactions =
        "api/IndependentExpenditure/GetIndependentExpenditureIEContributionTransactions";
    GetIndependentExpenditureExistingReport =
        "api/IndependentExpenditure/GetIndependentExpenditureExistingReport";
    GetIndependentExpenditureInformationByFiler =
        "api/IndependentExpenditure/ie-information/filer/";
    GetIndependentExpenditureInformationByFiling =
        "api/IndependentExpenditure/ie-information/filing/";
    GetIeReportFilingInformationByFiling = "api/Filing/ie-report-information/";
    // auth APIs
    login = "api/UserManagement/Authenticate";
    resetPassword = "api/UserManagement/ResetPassword";
    forgotPassword = "api/UserManagement/ForgotPassword";

    //System Management
    createBallot = "api/SystemManagement/CreateBallotIssues";
    getBallot = "api/SystemManagement/GetBallotIssuesList?searchKeyword=";
    getBallotByElectionCycleId(electionCycleId: number) {
        return `${this.getBallot}&electionCycleId=${electionCycleId}`;
    }
    getSearchBallot =
        "api/SystemManagement/GetBallotIssuesByName?searchKeyword=";
    addNewIndependentExpenditureUser =
        "api/IndependentExpenditure/addNewIndependentExpenditureUser";
    editIndependentExpenditure = "api/IndependentExpenditure/";
    getBallotIssuesByElectionCycle =
        "api/SystemManagement/GetBallotIssuesByElectionCycle?electionCycle=";
    getBallotIssuesById =
        "api/SystemManagement/getBallotIssuesById?ballotIssueId=";
    updateBallotIssue = "api/SystemManagement/UpdateBallotIssues";
    deleteBallotIssue = "api/SystemManagement/DeleteBallotIssues";
    validateContributionLimits =
        "api/SystemManagement/ValidateContributionLimits";
    createContribution = "api/SystemManagement/CreateContributionLimits";
    updateContribution = "api/SystemManagement/UpdateContributionLimits";
    deleteContribution = "api/SystemManagement/DeleteContributionLimits";
    getContribution = "api/SystemManagement/GetContributionLimitsList";
    getFilerTypeList = "api/SystemManagement/GetFillerTypeList";
    getDonorTypeList = "api/SystemManagement/GetDonorTypeList";
    getElectionList = "api/SystemManagement/GetElectionList";
    addCommitteeType = "api/SystemManagement/SaveCommitteeType";
    updateCommitteeType = "api/SystemManagement/UpdateCommitteType";
    deleteCommitteeType = "api/SystemManagement/DeleteCommitteeType";
    getCommitteeTypeList = "api/SystemManagement/GetCommitteeTypeList";
    validateCommitteeType = "api/SystemManagement/ValidateCommitteeType";
    validateOffice = "api/SystemManagement/ValidateOffice";
    addOffice = "api/SystemManagement/SaveOffice";
    updateOffice = "api/SystemManagement/UpdateOffice";
    deleteOffice = "api/SystemManagement/DeleteOffice";
    getallOfficeList = "api/SystemManagement/GetAllOfficeList";
    GetBallotIssuesList = "api/SystemManagement/GetBallotIssuesList";
    GetLookUpTypeList = "api/LookUp/GetLookUpTypeList";
    addMatchingLimits = "api/SystemManagement/AddMatchingLimits";
    updateMatchingLimits = "api/SystemManagement/UpdateMatchingLimits";
    deleteMatchingLimits = "api/SystemManagement/DeleteMatchingLimits";
    getMatchingLimitsList = "api/SystemManagement/GetMatchingLimitsList";
    createModifyForm = "api/SystemManagement/CreateModifyFormImageUpload";
    updateModifyForm = "api/SystemManagement/UpdateModifyFormImageUpload";
    permissionList = "api/SystemManagement/GetUserPermissionList";
    getCommitteeListBallot =
        "api/SystemManagement/GetCommiteeListMatchingBallotCode";
    getModifyForms = "api/SystemManagement/GetModifyFormImageById";
    getModifyFormImage = "/api/SystemManagement/GetModifyFormImage";
    checkContributionLimit =
        "api/SystemManagement/ValidateContributionLimitByContactAndElectionCycle";

    getTreasurerDocument = "api/Committee/TreasurerDocument";
    getCandidateAffidavit = "api/Committee/CandidateAffidavit";

    // Switch Committee
    CommitteeLobbyList = "api/Committee/GetCommitteeandLobbyistbyUser?id=";
    CommitteeLobbyDetail = "api/Committee/GetCommitteeorLobbyistbyID?id=";
    //getCommiteeDetail = 'api/Committee/GetOfficersList?committeeid=';
    GetCommitteeOfficersList =
        "api/Committee/GetCommitteeOfficersList?committeeid=";
    //getUserEntityDetails = 'api/UserManagement/getUserEntityDetails';
    GetCommitteeDetailsByFiler =
        "api/Committee/GetCommitteeDetailsByFiler?filerId=";
    CommitteeDetail = "api/UserManagement/getUserEntityDetails?entityId=";
    getCurrentUserList = "api/UserManagement/GetFilerUsers";
    deleteCurrentUser = "api/UserManagement/DeleteFilerContact";
    makeUserTreasurer = "api/UserManagement/SaveMakeTreasurer";
    sendFilerInvite = "api/UserManagement/FilerInviteUser";
    addUserToFiler = "api/UserManagement/addUserToFiler";
    LobbyistDetail = "api/Committee/GetSwitchLobbyistDetails?id=";
    lobbyistContactInformation = "api/Lobbyist/Contact";
    IEDetail = "api/Committee/GetSwitchIEDetails?id=";
    getLobbyDetails = "api/Lobbyist/GetLobbyistDetail?lobbyistId=";
    // Send / Join APIs
    sendCommittee = "api/Committee/SendCommittee";
    sendLobbyist = "api/Lobbyist/SendLobbyist";
    //manage Committee
    updateCommitteeStatus = "api/Committee/UpdateCommitteeStatus";
    getsearchFilter = "api/UserManagement/getFilerNamesByName";
    getFilerUserNamesByName = "api/UserManagement/getFilerUserNamesByName";

    //UserManagement
    getManageFilerDetail = "api/UserManagement/GetManageFilerDetail";
    getManageFiler = "api/UserManagement/getManageFilers";
    addNewUserCommitteeJoinRequest =
        "api/UserManagement/addNewUserCommitteeJoinRequest";
    addNewUserLobbyistJoinRequest =
        "api/UserManagement/addNewUserLobbyistJoinRequest";
    addNewUserAccessEthicsJoinRequest = "api/AccessEthics";
    addNewUserIndependentExpenditureJoinRequest =
        "api/UserManagement/addNewUserIndependentExpenditureJoinRequest";
    getLookUps = "api/LookUp/getLookUps?lookUpTypeCode=";
    getCommitteeByName = "api/Committee/GetCommitteeByName";
    getLookUpStateList = "api/LookUp/getStatesList";
    getTemplatesList = "api/LookUp/getTemplatesList";
    getNotificationUserTypesList = "api/LookUp/getNotificationUserTypesList";
    GetEmailScheduledNotifications =
        "api/notification/GetEmailScheduledNotifications";
    GetEmailPastNotifications = "api/notification/GetEmailPastNotifications";
    saveEmailNotification = "api/notification/saveEmailNotification";
    deleteNotification = "api/notification/deleteNotification";
    GetTemplates = "api/notification/GetTemplates";
    saveTemplate = "api/notification/saveTemplate";
    deleteTemplate = "api/notification/deleteTemplate?templateId=";
    archivedNotifications = "api/notification/archive";
    notificationEmailTest = "api/email/notificationEmailTest";
    helpEmail = "api/email/helpEmail";
    AdminHelp = "api/Help";
    templateNameExists = "api/notification/TemplateNameExists?name=";

    //Lookups
    getStatesList = "api/LookUp/getStatesList";
    getCityStatesList = "api/LookUp/GetCityStatesList";
    getElectionCycleByType = "api/LookUp/getElectionCycleByType";
    getFutureElectionCycleList = "api/LookUp/getFutureElectionCycleList";
    getElectionDates = "api/LookUp/getElectionDates";
    getTheNewElectionDatesByCommittee =
        "api/LookUp/getTheNewElectionDatesByCommittee";
    getApplicationUserStatusesList =
        "api/LookUp/getApplicationUserStatusesList";
    getCommitteeTypesList = "api/LookUp/getCommitteeTypesList";
    getContactRolesList = "api/LookUp/getContactRolesList";
    getContactTypesList = "api/LookUp/getContactTypesList";
    getContributorTypesList = "api/LookUp/getContributorTypesList";
    getElectionCycleStatusesList = "api/LookUp/getElectionCycleStatusesList";
    getElectionTypesList = "api/LookUp/getElectionTypesList";
    getExpenditureCategoriesList = "api/LookUp/getExpenditureCategoriesList";
    getFilerStatusesList = "api/LookUp/getFilerStatusesList";
    getFilerTypesList = "api/LookUp/getFilerTypesList";
    getFilingStatusesList = "api/LookUp/getFilingStatusesList";
    getFilingStatusesListExcludingDraft =
        "api/LookUp/getFilingStatusesListExcludingDraft";
    getFineFrequencyTypesList = "api/LookUp/getFineFrequencyTypesList";
    getIndependentExpenditureTargetTypesList =
        "api/LookUp/getIndependentExpenditureTargetTypesList";
    getInvoiceStatusesList = "api/LookUp/getInvoiceStatusesList";
    getLoanSubTransactionTypesList =
        "api/LookUp/getLoanSubTransactionTypesList";
    getIndependentExpenditureFilerTypesList =
        "api/LookUp/getIndependentExpenditureFilerTypesList";
    getLoanTypesList = "api/LookUp/getLoanTypesList";
    getLobbyistTypesList = "api/LookUp/getLobbyistTypesList";
    getMonetaryTypesList = "api/LookUp/getMonetaryTypesList";
    getOfficeTypesList = "api/LookUp/getOfficeTypesList";
    getPaymentMethodTypeList = "api/LookUp/getPaymentMethodTypeList";
    getPositionTypesList = "api/LookUp/getPositionTypesList";
    getTransactionSubTypesList = "api/LookUp/getTransactionSubTypesList";
    getTransactionTypesList = "api/LookUp/getTransactionTypesList";
    getContributionTransactionTypes =
        "api/LookUp/getContributionTransactionTypesList";
    getAppointingAuthoritiesList = "api/LookUp/getAppointingAuthoritiesList";
    getDepartmentsList = "api/LookUp/departments";
    getFutureFilingPeriodsList = "api/LookUp/getFutureFilingPeriodsList";
    getLookUpTypeList = "api/LookUp/getLookUps?lookUpTypeCode=";
    getGiftTypesList = "api/LookUp/getGiftTypesList";
    getElectionCycleList = "api/LookUp/getElectionCycleList";
    getAllActiveElectionCycleList = "api/LookUp/getAllActiveElectionCycleList";
    getElectionCycleListByFiler(
        filerId: number = null,
        entityId: number = null,
        filerTypeId: number = null
    ) {
        return `api/LookUp/getElectionCycleList?filerId=${filerId}&entityId=${entityId}&filerTypeId=${filerTypeId}`;
    }
    getElectionCyclesByIeTargetTypeId(ieTargetTypeId: number) {
        return `api/LookUp/GetElectionCycles?ieTargetTypeId=${ieTargetTypeId}`;
    }

    getCampaignFinanceReportElectionCycleListByFiler =
        "api/LookUp/getCampaignFinanceReportElectionCycleListByFiler?filerId=";
    getCampaignFinanceFilingPeriodsByElectionCycle(
        electionCycleId: number,
        filerId: number
    ) {
        return `api/LookUp/getCampaignFinanceReportFilingPeriodListByElectionCycle?electionCycleId=${electionCycleId}&filerId=${filerId}`;
    }

    getAddressVerification(contactId: number) {
        return `api/FEFAddressVerification/GetAddressVerification/${contactId}`;
    }

    getFilingByElectionCycleAndFiler(electionCycleId: number, filerId: number) {
        return `api/LookUp/getFilingByElectionCycleAndFiler?electionCycleId=${electionCycleId}&filerId=${filerId}`;
    }

    GetIfTheCurrentReportHasChanges =
        "api/LookUp/GetIfTheCurrentReportHasChanges";

    //public
    getpublicPenalties = "api/Payments/getpublicPenalties/";
    getPublicComplaints = "api/Committee/getPublicComplaints/";
    getPublicAudits = "api/Committee/getPublicAudits/";

    getMessageByThreadId(filerId: number, threadId: string) {
        return `api/messaging/${filerId}/thread/${threadId}`;
    }

    addNewMessage(filerId: number) {
        return `api/messaging/${filerId}`;
    }
    GetLobbyistClientsById(lobbyistId: number) {
        return `api/Lobbyist/GetLobbyistClientsById?lobbyistId=${lobbyistId}`;
    }

    GetLobbyistContacsById(lobbyistId: number) {
        return `api/Lobbyist/GetLobbyistContactsById?lobbyistId=${lobbyistId}`;
    }

    addLobbyistExpenses(filerId: number) {
        return `api/LobbyistExpenses/${filerId}`;
    }

    updateLobbyistExpenses(filerId: number) {
        return `api/LobbyistExpenses/${filerId}`;
    }

    GetLobbyistExpensesByFiler(
        filerId: number,
        filerTypeId: number,
        filingPeriodId: number
    ) {
        return `api/LobbyistExpenses/GetLobbyistExpensesByFiler?filerId=${filerId}&filerTypeId=${filerTypeId}&filingPeriodId=${filingPeriodId}`;
    }

    GetLobbyistHasDraft(
        filerId: number,
        filerTypeId: number,
        filingPeriodId: number
    ) {
        return `api/LobbyistExpenses/GetLobbyistHasDraft?filerId=${filerId}&filerTypeId=${filerTypeId}&filingPeriodId=${filingPeriodId}`;
    }

    GetLobbyistHasFiling(
        filerId: number,
        filerTypeId: number,
        filingPeriodId: number
    ) {
        return `api/LobbyistExpenses/GetLobbyistHasFiling?filerId=${filerId}&filerTypeId=${filerTypeId}&filingPeriodId=${filingPeriodId}`;
    }

    GetLobbyistReportFilingPeriodList(filerId: number) {
        return `api/LookUp/getLobbyistReportFilingPeriodList?filerId=${filerId}`;
    }

    GetLobbyistById =
        "api/LobbyistExpenses/getLobbyistExpensesById?lobbyistExpensesId=";

    DeleteLobbyistExpenses(
        filerId: number,
        lobbyistId: number,
        filingPeriodId,
        filingId
    ) {
        return `api/LobbyistExpenses/${filerId}/lobbyist/${lobbyistId}/filingPeriod/${filingPeriodId}/filingId/${
            filingId != null ? filingId : 0
        }`;
    }
    SaveFillingLobbyistByMonthlyReport =
        "api/Filing/SaveFillingLobbyistByMonthlyReport";

    BiMonthlyReports = "api/Lobbyist/BiMonthlyReports";

    getFilingByFilingPeriodAndFiler(filingPeriodId: number, filerId: number) {
        return `api/LookUp/getFilingByFilingPeriodAndFiler?filingPeriodId=${filingPeriodId}&filerId=${filerId}`;
    }
    getLobbyistExpensesByFiling =
        "api/LobbyistExpenses/GetLobbyistExpensesByFiling";

    GetlobbyistFilingInformation(filerId: number, filingId: string) {
        return `api/LobbyistExpenses/LobbyistFilingInfo?filerId=${filerId}&filingId=${filingId}`;
    }

    GetFilingPeriods(filer: number, filingPeriodId: number) {
        return `api/LobbyistExpenses/GetFilingPeriods?filerId=${filer}&filingPeriodId=${filingPeriodId}`;
    }

    getClientToLobbyistLinks(year: number = 0, client: number = 0): string {
        return `api/lobbyist/clientstolobbyists?year=${year}&clientId=${client}`;
    }

    readonly getYearsWithLobbyistRegistrations = "api/lobbyist/years";
    readonly getFilingPeriodsByYear = "api/lookup/filingperiodsbyyear";
    readonly getLobbyistExpensesByClientAndPeriod =
        "api/lobbyistexpenses/byclientandperiod";
    readonly getLobbyistExpensesByClientPeriodAndYear =
        "api/LobbyistExpenses/ByClientPeriodAndYear";
    readonly getLobbyistClientById = "api/lobbyist/client";
    readonly getAdminUnreadMessages: string = "api/messaging/admin/unread";
    getOfficerByEmail = "api/UserManagement/getOfficerByEmail?UserEmailID=";

    readonly getTemplate: string = "api/messaging/admin/template";

    getAllScheduledNotifications(): string {
        return "api/notification/getemailschedulednotifications";
    }

    getScheduledNotification(id: number): string {
        return `api/notification/getemailnotificationbyid?notificationId=${id}`;
    }

    readonly getSystemDates = "api/notification/getSystemDates";

    GetReportButtonOptionByElectionCycleAndFilingPeriod(
        filerId: number,
        electionCycleId: number,
        filingPeriodId: number,
        filingTypeId: number
    ) {
        return `api/Filing/GetReportButtonOptionByElectionCycleAndFilingPeriod?filerId=${filerId}&electionCycleId=${electionCycleId}&filingPeriodId=${filingPeriodId}&filingTypeId=${filingTypeId}`;
    }

    getCfrButtonOption = "api/Filing/button-option/";

    /*Gift*/
    GetGiftFilingInformation = "api/gift/FilingInformation";
    GetEthicsReportFilingPeriodList(filerId: number) {
        return `api/lookUp/getEthicsReportFilingPeriodList?filerId=${filerId}`;
    }

    GetGiftByFilerAndFilingPeriod(filerId: number, filingPeriodId: number) {
        return `api/Gift/GetByFilerAndFilingPeriod?filerId=${filerId}&filingPeriodId=${filingPeriodId}`;
    }

    GetGiftHasFiling(filerId: number, filingPeriodId: number) {
        return `api/Gift/GetGiftHasFiling?filerId=${filerId}&filingPeriodId=${filingPeriodId}`;
    }

    GetGiftHasDraft(filerId: number, filingPeriodId: number) {
        return `api/Gift/GetGiftHasDraft?filerId=${filerId}&filingPeriodId=${filingPeriodId}`;
    }

    SaveFilingGiftReport = "api/Gift/SaveFilingGiftReport";

    DeleteGift(filerId: number, giftId: number, filingPeriodId, filingId) {
        return `api/Gift/${filerId}/gift/${giftId}/filingPeriod/${filingPeriodId}/filingId/${
            filingId != null ? filingId : 0
        }`;
    }

    GetGiftById = "api/Gift/getGiftById?giftId=";

    GetGiftRefundByFiling = "api/gift/getGiftRefundByFiling";

    getGiftByFiling = "api/Gift/GetGiftByFiling";

    getGiftRecords = "api/Gift/records";
    saveGiftReport = "api/Gift/Report";
    /*Gift*/

    saveMajorContributionReport(filerId: number) {
        return `api/filing/SaveFilingMajorContributionReport/${filerId}`;
    }

    getMCRTransactions = "api/transaction/getMCRTransactions?filerId=";

    getMCRTransactionsByFiling = "api/transaction/getMCRTransactionsByFiling";

    checkIfExistElectionCycleActive =
        "api/transaction/CheckIfExistElectionCycleActive";
    editFEFDisbursement = "api/FEFDisbursement/";

    getInitialReportTransactionsByFiler =
        "api/transaction/GetInitialReportTransactionsByFiler/";
    getSpecialSummaryInfo = "api/filing/GetSpecialSummaryInfo/";
    getInitialCampaignFinanceReportDateRange =
        "api/filing/getInitialCampaignFinanceReportDateRange";

    readonly CFGuidanceUrl =
        "https://vote.minneapolismn.gov/candidates/campaign-finance/" as const;

    SubmitFilingCertification = "api/Filing/SaveFilingCertificationReport";

    getLatestFinancialDisclosureReportOriginal =
        "api/FinancialDisclosure/original-report";

    downloadCertificationReport(filingId: number) {
        return `api/Filing/certification-filing-pdf/?filingId=${filingId}`;
    }

    checkIfCertificationFilingAvailable =
        "api/filing/certification-filing-available";
    validateSEIEmailCheck =
        "api/UserManagement/ValidateSEIEmailCheck?UserEmailID=";

    getUnresolvedInvitations = "api/UserManagement/GetUnresolvedInvitations";
    resendInvitation = "api/UserManagement/resendInvitation";
}
