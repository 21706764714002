import { Injectable } from "@angular/core";
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { take, switchMap, catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { UserService } from "../services/user-service";
import { MasterUrlService } from "..";
import { Router } from "@angular/router";

@Injectable({
    providedIn: "root",
})
export class AuthorizeInterceptor implements HttpInterceptor {
    constructor(
        private readonly user: UserService,
        private readonly urls: MasterUrlService,
        private readonly router: Router
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (!req.url.includes("/api/")) return next.handle(req);
        
        return this.getTokenForRequest(req).pipe(
            switchMap((token) => this.processWithToken(token, req, next)),
            catchError((err) => this.retryOnUnauthorized(req, err, next))
        );
    }

    private getTokenForRequest(req: HttpRequest<any>) {
        return (
            req.method === "POST" && req.url.endsWith(this.urls.user)
                ? this.user.getIdToken()
                : this.user.getAccessToken()
        ).pipe(take(1));
    }

    private retryOnUnauthorized(
        req: HttpRequest<any>,
        err: HttpErrorResponse,
        next: HttpHandler
    ) {
        if (err.status === 403) {
            this.router.navigateByUrl("/error");
        }

        if (err.status !== 401) return throwError(err);

        return this.user.refreshToken().pipe(
            switchMap(() => this.getTokenForRequest(req)),
            switchMap((token) => this.processWithToken(token, req, next)),
            catchError((e) => {
                if (e.status === 401 || e.status === 400) {
                    this.router.navigateByUrl("/authentication/login");
                } else {
                    this.router.navigateByUrl("/");
                }

                return throwError(e);
            })
        );
    }

    // Checks if there is an access_token available in the authorize service
    // and adds it to the request in case it's targeted at the same origin as the
    // single page application.
    private processWithToken(
        token: string,
        req: HttpRequest<any>,
        next: HttpHandler
    ) {
        if (!!token && this.validateUrl(req)) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                },
            });
        }

        return next.handle(req);
    }

    private validateUrl(req: any) {
        if (
            req.url.startsWith(`${window.location.origin}/`) || // It's an absolute url with the same origin.
            req.url.startsWith(`//${window.location.host}/`) || // It's a protocol relative url with the same origin. For example: //www.example.com/api/Products
            /^\/[^\/].*/.test(req.url) || // It's a relative url like /api/Products
            req.url.startsWith(environment.baseUrl)
        )
            // It's an url that belongs to the API domain configured in the environment files
            return true;

        // It's an absolute or protocol relative url that
        // doesn't have the same origin.
        return false;
    }
}
