import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FilerInformationService } from "../services";
import { SharedService } from "../../core";

@Injectable({
    providedIn: "root",
})
export class AdminUserRequiredGuard implements CanActivate {
    private readonly redirectTo: UrlTree;

    constructor(
        private readonly filer: FilerInformationService,
        private readonly router: Router,
        private readonly shared: SharedService,
    ) {
        this.redirectTo = router.createUrlTree(["public"]);
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        return this.filer.current$.pipe(
            map(
                (_) =>
                    this.shared.isAdminUser() ||
                    (!_ &&
                        this.router.createUrlTree(["switch"], {
                            queryParams: { returnUrl: state.url },
                        })) ||
                    this.redirectTo
            )
        );
    }
}
