import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-relationships-grid',
  templateUrl: './relationships-grid.component.html',
  styleUrls: ['./relationships-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RelationshipsGridComponent implements OnInit {

@Input() data: any[];

constructor() { }

ngOnInit(): void {
}
  
}
