import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslocoModule } from "@ngneat/transloco";
import { ToggleablePasswordFieldComponent } from "../components/toggleable-password-field/toggleable-password-field.component";

@NgModule({
    declarations: [ToggleablePasswordFieldComponent],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        TranslocoModule,
    ],
    exports: [ToggleablePasswordFieldComponent]
})
export class ToggeablePasswordFieldModule {}
