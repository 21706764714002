import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-employees-grid',
  templateUrl: './employees-grid.component.html',
    styleUrls: ['./employees-grid.component.scss'],
      encapsulation: ViewEncapsulation.None,
})
export class EmployeesGridComponent implements OnInit {

    @Input() data: any[];
    mask: string;

  constructor() { }

    ngOnInit(): void {
        this.mask = "(999) 000-0000";
  }

}
