import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject, merge, Observable, of, partition, Subject } from "rxjs";
import {
    ErrorMessageService,
    MasterDataService,
    SnackbarService,
} from "src/app/core";
import {
    catchError,
    filter,
    map,
    mapTo,
    share,
    startWith,
    switchMap,
    takeUntil,
    tap,
} from "rxjs/operators";
import { TranslocoService } from "@ngneat/transloco";
import { UserAttributeName } from "src/app/core/api-services/authorization/auth.service";
import { ManageUpdateAttributeDialogComponent } from "../manage-update-attribute-dialog/manage-update-attribute-dialog.component";
import {
    DialogCloseResult,
    DialogService,
} from "@progress/kendo-angular-dialog";
import { AccountService } from "../../services/account.service";

@Component({
    selector: "app-manage-profile",
    templateUrl: "./manage-profile.component.html",
    styleUrls: ["./manage-profile.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageProfileComponent implements OnInit, OnDestroy {
    userInformationForm: FormGroup;
    states$: Observable<any[]>;

    submitEvent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );

    buttonDisabled$: Observable<boolean>;
    code: string;
    email: string;
    phone: string;

    private readonly destroy$ = new Subject<void>();

    constructor(
        public errorService: ErrorMessageService,
        private readonly accountService: AccountService,
        private readonly fb: FormBuilder,
        private readonly formatting: MasterDataService,
        private readonly snackbar: SnackbarService,
        private readonly translocoService: TranslocoService,
        private readonly dialogService: DialogService,
        private readonly snackbarService: SnackbarService
    ) {
        this.email = this.phone = "";
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    ngOnInit(): void {
        this.initUserInformationForm();

        const userData$ = this.accountService.getUserData().pipe(
            tap((u) => {
                this.userInformationForm.patchValue({
                    ...u,
                    phone: u.phone,
                    zipCode: u.zip,
                    state: u.stateCode,
                    mailingAddress1: u.address1,
                    mailingAddress2: u.address2,
                });
            }),
            mapTo(false),
            catchError((_) => {
                this.snackbar.snackbarError(
                    this.translocoService.translate(
                        "manageAccount.profile.errorWhileLoadingUserDetails"
                    )
                );
                return of(true);
            })
        );

        this.states$ = this.getStates();

        const startSubmitting$ = this.submitEvent$.pipe(
            filter((x) => x),
            share()
        );
        const [canSubmit$, cannotSubmit$] = partition(
            startSubmitting$,
            this.canSubmit
        );

        const processSubmit$ = canSubmit$.pipe(
            switchMap(this.editUser),
            map((res) => !!res),
            share(),
            catchError((_) => of(false))
        );

        const [success$, fail$] = partition(processSubmit$, (x) => x);

        const isSubmitting$ = merge(
            startSubmitting$,
            cannotSubmit$.pipe(mapTo(false)),
            success$.pipe(
                tap((_) =>
                    this.snackbar.snackbarSuccess(
                        this.translocoService.translate(
                            "manageAccount.profile.userSuccessfullyEdited"
                        )
                    )
                ),
                mapTo(false)
            ),
            fail$.pipe(
                tap((_) =>
                    this.snackbar.snackbarError(
                        this.translocoService.translate(
                            "manageAccount.profile.errorWhileEditingUser"
                        )
                    )
                ),
                mapTo(false)
            )
        );

        this.buttonDisabled$ = merge(isSubmitting$, userData$).pipe(
            startWith(true)
        );
    }

    initUserInformationForm = () => {
        this.userInformationForm = this.fb.group({
            id: [null],
            email: [null, [Validators.required, Validators.email]],
            phone: [
                null,
                [
                    Validators.required,
                    Validators.maxLength(10),
                    Validators.minLength(10),
                    Validators.pattern(this.formatting.onlyNumberPattern),
                ],
            ],
            firstName: [
                null,
                [
                    Validators.required,
                    Validators.minLength(1),
                    Validators.maxLength(80),
                ],
            ],
            lastName: [
                null,
                [
                    Validators.required,
                    Validators.minLength(1),
                    Validators.maxLength(80),
                ],
            ],
            mailingAddress1: [
                null,
                [
                    Validators.required,
                    Validators.minLength(1),
                    Validators.maxLength(150),
                    Validators.pattern(this.formatting.addressPattern),
                ],
            ],
            mailingAddress2: [
                null,
                [
                    Validators.minLength(1),
                    Validators.maxLength(150),
                    Validators.pattern(this.formatting.addressPattern),
                ],
            ],
            zipCode: [
                null,
                [
                    Validators.required,
                    Validators.pattern(this.formatting.zipcodePattern),
                ],
            ],
            city: [null, [Validators.required]],
            state: [null, [Validators.required]],
        });
    };

    submit = () => this.submitEvent$.next(true);

    getStates = () => this.accountService.getStatesList();

    editUser = () =>
        this.accountService.saveUser(this.userInformationForm.value, true);

    canSubmit = () => {
        if (!this.userInformationForm.valid)
            this.userInformationForm.markAllAsTouched();
        return this.userInformationForm.valid;
    };

    openUpdateAttributeDialog(attributeName: UserAttributeName) {
        const dialogRef = this.dialogService.open({
            content: ManageUpdateAttributeDialogComponent,
        });
        const userInfo = dialogRef.content
            .instance as ManageUpdateAttributeDialogComponent;

        userInfo.oldValue =
            attributeName == "email"
                ? this.userInformationForm.value.email
                : this.userInformationForm.value.phone;
        userInfo.attributeName = attributeName;

        dialogRef.result.pipe(
            filter((it) => !(it instanceof DialogCloseResult)),
            switchMap(() => this.accountService.refreshToken()),
            tap((session) => {
                this.userInformationForm.controls.phone?.setValue(
                    session?.phoneNumber?.slice(2)
                );
                this.snackbarService.snackbarInfo(
                    this.translocoService.translate(
                        "manageAccount.profile.attributeEditedSuccessfully"
                    )
                );
            }),
            takeUntil(this.destroy$)
        ).subscribe();
    }
}
