import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { UserFilers } from "src/app/shared/models";
import { SharedService } from "./Shared-services";
import { FilerTypeEnum } from "../../shared/models/app-enums.model";
import { map, shareReplay } from "rxjs/operators";
import { FilerDataCacheService } from "src/app/modules/switch-committee/services/filer-data-cache.service";

@Injectable({ providedIn: "root" })
export class FilerInformationService {
    private readonly filer$ = new BehaviorSubject<UserFilers>(null);
    readonly current$ = this.filer$.asObservable();

    isAdmin: boolean = false;
    filerTypeName$: Observable<string>;

    constructor(
        private readonly shared: SharedService,
        private readonly cache: FilerDataCacheService
    ) {
        const optionA = this.shared.getCurrentCommittee();
        const optionB = this.shared.getCurrentSelectedUserFiler(true);

        const current = optionA ?? optionB;

        this.isAdmin = !!current?.isAdmin;

        this.filer$.next(current?.selectedFiler);
        this.filerTypeName$ = this.current$.pipe(
            map((x) => {
                switch (x.filerTypeId) {
                    case FilerTypeEnum.Campaign:
                        return "campaign";
                    case FilerTypeEnum.EthicsFiler:
                        return "ethics";
                    case FilerTypeEnum.IndependentExpenditure:
                        return "independent expenditure";
                    case FilerTypeEnum.Lobbyists:
                        return "lobbyist";
                    case FilerTypeEnum.Unaffiliated:
                        return "unaffiliated";
                }
            }),
            shareReplay(1)
        );
    }

    setAdminRights(value: boolean): void {
        this.isAdmin = value;
    }

    setCurrent<T extends UserFilers>(value: T): void {
        this.shared.setCurrentFiler(value?.filerId ?? 0);
        this.filer$.next(this.shared.setCurrentCommittee(value, true));
    }

    update<T extends UserFilers>(updater: (_: UserFilers) => T): void {
        const lookup = {
            filerTypeId: this.filer$.value.filerTypeId,
            entityId: this.filer$.value.entityId,
        };

        const updated = updater(this.filer$.value);

        this.cache.updateCached(lookup, (existing) => ({
            ...existing,
            committeeTypeId: updated.committeeTypeId,
            entityTypeId: updated.entityTypeId,
            entityType: updated.entityType,
            entityName: updated.entityName,
        }));

        this.setCurrent(updated);
    }

    clear(): void {
        this.setCurrent(null);
        this.isAdmin = false;
    }
}
