export type CurrentPage = "SaveUserForm" | "ChooseUserType" | "NewOrJoin" | "Loading";

export enum CurrentPageEnumerator {
    EditAttributeForm,
    ConfirmationCodeForm,
    SignupForm,
    EmailConfirmationForm,
    Loading,
    MFAVerificationForm,
}
