import {
    ChangeDetectionStrategy,
    Component,
    ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { map } from "rxjs/operators";
import {
    FilerTypeEnum,
    InvitationDetails,
    UserApiModel,
} from "../../../../shared/models";

type CurrentPage = "SaveUserForm" | "ChooseUserType" | "NewOrJoin" | "Loading";

@Component({
    selector: "app-create-account",
    templateUrl: "./create-account.component.html",
    styleUrls: ["./create-account.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateAccountComponent {
    readonly filerTypeId: FilerTypeEnum = FilerTypeEnum.Campaign;

    page: CurrentPage = "SaveUserForm";
    userData: UserApiModel = undefined;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router
    ) {}

    readonly fillout$ = this.route.data.pipe(
        map((it) => it?.userData as InvitationDetails)
    );

    saveUserSuccess(data: UserApiModel & { filerId?: number }) {
        this.userData = data;

        if (!data.filerId && !data.isAdmin) {
            this.page = "NewOrJoin";
        } else {
            this.router.navigate(["switch"], { queryParams: { login: 1 } });
        }
    }
}
