import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { merge, Observable, of, partition, Subject } from "rxjs";
import {
    filter,
    map,
    share,
    switchMap,
    mapTo,
    startWith,
    shareReplay,
    tap,
    catchError,
} from "rxjs/operators";
import { ClientService, MasterUrlService } from "src/app/core";
import { UserService } from "../../../../core/services/user-service";
import { FilerTypeEnum } from "src/app/app.model";

@Component({
    selector: "app-create-account-new-or-join",
    templateUrl: "./create-account-new-or-join.component.html",
    styleUrls: ["./create-account-new-or-join.component.scss"],
})
export class CreateAccountNewOrJoinComponent implements OnInit {
    @Input() filerTypeId: FilerTypeEnum;
    @Output() back: EventEmitter<void> = new EventEmitter<void>();

    newOrJoinData$: Observable<any>;
    joiningEntity: boolean = false;

    selectedFilers: any[] = [];
    sendingJoinRequest$: Observable<boolean>;
    private sendJoinRequestSubject$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private readonly urlService: MasterUrlService,
        private readonly userService: UserService,
        private readonly clientService: ClientService,
        private readonly router: Router
    ) {}

    ngOnInit(): void {
        this.newOrJoinData$ = this.userService.getUserData().pipe(
            map(({ id }) => this.getFilerObjects(id, this.filerTypeId)),
            shareReplay()
        );

        const sendJoin$ = this.sendJoinRequestSubject$.pipe(
            filter((x) => x),
            switchMap(this.sendJoinRequest),
            share()
        );

        const [success$, fail$] = partition(sendJoin$, (x) => x);

        this.sendingJoinRequest$ = merge(
            success$.pipe(
                tap((_) =>
                    this.router.navigate(["switch"], {
                        queryParams: { login: 1 },
                    })
                ),
                mapTo(false)
            ),
            fail$.pipe(mapTo(false)),
            this.sendJoinRequestSubject$.pipe(filter((x) => x))
        ).pipe(startWith(false));
    }

    sendJoinRequest = () =>
        this.clientService
            .postData(this.urlService.requestAffiliation, {
                targets: this.selectedFilers.map((x) => x.filerId),
            })
            .pipe(
                map((x) => !x.hasError),
                catchError((_) => of(false))
            );

    requestSendJoin = () => this.sendJoinRequestSubject$.next(true);

    selectionChange = (e) => (this.selectedFilers = e);

    backButton = () => this.back.emit();

    private getFilerObjects = (id: string, filerTypeId: number) => {
        switch (filerTypeId) {
            case 1:
            case 2:
            // FilerTypeEnum.Committee
            case 3:
                return {
                    join: "manageAccount.selectCreateCommitee.selectEntity",
                    new: "manageAccount.selectCreateCommitee.createEntity",
                    description:
                        "manageAccount.selectCreateCommitee.description",
                    searchUrl: this.urlService.SearchCommitteeByName,
                    createUrl: ["/committee/committee-registration"],
                    queryParams: { userId: id },
                };
        }
    };
}
