import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
@Component({
    selector: "app-confirmation-screen",
    templateUrl: "./confirmation-screen.component.html",
    styleUrls: ["./confirmation-screen.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ConfirmationScreenComponent implements OnInit {
    @Input() type: any;
    @Input() user: any;
    emailStorage: any;
    email: any;
    userType: any;

    constructor(public router: Router) {
        this.emailStorage = localStorage.getItem("email");
        this.email = this.emailStorage?.replace(/"/g, " ");
    }

    ngOnInit(): void {
        this.userType = (this.type?.length ?? 0) > 0 ? this.type[0] : undefined;
    }

    login() {
        this.router.navigate(["/authentication/login"]);
    }
}
