import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { Observable } from "rxjs";
import {
    CommonMethods,
    ErrorMessageService,
    MasterDataService,
    MasterUrlService,
    SnackbarService,
} from "src/app/core";
import { ClientService } from "src/app/core/api-services/client.service";

@Component({
    selector: "app-current-committee-officer-information",
    templateUrl: "./current-committee-officer-information.component.html",
    styleUrls: ["./current-committee-officer-information.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class CurrentCommitteeOfficerInformationComponent {
    @Input() committeeInfo: any[];

    constructor(
        public commonMethods: CommonMethods,
        public formatting: MasterDataService,
        public errorService: ErrorMessageService,
        public snackbar: SnackbarService
    ) {}

    
}
