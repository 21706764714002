import {
    Component,
    EventEmitter,
    Input,
    Output,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "attachment-display",
    template: `
        <kendo-dialog
            id="attachmentDialog"
            [ngClass]="{
                'my-dialog': true,
                'pdf-mode': isPDF
            }"
            [title]="title"
            *ngIf="show"
            (close)="onClose()"
        >
            <object *ngIf="isPDF"
                class="popup-image"
                [data]="safeResourceUrl"
                [type]="mimeType"
            ></object>
            <img *ngIf="!isPDF"
                class="popup-image"
                [src]="safeResourceUrl"
            />
        </kendo-dialog>
    `,
    styleUrls: ["./attachment-display.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class AttachmentDisplayComponent implements OnInit {
    @Input() title: string = "View Receipt";
    @Input() mimeType: string;
    @Input() show: boolean;
    @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() url: string;

    isPDF: boolean = false;
    safeResourceUrl: any;
    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit() {
        if (this.mimeType === "application/pdf") {
            this.url = `${this.url}#view=FitH`;
            this.isPDF = true;
        }
        this.safeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.url
        );
    }

    onClose() {
        this.show = false;
        this.showChange.emit(this.show);
    }
}
