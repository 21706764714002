import { Component, OnInit, ViewChild ,Input, EventEmitter, Output} from '@angular/core';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { Subject } from 'rxjs';
import { MasterDataService, SharedService } from 'src/app/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
export interface FairElectionFundingResponseModel {
  contactId: number;
  transactionId: number;
  amount: number;
  date?: Date;
  filerId: number;
  numberOfQualifyingContributions: number;
  amountOfQualifyingContributions: number;
}
@Component({
  selector: 'app-fair-election-funding',
  templateUrl: './fair-election-funding.component.html',
  styleUrls: ['./fair-election-funding.component.css']
})
export class FairElectionFundingComponent implements OnInit {
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  @Input() data: FairElectionFundingResponseModel[];
  @Input() fefparticipationstatus: number;
  @Input() hideActions: boolean = false;   
  @Output() onSaveNote: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();

  isAdmin: boolean = false;
  isPublicView: boolean = false;
  public expandedDetailKeys: any[] = [];
  private _unsubscribeAll: Subject<any>; 
  addNoteForm: UntypedFormGroup;
  noteWindow: boolean = false;
  constructor(
    public formatting: MasterDataService,
    private formBuilder: UntypedFormBuilder,
    private sharedService: SharedService
  ) {
    this._unsubscribeAll = new Subject();
    this.addNoteForm = this.formBuilder.group({
      recordId: new UntypedFormControl(''),
      notetext: new UntypedFormControl('', Validators.required)
    });
  }

  ngOnInit(): void {
    this.isPublicView = this.sharedService.isPublicView();

    let filter;
    if (this.isPublicView) {
      filter = this.sharedService.getCurrentSelectedPublicFiler();
    } else {
      filter = this.sharedService.getCurrentSelectedUserFiler();
    }
    if (filter) {
      this.isAdmin = filter.isAdmin;
    } 
  }

  editTransaction(transactionId: number) {
    this.onEdit.emit({id: transactionId, type:'fairelection'});
   }

  deleteTransaction(transactionId: number) {
    this.onDelete.emit({id: transactionId, type:'monetary'});
  }

  addNote(event: any) {
    console.log(event);
    this.addNoteForm.controls.recordId.setValue(event.transactionId);
    this.noteWindow = true;
  }
  closeNote() {
    this.noteWindow = false;
    this.addNoteForm.reset();
  }

  saveNote(){ this.onSaveNote.emit(this.addNoteForm.value);  }
}
