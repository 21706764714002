import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './components/login/login.component';
import {LogoutComponent} from './components/logout/logout.component';
import {RouterModule} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {TranslocoModule} from '@ngneat/transloco';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from 'src/app/shared/modules/shared.module';
import {ToggeablePasswordFieldModule} from 'src/app/shared/modules/toggeable-password-field-module';
import {AccountRoutingModule} from './account-routing.module';
import {LoginFormComponent} from './components/login-form/login-form.component';
import {ConfirmEmailFormModule} from 'src/app/shared/modules/confirm-email-form.module';
import {MfaFormModule} from 'src/app/shared/modules/mfa-form.module';
import {DialogModule, WindowModule} from '@progress/kendo-angular-dialog';
import {SectionHeadingModule} from 'src/app/shared/modules/section-heading.module';
import {JoinableEntitySelectorModule} from 'src/app/shared/modules/joinable-entity-selector.module';
import {CreateAccountComponent} from './components/create-account/create-account.component';
import {ManageComponent} from './components/manage/manage.component';
import {ManageProfileComponent} from './components/manage-profile/manage-profile.component';
import {ManagePersonalDataComponent} from './components/manage-personal-data/manage-personal-data.component';
import {ManageChangePasswordComponent} from './components/manage-change-password/manage-change-password.component';
import {SignupComponent} from './components/signup/signup.component';
import {SignupFormComponent} from './components/signup-form/signup-form.component';
import {ForgotPasswordInitFlowComponent} from './components/forgot-password-init-flow/forgot-password-init-flow.component';
import {ForgotPasswordCompleteFlowComponent} from './components/forgot-password-complete-flow/forgot-password-complete-flow.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {ManageUpdateAttributeDialogComponent} from './components/manage-update-attribute-dialog/manage-update-attribute-dialog.component';
import {CreateAccountFormComponent} from './components/create-account-form/create-account-form.component';
import {ChooseUserTypeComponent} from './components/create-account-choose-user-type/choose-user-type.component';
import {CreateAccountNewOrJoinComponent} from './components/create-account-new-or-join/create-account-new-or-join.component';
import {AccountService} from './services/account.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        TranslocoModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        ToggeablePasswordFieldModule,
        AccountRoutingModule,
        RouterModule,
        ConfirmEmailFormModule,
        MfaFormModule,
        DialogModule,
        WindowModule,
        SectionHeadingModule,
        JoinableEntitySelectorModule,
    ],
    declarations: [
        LoginComponent,
        LogoutComponent,
        LoginFormComponent,
        CreateAccountComponent,
        ManageComponent,
        ManageProfileComponent,
        ManagePersonalDataComponent,
        ManageChangePasswordComponent,
        SignupComponent,
        SignupFormComponent,
        ForgotPasswordInitFlowComponent,
        ForgotPasswordCompleteFlowComponent,
        ForgotPasswordComponent,
        ManageUpdateAttributeDialogComponent,
        CreateAccountFormComponent,
        ChooseUserTypeComponent,
        CreateAccountNewOrJoinComponent,
    ],
    exports: [
        LoginComponent,
        LogoutComponent
    ],
    providers: [
        AccountService,
    ]
})
export class AccountModule {
}
