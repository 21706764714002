import { ChangeDetectionStrategy, Component } from "@angular/core";
import { of } from "rxjs";
import { Router } from "@angular/router";
import { startWith, switchMap, tap } from "rxjs/operators";
import { AccountService } from "../../services/account.service";

// The main responsibility of this component is to handle the user's logout process.
// This is the starting point for the logout process, which is usually initiated when a
// user clicks on the logout button on the LoginMenu component.
@Component({
    selector: "app-logout",
    templateUrl: "./logout.component.html",
    styleUrls: ["./logout.component.css"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent {
    constructor(
        private readonly account: AccountService,
        private readonly router: Router
    ) {}

    readonly processing$ = of(null).pipe(
        tap(() => this.account.logoutUser()),
        switchMap(() => this.router.navigateByUrl("/public")),
        startWith(false)
    );
}
