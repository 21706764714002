import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    inject,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import {
    ClearEvent,
    FileInfo,
    FileRestrictions,
    RemoveEvent,
    SelectEvent,
    SuccessEvent,
    UploadComponent,
} from "@progress/kendo-angular-upload";
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpHeaders } from "@angular/common/http";
import { ClientService, MasterUrlService } from "src/app/core";
import { IAttachmentApiModel as Attachment } from "../../modules/Filing/filing.models";
import { UserService } from "src/app/core/services/user-service";
import { environment } from "src/environments/environment";
import { merge, Observable, of, Subject } from "rxjs";
import { ApiResponse } from "../models";

export type MyFileInfo = FileInfo & { url?: string; myUid?: number };

@Component({
    selector: "transaction-upload",
    template: `
        <kendo-formfield class="cfs-upload">
            <kendo-label [for]="upload" [text]="labelText">
                <app-tooltip [message]="tooltip"></app-tooltip>
                <kendo-upload
                    #upload
                    [disabled]="deleting$ | async"
                    [multiple]="false"
                    [saveUrl]="uploadSaveUrl"
                    [removeUrl]="uploadRemoveUrl"
                    [autoUpload]="false"
                    [(ngModel)]="files"
                    (select)="onSelect($event)"
                    (remove)="onRemove($event)"
                    (clear)="onClear($event)"
                    (success)="onSuccess($event)"
                    [saveHeaders]="headers$ | async"
                    [withCredentials]="false"
                    [restrictions]="allowed$ | async"
                >
                </kendo-upload>
            </kendo-label>
            <button
                [disabled]="disableDownload"
                class="receipt"
                *ngIf="receiptUrl"
                (click)="viewReceipt()"
            >
                <span class="material-icons">receipt</span>{{"downloadFile" | transloco }}
            </button>
        </kendo-formfield>
    `,
    styles: [
        `
            .receipt {
                vertical-align: middle;
                align-content: center;
                cursor: pointer;
                display: flex;
                flex-wrap: wrap;
                padding: 4px;
                margin-top: 4px;
                border: 1px solid #dddddd;
            }
        `,
    ],
    encapsulation: ViewEncapsulation.None,
})
export class TransactionUploadComponent implements OnInit {
    // Inputs & Outputs

    @Input() files: MyFileInfo[] = [];
    @Input() labelText: string;
    @Input() disableDownload = false;

    @Output() readonly uploaded = new EventEmitter<Attachment>();
    @Output() readonly removed = new EventEmitter<number>();
    @Output() readonly view = new EventEmitter<string>();

    // View queries

    @ViewChild(UploadComponent) uploader: UploadComponent;

    // Services

    private readonly client = inject(ClientService);
    private readonly urls = inject(MasterUrlService);

    // Endpoints

    readonly uploadSaveUrl =
        environment.baseUrl + this.urls.uploadSaveAttachments;

    readonly uploadRemoveUrl = environment.baseUrl + this.urls.uploadRemove;

    // Immutable State

    readonly tooltip =
        "Upload an image, document, or spreadsheet file, such as .jpg, .png, .doc, .pdf, .csv, or .xls.";

    readonly headers$ = inject(UserService)
        .getAccessToken()
        .pipe(map((it) => this.asHeader(it)));

    readonly allowed$ = this.client
        .getData(this.urls.getFileExtensionWhiteList)
        .pipe(map((it) => ({ allowedExtensions: it } as FileRestrictions)));

    private readonly delete$ = new Subject<number>();

    // State

    receiptUrl: string = "";
    deleting$: Observable<boolean>;

    // Lifecycle Methods

    ngOnInit() {
        const startDelete$ = this.delete$.pipe(map(() => true));

        const endDelete$ = this.delete$.pipe(
            switchMap((id) => this.doDelete(id)),
            map(() => false)
        );

        this.deleting$ = merge(startDelete$, endDelete$);
    }

    // Methods

    private asHeader(token: string): HttpHeaders {
        return new HttpHeaders({ Authorization: `Bearer ${token}` });
    }

    viewReceipt(): void {
        this.view.emit(this.receiptUrl);
    }

    clearFiles() {
        this.files = [];
        this.receiptUrl = null;

        this.uploader.clearFiles();
    }

    onClear(_e: ClearEvent) {
        this.receiptUrl = null;
    }

    onSuccess(e: SuccessEvent) {
        if (e.operation !== "upload") return;

        const [file, ..._f] = e.files as MyFileInfo[];
        const [data, ..._d] = e.response.body as Attachment[];

        file.myUid = data.attachmentId;
        file.url = data.url;

        this.receiptUrl = data.url;

        this.uploaded.emit(data);
    }

    onSelect(e: SelectEvent) {
        e.files.forEach((file) => this.files.push(file));
    }

    onRemove(e: RemoveEvent) {
        const [file, ..._] = e.files as MyFileInfo[];

        if (!file.myUid) {
            this.files = this.files.filter((it) => it.myUid !== file.myUid);

            return;
        }

        e.preventDefault();

        this.delete$.next(file.myUid);
    }

    private doDelete(id: number) {
        const url = `${this.urls.uploadRemove}?attachmentId=${id}`;

        return this.client.delete(url).pipe(
            catchError(() => of([] as ApiResponse[])),
            map(([res, ..._]: ApiResponse[]) => {
                if (res?.code !== 200) return;

                this.files = this.files.filter((it) => it.myUid !== id);
                this.receiptUrl = null;

                this.removed.emit(id);
            })
        );
    }
}
