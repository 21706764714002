import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HelpsRoutingModule } from "./helps-routing.module";
import { HelpComponent } from "./help/help.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SharedModule } from "src/app/shared/modules/shared.module";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { ComingSoonComponent } from "./coming-soon/coming-soon.component";
import { TranslocoModule } from "@ngneat/transloco";
import { FlexLayoutModule } from "@angular/flex-layout";
@NgModule({
    declarations: [HelpComponent, ComingSoonComponent],
    imports: [
        CommonModule,
        HelpsRoutingModule,
        RouterModule,
        FormsModule,
        ButtonsModule,
        ReactiveFormsModule,
        SharedModule,
        TranslocoModule,
        FlexLayoutModule,
    ],
})
export class HelpsModule {}
