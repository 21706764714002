import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { ClearEvent, FileInfo, RemoveEvent, SelectEvent, SuccessEvent } from "@progress/kendo-angular-upload";
import { FilerInformationService, MasterUrlService, SnackbarService } from "src/app/core";
import { map } from "rxjs/operators";
import { HttpHeaders } from "@angular/common/http";
import { ApiResponse } from "src/app/app.model";
import { combineLatest } from "rxjs";
import { UserService } from "src/app/core/services/user-service";
import { environment } from "src/environments/environment";

export class MyFileInfo implements FileInfo {
    name: any;
    uid?: any;
    myUid?: any;
    isFailed?: boolean;
}

@Component({
    selector: "transaction-bulkupload",
    template: `
        <kendo-formfield>
            <kendo-label *ngIf="labelText" [text]="labelText"></kendo-label>
            <kendo-upload
                [saveUrl]="uploadSaveUrl"
                [autoUpload]="false"
                [concurrent]="false"
                [multiple]="true"
                [batch]="true"
                [(ngModel)]="myuploadedFiles"
                (select)="selectEventHandler($event)"
                (remove)="removeEventHandler($event)"
                (success)="successEventHandler($event)"
                (clear)="clear($event)"
                [saveHeaders]="headers$ | async"
            >
                <ng-template kendoUploadFileInfoTemplate let-files>
                    <ng-container *ngFor="let file of files">
                        <div>{{ file.name }}</div>
                        <div
                            [ngClass]="{ 'upload-box': true, 'upload-failed': file.isFailed }"
                            *ngIf="file.isFailed != undefined"
                        >
                            {{
                                (file.isFailed ? "transactions.notUploaded" : "transactions.fileSuccessfullyUploaded")
                                    | transloco
                            }}
                        </div>
                    </ng-container>
                </ng-template>
            </kendo-upload>
        </kendo-formfield>
    `,
    styleUrls: ["./transaction-bulkupload.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class TransactionBulkUploadComponent {
    @Output() sendUID = new EventEmitter<string>();
    @Output() removeUID = new EventEmitter<string>();

    @Input() myuploadedFiles: MyFileInfo[] = [];
    @Input() labelText: string;

    readonly uploadSaveUrl = environment.baseUrl + this.urls.uploadTransactionsTemplate;
    readonly headers$ = combineLatest([this.filer.current$, this.user.getAccessToken()]).pipe(
        map(
            ([filer, token]) =>
                new HttpHeaders({
                    Authorization: `Bearer ${token}`,
                    filerId: `${filer.filerId}`
                })
        )
    );

    constructor(
        private readonly urls: MasterUrlService,
        private readonly user: UserService,
        private readonly snackbar: SnackbarService,
        private readonly filer: FilerInformationService
    ) {}

    clearFiles() {
        this.myuploadedFiles = [];
    }

    clear(ev: ClearEvent) { }

    successEventHandler(e: SuccessEvent) {
        let apiResponse = <ApiResponse>e.response.body[0];

        if (e.operation == "upload") {
            let file = <MyFileInfo>e.files[0];

            // this might not be required/make sense anymore
            e.response.body.forEach((uid) => {
                file.myUid = uid;
                this.sendUID.emit(uid);
            });

            e.files.forEach((file) => ((file as MyFileInfo).isFailed = apiResponse.code !== 200));
        }

        if (apiResponse.code != 200) {
            this.snackbar.snackbarBulkUpload(apiResponse.message);
        } else {
            this.snackbar.snackbarSuccess(apiResponse.message);
        }
    }

    selectEventHandler(e: SelectEvent) {
        e.files.forEach((file) => this.myuploadedFiles.push(file));
    }

    removeEventHandler(e: RemoveEvent) {
        const myFile: MyFileInfo = <MyFileInfo>e.files[0];

        if (myFile.myUid > 0) {
            e.preventDefault();

            this.myuploadedFiles = this.myuploadedFiles.filter((f) => f.myUid !== myFile.myUid);
        } else {
            this.myuploadedFiles = this.myuploadedFiles.filter((f) => f.uid !== myFile.uid);
        }
    }
}
