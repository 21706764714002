import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
@Component({
    selector: "app-filer-layout",
    templateUrl: "./filer-layout.component.html",
    styleUrls: ["./filer-layout.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class FilerLayoutComponent implements OnInit {
    isAuthenticated: Observable<boolean>;
    constructor(
        private router: Router
    ) {}

    ngOnInit() {
        this.router.navigate(["public"]);
    }
}
