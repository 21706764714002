import { inject } from "@angular/core";
import { ResolveFn } from "@angular/router";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { InvitationDetails as Data } from "src/app/app.model";
import { ClientService, MasterUrlService } from "src/app/core";

export const userData: ResolveFn<Data> = (route) => {
    const token = route.queryParamMap.get("token");

    if (!token) return of(null as Data);

    const urls = inject(MasterUrlService);
    const request = urls.getInvitationDetails({ token });
    const client = inject(ClientService);

    return client.getData(request).pipe(
        map((data) => ({ ...data, token })),
        catchError(() => of(null as Data))
    );
};
