import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommonMethods } from "src/app/core";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-search-committee-lobbyist',
  templateUrl: './search-committee-lobbyist.component.html',
  styleUrls: ['./search-committee-lobbyist.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchCommitteeLobbyistComponent implements OnInit {
  @ViewChild("myInput") inputEl: ElementRef;
  @Input() showCommitee: any = false;
  @Input() showJoinIndipendent: any = false;
  @Input() isFromSwitchComm: any = false;
  @Input() options: any[];
  @Input() lobbyOption: any[];
  @Input() selectedItems: any;
  @Output() addSelectedEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() addSearchEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() navigatePath: EventEmitter<any> = new EventEmitter<any>();
  
  searchpopular: any;
  txtQueryChanged: Subject<string> = new Subject<string>();
  constructor(public commonMethods: CommonMethods) {
    this.txtQueryChanged.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(model => {
        this.searchpopular = model;
        this.addSearchEmitter.emit(this.searchpopular);
      });
  }

  ngOnInit(): void {
  }

    selectedItem(data: any) {
        const index = this.showCommitee ? this.options.findIndex(x => x.name === data)
            : this.lobbyOption.findIndex(x => x.name === data);
        if (index != -1) {
            const element = (this.showCommitee ? this.options : this.lobbyOption)[index];
            this.addSelectedEmitter.emit(element);
        }
  }
  routeTopath(data: any) {
    this.navigatePath.emit(data);
  }
}
