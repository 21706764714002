import { Component, OnInit } from '@angular/core';
import { SharedService } from "src/app/core";
@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.css']
})
export class ComingSoonComponent implements OnInit {
    currentFiler: any;

    constructor(
        private sharedService: SharedService) { }

    ngOnInit(): void {

        this.currentFiler = this.sharedService.getCurrentSelectedUserFiler().isAdmin;
  }

}
