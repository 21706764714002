import { Injectable } from "@angular/core";
import { UserFilers } from "src/app/app.model";
import { Entity, KeyedEntity, KeyedFiler } from "../switch-commitee.model";

@Injectable({ providedIn: "root" })
export class FilerDataCacheService {
    readonly switchCache: { [key: string]: KeyedEntity } = {};

    toKeyed(_: UserFilers[]): KeyedFiler[] {
        return _.map((f) => ({ key: this.entityKey(f), ...f }));
    }

    intoCache(_: Entity): KeyedEntity {
        const key = this.entityKey(_);
        return (this.switchCache[key] = { ..._, key });
    }

    updateCached(
        lookup: { filerTypeId: number; entityId: number },
        setup: (old: KeyedEntity) => KeyedEntity
    ) {
        const key = this.entityKey(lookup);
        const updated = setup(this.switchCache[key]);

        this.intoCache(updated);
    }

    private entityKey({
        entityId,
        filerTypeId,
    }: {
        entityId: number;
        filerTypeId: number;
    }): string {
        return `k-${filerTypeId}-${entityId}`;
    }

    get(key: string) {
        return this.switchCache[key];
    }
}
