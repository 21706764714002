import {
    Component,
    EventEmitter,
    Input, OnInit,
    Output, OnDestroy,
    ViewChild,
} from "@angular/core";
import {
    ClearEvent,
    FileInfo,
    RemoveEvent,
    SelectEvent,
    SuccessEvent,
    UploadComponent,
} from "@progress/kendo-angular-upload";
import { map } from "rxjs/operators";
import { HttpHeaders } from "@angular/common/http";
import { MasterUrlService } from "src/app/core";
import { FillingService } from "src/app/core/api-services/filling.service";
import {Observable, Subscription} from "rxjs";
import {IAttachmentApiModel} from "../../modules/Filing/filing.models";
import { UserService } from "src/app/core/services/user-service";
import { environment } from "src/environments/environment";

export class MyFileInfo implements FileInfo {
    name: any;
    uid?: any;
    myUid?: any;
}

@Component({
    selector: "payment-upload",
    template: `
        <kendo-formfield>           
            <kendo-upload
                [multiple]="false"
                [saveUrl]="uploadSaveUrl"
                [removeUrl]="uploadRemoveUrl"
                [autoUpload]="false"
                [(ngModel)]="myuploadedFiles"
                (select)="selectEventHandler($event)"
                (remove)="removeEventHandler($event)"
                (success)="successEventHandler($event)"
                [saveHeaders]="headers$ | async"
            >
            </kendo-upload>
        </kendo-formfield>
    `,
})
export class PaymentUploadComponent implements OnInit, OnDestroy{
    constructor(
        private urlService: MasterUrlService,
        private fillingservice: FillingService,
        private user: UserService
    ) {}

    @ViewChild(UploadComponent) uploader: UploadComponent;
    @Input() myuploadedFiles: Array<MyFileInfo> = [];
    @Input() labelText: string;
    @Input() events: Observable<string>;
    @Output() sendUID: EventEmitter<IAttachmentApiModel> = new EventEmitter();
    @Output() removeUID: EventEmitter<any> = new EventEmitter();

    private eventsSubscription: Subscription;

    uploadSaveUrl = environment.baseUrl + this.urlService.uploadSaveAttachments; // should represent an actual API endpoint
    uploadRemoveUrl = environment.baseUrl + this.urlService.uploadRemove; // should represent an actual API endpoint
    headers$: Observable<HttpHeaders>;
    receiptUrl: string = "";

    ngOnInit(): void {
        this.headers$ = this.user.getAccessToken().pipe(map(token => new HttpHeaders({Authorization: `Bearer ${token}`})));
        this.eventsSubscription = this.events.subscribe((receiptUrl: string) => {
            this.receiptUrl = receiptUrl;
        });
    }

    ngOnDestroy(): void {
        this.eventsSubscription.unsubscribe();
    }

    clearFiles() {
        this.myuploadedFiles = [];
    }

    successEventHandler(e: SuccessEvent) {
        if (e.operation == "upload") {
            let file = <MyFileInfo>e.files[0];            
            e.response.body.forEach((x) => {
                file.myUid = x.attachmentId;
                this.sendUID.emit(x);
            });
        }
    }

    selectEventHandler(e: SelectEvent) {
        const myFile: MyFileInfo = <MyFileInfo>e.files[0];
        if (myFile.myUid > 0) {
            e.files.forEach((file) => this.myuploadedFiles.push(file));
        }
    }

    removeEventHandler(e: RemoveEvent) {
        const myFile: MyFileInfo = <MyFileInfo>e.files[0];
        if (myFile.myUid > 0) {
            e.preventDefault();

            this.fillingservice
                .delete(
                    this.urlService.uploadRemove +
                        "?attachmentId=" +
                        myFile.myUid
                )
                .subscribe((res: any) => {
                    if (res && res.length > 0 && res[0].code == 200) {
                        this.removeUID.emit(myFile.myUid);
                    }
                });
            this.myuploadedFiles = this.myuploadedFiles.filter(
                (f) => f.myUid !== myFile.myUid
            );
        } else {
            this.myuploadedFiles = this.myuploadedFiles.filter(
                (f) => f.uid !== myFile.uid
            );
        }
    }
}
