import { Observable } from 'rxjs';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MasterDataService, MasterUrlService, ErrorMessageService, SnackbarService } from 'src/app/core';
import { take } from 'rxjs/operators';
import { ClientService } from '../../../core/api-services/client.service';
import { UserApiModel } from 'src/app/app.model';
import { TranslocoService } from '@ngneat/transloco';
import { UserService } from "src/app/core/services/user-service";

interface TextDataModel
{
  faqs: string;
  lawsAndRules: string;
  userManual: string;
  contactUs: string;
}

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HelpComponent implements OnInit {
  contactInformationForm: UntypedFormGroup;
  textForm: UntypedFormGroup;
  editing: boolean = false;
  textData: TextDataModel;
  user$: Observable<UserApiModel>;

  constructor(
    public formatting: MasterDataService,
    private urlService: MasterUrlService,
    public errorService: ErrorMessageService,
    private clientService: ClientService,
    private readonly snackbar: SnackbarService,
    private translocoService: TranslocoService,
    private readonly user: UserService,
  ) { }

  ngOnInit(): void {
    this.InformationForm();
    this.initTextForm();

    this.loadTextData()
      .pipe(take(1))
      .subscribe( res => this.textData = res );

    this.user$ = this.user.getUserData();
  }

  loadTextData = () =>
    this.clientService.getData(this.urlService.AdminHelp);

  InformationForm(committeeInfo: any = {}) {
    this.contactInformationForm = new UntypedFormGroup({
      committeeName: new UntypedFormControl(committeeInfo.committeeName || null, Validators.pattern(this.formatting.organizationNameValidation)),
      message: new UntypedFormControl(committeeInfo.message || null),
      subject: new UntypedFormControl(committeeInfo.subject || null),
      fullName: new UntypedFormControl(committeeInfo.fullName || null),
      email: new UntypedFormControl(committeeInfo.email || null),
      issueCategory: new UntypedFormControl(committeeInfo.issueCategory || null),
    });
  }
  populateTextDataForm()
  {
    this.textForm.setValue(
      {
        faqs: this.textData.faqs,
        lawsAndRules: this.textData.lawsAndRules,
        userManual: this.textData.userManual,
        contactUs: this.textData.contactUs
      }
    );
  }

  back()
  {
    this.editing = false;
  }

  editPage() {
    this.editing = true;
    this.populateTextDataForm();
  }
  Save() {
    this.saveText(this.getTextFormValue())
      .pipe(take(1))
      .subscribe(res => {
        this.textData = res;
        this.editing = false;
        this.snackbar.snackbarSuccess(this.translocoService.translate('public.help.infoEditedSuccessfuly'));
      }, err =>
      this.snackbar.snackbarError(this.translocoService.translate('public.help.errorWhileEditingInfo')))
  }

  initTextForm()
  {
    this.textForm = new UntypedFormGroup(
      {
        faqs: new UntypedFormControl(),
        lawsAndRules: new UntypedFormControl(),
        userManual: new UntypedFormControl(),
        contactUs: new UntypedFormControl()
      }
    );
  }
  getTextFormValue() : TextDataModel
  {
    return {
      faqs: this.textForm.value.faqs,
      lawsAndRules: this.textForm.value.lawsAndRules,
      userManual: this.textForm.value.userManual,
      contactUs: this.textForm.value.contactUs
    } as TextDataModel;
  }
  private readonly saveText = (data: TextDataModel) =>
    this.clientService.postData(this.urlService.AdminHelp, data);


  send() {
      this.sendPublicUser();
  }

  sendPublicUser() {
    if (this.contactInformationForm.get('message').errors !== null ||
        this.contactInformationForm.get('subject').errors !== null  ||
        this.contactInformationForm.get('email').errors !== null  ||
        this.contactInformationForm.get('fullName').errors !== null ) {
          return; 
        }

    const email = {
      content: `User's name: ${this.contactInformationForm.value.fullName}<br />Email: ${this.contactInformationForm.value.email}<br />Committee Name: ${this.contactInformationForm.value.committeeName}<br />Message: <br />${this.contactInformationForm.value.message}`,    
      subject: this.contactInformationForm.value.subject,
    };
    this.clientService
      .postData(this.urlService.helpEmail, email)
      .pipe(take(1))
      .subscribe((res: any) => {        
        if (res && res.length > 0) {
          if (res[0].code == 200) {
            this.contactInformationForm.reset();
            this.snackbar.snackbarSuccess(res[0].message);
          }
          else {
            this.snackbar.snackbarError(res[0].message);
          }
        }
      });
  }
}
