import { Injectable } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { Observable, of } from "rxjs";
import { catchError, map, filter, tap, mapTo, switchMap, take } from "rxjs/operators";
import { InvitationDetails } from "src/app/app.model";
import { ClientService, MasterUrlService, SnackbarService } from "src/app/core";
import { StatusType, UserService } from "src/app/core/services/user-service";

@Injectable()
export class AccountService {
    constructor(
        private readonly urls: MasterUrlService,
        private readonly client: ClientService,
        private readonly user: UserService,
        private readonly snackbar: SnackbarService,
        private readonly translocoService: TranslocoService
    ) {}

    /*
     *  Create a new Ethic User access
     *
     *  Return an Observable<boolean> that indicates if it was created successfully
     */
    createEthicsFiler() {
        return this.client
            .postData(this.urls.addNewUserAccessEthicsJoinRequest, {})
            .pipe(
                mapTo(true),
                catchError((_) => of(false))
            );
    }

    /*
     *
     */
    getStatesList() {
        return this.client.getData(this.urls.getStatesList);
    }

    public getUserStatusType(_filter?: StatusType) {
        if (!!_filter)
            return this.user
                .getUserStatusType()
                .pipe(filter((x) => x === _filter));
        return this.user.getUserStatusType();
    }

    public getUserData() {
        return this.user.getUserData();
    }

    updateUserAttribute(attributeName, newValue) {
        return this.user
            .updateAttribute(
                attributeName,
                (attributeName == "email" ? "" : "+1") + newValue
            )
            .pipe(
                mapTo(true),
                catchError((_) => {
                    this.snackbar.snackbarError(
                        this.translocoService.translate(
                            "manageAccount.profile.errorUpdatingAttribute"
                        )
                    );
                    return of(false);
                })
            );
    }

    verifyUserAttribute(attributeName, code) {
        return this.user.verifyAttribute(attributeName, code).pipe(
            mapTo(true),
            catchError((_) => {
                this.snackbar.snackbarError(
                    this.translocoService.translate(
                        "manageAccount.profile.errorVerifyingAttribute"
                    )
                );
                return of(false);
            })
        );
    }

    public saveUser(value: any, isEdit: boolean = false) {
        const saveRequest = isEdit ? this.client.putData(this.urls.user, value) : this.client.postData(this.urls.user, value);
    
        return saveRequest.pipe(
            tap((_) => this.user.forceReload()),
            mapTo(true),
            catchError((_) => {
                this.snackbar.snackbarError(
                    this.translocoService.translate(
                        "manageAccount.contactInfo.errorSavingUserData"
                    )
                );
                return of(false);
            })
        );
    }

    requestAffiliation(selectedFilers: any[]) {
        return this.client
            .postData(this.urls.requestAffiliation, {
                targets: selectedFilers.map((x) => x.filerId),
            })
            .pipe(
                map((x) => !x.hasError),
                catchError((_) => of(false))
            );
    }

    public initForgotPasswordFlow(email: string): Observable<string> {
        return this.user.initForgotPasswordFlow(email);
    }

    public completeForgotPasswordFlow(code, email, newPassword) {
        return this.user.completeForgotPasswordFlow(code, email, newPassword);
    }

    public changePassword(oldpass, newpass) {
        return this.user.changePassword(newpass, oldpass).pipe(
            mapTo(true),
            catchError((e) => {
                this.snackbar.snackbarError(e.message);
                return of(false);
            })
        );
    }

    public signupUser(email: string, pass: string, phone: string) {
        return this.user.signUp(email, pass, phone).pipe(
            mapTo(true),
            catchError((_) => {
                this.snackbar.snackbarError(
                    this.translocoService.translate(
                        "signup.invalidSignupAttempt"
                    ), true
                );
                return this.snackbar.closeSubject$.pipe(
                    take(1),
                    switchMap(() => {                       
                        return of(false);
                    })
                );
            })
        );
    }
    

    loginUser(email: string, password: string, rememberMe?: boolean, errorMessage?: string) {
        return this.user.signIn(email, password, rememberMe).pipe(
            mapTo(true),
            catchError((e) => {
                this.snackbar.snackbarError(
                    this.translocoService.translate(
                        !!errorMessage ? errorMessage : e.message
                    )
                );
                return of(false);
            })
        );
    }

    public logoutUser() {
        return this.user.signOut();
    }

    public loadInvitation = (token: string): Observable<InvitationDetails> => 
        this.client.getData(this.urls.getInvitationDetails({ token })).pipe(catchError(() => null));

    public refreshToken = () => this.user.refreshToken();
}
