import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { Subject } from "rxjs";
import { MasterDataService, SharedService } from "src/app/core";
import { Contributions } from "../../../app.model";
import {
    UntypedFormBuilder,
    UntypedFormControl,
    Validators,
    UntypedFormGroup,
} from "@angular/forms";
import { SelectedFiler } from "../../models";
export interface InKindContributionsResponseModel extends Contributions {
    contributionLimitError: boolean;
}
@Component({
    selector: "app-in-kind-contributions",
    templateUrl: "./in-kind-contributions.component.html",
    styleUrls: ["./in-kind-contributions.component.scss"],
})
export class InKindContributionsComponent implements OnInit {
    @Input() data: InKindContributionsResponseModel[] = [];
    @Input() unitemizedTotal: number = 0;
    @Input() hideActions: boolean = false;
    @Input() fefparticipationstatus: number;
    @Output() onSaveNote: EventEmitter<any> = new EventEmitter<any>();
    @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();

    isAdmin: boolean = false;
    isPublicView: boolean = false;
    public expandedDetailKeys: any[] = [];
    private _unsubscribeAll: Subject<any>;
    displayAttachment: string = "";
    showPopup: boolean = false;
    addNoteForm: UntypedFormGroup;
    noteWindow: boolean = false;

    constructor(
        public formatting: MasterDataService,
        private formBuilder: UntypedFormBuilder,
        private sharedService: SharedService
    ) {
        this._unsubscribeAll = new Subject();
        this.addNoteForm = this.formBuilder.group({
            recordId: new UntypedFormControl(""),
            notetext: new UntypedFormControl("", Validators.required),
        });
    }

    ngOnInit(): void {
        this.isPublicView = this.sharedService.isPublicView();
        
        let filer: SelectedFiler;
        
        if (this.isPublicView) {
            filer = this.sharedService.getCurrentSelectedPublicFiler();
        } else {
            filer = this.sharedService.getCurrentSelectedUserFiler();
        }
        if (filer) {
            this.isAdmin = filer.isAdmin;
        }
    }

    editTransaction(transactionId: number) {
        this.onEdit.emit({ id: transactionId, type: "inkind" });
    }

    deleteTransaction(transactionId: number) {
        this.onDelete.emit({ id: transactionId, type: "monetary" });
    }

    onViewReceipt(receiptImageUrl: string): void {
        this.displayAttachment = receiptImageUrl;
        this.showPopup = true;
    }

    onCloseReceipt(showPopup: boolean): void {
        this.showPopup = showPopup;
    }

    addNote(event: any) {
        console.log(event);
        this.addNoteForm.controls.recordId.setValue(event.transactionId);
        this.noteWindow = true;
    }
    closeNote() {
        this.noteWindow = false;
        this.addNoteForm.reset();
    }

    saveNote() {
        this.onSaveNote.emit(this.addNoteForm.value);
    }

    public expandDetailsBy = (
        dataItem: InKindContributionsResponseModel
    ): any => {
        return dataItem.transactionId;
    };
}
