import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Input,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { Validators, FormBuilder } from "@angular/forms";
import { Observable, Subject, merge } from "rxjs";
import { filter, switchMap, startWith, map } from "rxjs/operators";
import { ErrorMessageService } from "src/app/core";
import { MasterDataService } from "../../../../configs/master-data";
import {
    InvitationDetails as Model,
    StateListResponseApiModel as State,
} from "../../../../shared/models";
import { AccountService } from "../../services/account.service";
import { FilerTypeEnum } from "src/app/app.model";
import { ActivatedRoute } from "@angular/router";

type Token = { token: string };

@Component({
    selector: "app-create-account-form",
    templateUrl: "./create-account-form.component.html",
    styleUrls: ["./create-account-form.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateAccountFormComponent {
    // Inputs and outputs

    @Input() set data(value: Model) {
        if (!value) return;

        this.form.reset({
            firstName: value.firstName,
            lastName: value.lastName,
            city: value.city,
            mailingAddress1: value.address1,
            mailingAddress2: value.address2,
            zipCode: value.zip,
            state: value.stateCode,
        });
        this.isEthics = value.filerTypeId == FilerTypeEnum.EthicsFiler;
        this.token = value.token;
        this.filerId = value.filerId;
    }

    // Set as a side-effect from the main input
    private token: string = undefined;
    private filerId?: number = undefined;
    invitingAdmin: boolean = inject(ActivatedRoute).snapshot.queryParamMap.get('role') === "admin";
    isEthics: boolean = false;

    @Output() readonly success = this.account.getUserData().pipe(
        filter((it) => !!it),
        map((it) => ({ ...it, filerId: this.filerId } as const))
    );

    // Forms and validation

    private readonly nn = this.fb.nonNullable;

    private readonly _minReq = Validators.compose([
        Validators.required,
        Validators.minLength(1),
    ]);

    private readonly _name = Validators.compose([
        this._minReq,
        Validators.maxLength(80),
    ]);

    private readonly _address = Validators.compose([
        Validators.maxLength(150),
        Validators.pattern(this.formatting.addressPattern),
    ]);

    private readonly _zip = Validators.compose([
        Validators.required,
        Validators.pattern(this.formatting.zipcodePattern),
    ]);

    readonly form = this.fb.group({
        firstName: this.nn.control("", this._name),
        lastName: this.nn.control("", this._name),
        mailingAddress1: this.nn.control("", [this._minReq, this._address]),
        mailingAddress2: this.nn.control("", this._address),
        zipCode: this.nn.control("", this._zip),
        city: this.nn.control("", Validators.required),
        state: this.nn.control("", Validators.required),
        businessAddress1: this.nn.control(""),
        businessAddress2: this.nn.control(""),
        businessCity: this.nn.control(""),
        businessState: this.nn.control(""),
        businessZipCode: this.nn.control(""),
        orgName: this.nn.control(""),
        occupation: this.nn.control("")
    });

    // Constructor (DI)

    constructor(
        private readonly account: AccountService,
        readonly formatting: MasterDataService,
        readonly errorService: ErrorMessageService,
        private readonly fb: FormBuilder
    ) {}

    // Streams and immutable state

    readonly states$: Observable<State[]> = this.account.getStatesList();
    private readonly request$ = new Subject<typeof this.form.value & Token>();

    readonly loading$ = merge(
        this.request$.pipe(map(() => true)),
        this.request$.pipe(
            switchMap((it) => this.account.saveUser(it)),
            map(() => false)
        )
    ).pipe(startWith(false));

    requestSaveUser() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            this.form.updateValueAndValidity();
        } else {
            this.request$.next({ ...this.form.value, token: this.token });
        }
    }
}
