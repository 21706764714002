import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UserFilers } from "src/app/app.model";
import { FilerInformationService, SharedService } from "..";

@Injectable({
    providedIn: "root",
})
export class FilerRequiredGuard implements CanActivate {
    constructor(
        private readonly filer: FilerInformationService,
        private readonly shared: SharedService,
        private readonly router: Router
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        return this.filer.current$.pipe(
            map(
                (_) =>
                    this.filerSourceAvailable(_) || this.selectFiler(state.url)
            )
        );
    }

    private filerSourceAvailable(currentUserFiler: UserFilers): boolean {
        return !!(
            currentUserFiler ||
            this.shared.getCurrentSelectedUserFiler() ||
            this.shared.getCurrentSelectedPublicFiler()
        );
    }

    private selectFiler(returnUrl: string): UrlTree {
        return this.router.createUrlTree(["switch"], {
            queryParams: { returnUrl },
        });
    }
}
