import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslocoModule } from "@ngneat/transloco";
import { ButtonModule } from "@progress/kendo-angular-buttons";
import { IconModule } from '@progress/kendo-angular-icons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { MfaFormComponent } from "../components/mfa-form/mfa-form.component";
@NgModule({
    declarations: [MfaFormComponent],
    imports: [
        IconModule,
        InputsModule,
        LabelModule,
        ReactiveFormsModule,
        TranslocoModule,
        ButtonModule,
    ],
    exports: [MfaFormComponent]
})
export class MfaFormModule {}
