import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import {
    LoginActions as Login,
    LogoutActions as Logout,
} from "src/app/core/api-services/authorization";
import { LoginComponent } from "./components/login/login.component";
import { LogoutComponent } from "./components/logout/logout.component";
import { RequiresCreateAccountGuard } from "src/app/core/guards/requires-create-account.guard";
import { CreateAccountComponent } from "./components/create-account/create-account.component";
import { SignupComponent } from "./components/signup/signup.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { ManageComponent } from "./components/manage/manage.component";
import { AuthorizeGuard } from "src/app/core/guards";
import { ManageProfileComponent } from "./components/manage-profile/manage-profile.component";
import { ManagePersonalDataComponent } from "./components/manage-personal-data/manage-personal-data.component";
import { ManageChangePasswordComponent } from "./components/manage-change-password/manage-change-password.component";
import { userData } from "./services/user-data.resolver";

const routes: Routes = [
    { path: Login.Login, component: LoginComponent },
    { path: Logout.Logout, component: LogoutComponent },
    {
        path: "",
        children: [
            {
                path: "create",
                canActivate: [RequiresCreateAccountGuard],
                resolve: { userData },
                component: CreateAccountComponent,
            },
            {
                path: "signup",
                component: SignupComponent,
            },
            {
                path: "forgot-password",
                component: ForgotPasswordComponent,
            },
            {
                path: "manage",
                component: ManageComponent,
                canActivate: [AuthorizeGuard],
                children: [
                    {
                        path: "profile",
                        component: ManageProfileComponent,
                    },
                    {
                        path: "personal-data",
                        component: ManagePersonalDataComponent,
                    },
                    {
                        path: "change-password",
                        component: ManageChangePasswordComponent,
                    },
                ],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AccountRoutingModule {}
