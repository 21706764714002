import { Injectable } from "@angular/core";
import {
    CanActivate,
    ActivatedRouteSnapshot,
    UrlTree,
    Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UserService } from "../services/user-service";

@Injectable({providedIn: "root"})
export class RequiresCreateAccountGuard implements CanActivate {
    constructor(
        private readonly user: UserService,
        private readonly router: Router,
    ) {}

    canActivate(_next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {  
        return this.user.getUserStatus().pipe(map(s => s.status == "LoggedIn" && !s.user ? true : this.router.createUrlTree(["switch"])));
    }
}
