import { Component, OnInit } from '@angular/core';
import { LoaderService } from "src/app/core";

@Component({
  selector: 'app-simple-layout',
  templateUrl: './simple-layout.component.html',
  styleUrls: ['./simple-layout.component.scss']
})
export class SimpleLayoutComponent implements OnInit {
  isAdmin: boolean = false;
  isnoentity: boolean = false;
  hidesidenav: boolean = false;

  constructor(
    private loader: LoaderService,
  ) {
    loader.display(true);
  }

  ngOnInit() {
    this.loader.display(false);
  }
}
