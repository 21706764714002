import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
    selector: "app-create-account-choose-user-type",
    templateUrl: "./choose-user-type.component.html",
    styleUrls: ["./choose-user-type.component.scss"],
})
export class ChooseUserTypeComponent implements OnInit {
    @Output() filerTypeSelected: EventEmitter<number> =
        new EventEmitter<number>();

    filerTypes$: Observable<any[]>;
    filerTypeId: number = 3;

    constructor(private readonly translocoService: TranslocoService) {}

    ngOnInit(): void {
        this.filerTypes$ = this.translocoService
            .selectTranslateObject("manageAccount.userType")
            .pipe(
                map((u) => [
                    //{ filerTypeId: 5, name: u.candidateLabel, description: u.candidateDescription},
                    {
                        filerTypeId: 3,
                        name: u.committeeMemberLabel,
                        description: u.committeeMemberDescription,
                    },
                    {
                        filerTypeId: 2,
                        name: u.indExpenFilerLabel,
                        description: u.indExpenFilerDescription,
                    },
                    {
                        filerTypeId: 1,
                        name: u.lobbyistLabel,
                        description: u.lobbyistDescription,
                    },
                    {
                        filerTypeId: 4,
                        name: u.coveredOfficialLabel,
                        description: u.coveredOfficialDescription,
                    },
                ])
            );
    }

    selectFilerType = () => this.filerTypeSelected.emit(this.filerTypeId);
}
