import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-select-create-commitee-lobby',
  templateUrl: './select-create-commitee-lobby.component.html',
  styleUrls: ['./select-create-commitee-lobby.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectCreateCommiteeLobbyComponent implements OnInit {
  @Input() showCommitee: any = false;
  @Output() selectedEmitter: EventEmitter<any> = new EventEmitter<any>();
  constructor(
  ) { }

  ngOnInit(): void {
  }

  showSelect(showSel: any) {
    this.selectedEmitter.emit(showSel);
  }
}
