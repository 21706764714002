import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { SectionHeadingComponent } from "../components/section-heading/section-heading.component";

@NgModule({
    declarations: [SectionHeadingComponent],
    imports: [ButtonsModule, CommonModule],
    exports: [SectionHeadingComponent]
})
export class SectionHeadingModule {}
