import { Component, Input, OnInit, Optional } from "@angular/core";
import { ClientService } from "src/app/core/api-services/client.service";
import { MasterDataService, MasterUrlService } from "src/app/core";
import { combineLatest, Observable } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { LobbyistContactInfoApiModel } from "src/app/modules/Lobbyist/lobbyist.model";
import { ActiveLobbyistRegistrationService } from "src/app/modules/Manage-Committee/services/active-lobbyist-registration.service";
import { ActivatedRoute } from "@angular/router";
import { TargetTimePipe } from "../../pipes/denver-time.pipe";
import { TranslocoService } from "@ngneat/transloco";

@Component({
    selector: "app-lobbyist-filing-info",
    templateUrl: "./lobbyist-filing-info.component.html",
    styleUrls: ["./lobbyist-filing-info.component.scss"],
})
export class LobbyistFilingInfoComponent implements OnInit {
    @Input() filerId: number;
    @Input() title: string;
    data$: Observable<any[]>;
    filingId$: Observable<string>;

    constructor(
        private readonly client: ClientService,
        private readonly formatting: MasterDataService,
        private readonly urlService: MasterUrlService,
        @Optional()
        private readonly lobbyist: ActiveLobbyistRegistrationService,
        private readonly route: ActivatedRoute,
        private readonly timeWarp: TargetTimePipe,
        private translocoService: TranslocoService
    ) {}

    ngOnInit(): void {
        this.filingId$ = this.route.queryParamMap.pipe(
            map((params) => params.get("selectedFilingId")),
            shareReplay(1)
        );

        const lobbyistInformationLabel$ = this.translocoService.selectTranslateObject('public.lobbying.grid');

        this.data$ = combineLatest([
            this.lobbyist?.details$ ??
            this.getLobbyistContactInformation(this.filerId),
            this.filingId$,
            lobbyistInformationLabel$
        ]).pipe(
            map(([x, id, lobbyistInformationLabel]: [LobbyistContactInfoApiModel, string, any]) =>
                id !== null
                    ? [
                        { label: lobbyistInformationLabel.lobbyistID, value: x.lobbyistId },
                        { label: lobbyistInformationLabel.lobbyistName, value: x.nameOfFirmOrOrganization },
                        { label: lobbyistInformationLabel.filingPeriod, value: x.filingPeriodName },
                        { label: lobbyistInformationLabel.filingID, value: id },
                        { label: lobbyistInformationLabel.address1, value: x.address1 },
                        { label: lobbyistInformationLabel.address2, value: x.address2 },
                        { label: lobbyistInformationLabel.city, value: x.city },
                        { label: lobbyistInformationLabel.state, value: x.stateCode },
                        { label: lobbyistInformationLabel.zipCode, value: x.zipCode },
                        { label: lobbyistInformationLabel.businessPhone, value: this.normalize_phone(x.businessPhone) },
                        { label: lobbyistInformationLabel.email, value: x.email },
                        { label: lobbyistInformationLabel.submittedBy, value: x.nameOfSubmitter },
                        { label: lobbyistInformationLabel.dueDate, value: this.formatDate(x.dueDate) },
                        { label: lobbyistInformationLabel.dateSubmitted, value: this.formatDate(x.submittedDate) },
                        { label: lobbyistInformationLabel.timeLate, value: this.formatting.formatTimeLate(x.timeLate) },
                      ]
                    : [
                        { label: lobbyistInformationLabel.lobbyistName, value: x.nameOfFirmOrOrganization },
                        { label: lobbyistInformationLabel.lobbyistID, value: this.filerId },
                        { label: lobbyistInformationLabel.address1, value: x.address1 },
                        { label: lobbyistInformationLabel.address2, value: x.address2 },
                        { label: lobbyistInformationLabel.city, value: x.city },
                        { label: lobbyistInformationLabel.state, value: x.stateCode },
                        { label: lobbyistInformationLabel.zipCode, value: x.zipCode },
                        { label: lobbyistInformationLabel.businessPhone, value: this.normalize_phone(x.businessPhone) },
                        { label: lobbyistInformationLabel.email, value: x.email },
                      ]
            ),
            map((_) =>
                _.filter(
                    ({ value }) =>
                        value !== null &&
                        value !== undefined &&
                        (typeof value !== "string" || value.trim().length > 0)
                )
            )
        );
    }

    private formatDate(value: string | Date): string {
        return value
            ? this.timeWarp.transform(value, this.formatting.dateTimeFormat)
            : null;
    }

    private readonly getLobbyistContactInformation = (filerId: number) =>
        this.filingId$.pipe(
            switchMap((id) =>
                this.client.getData(
                    id == null
                        ? this.urlService.lobbyistContactInformation +
                        "?filerId=" +
                        filerId
                        : this.urlService.GetlobbyistFilingInformation(
                            filerId,
                            id
                        )
                )
            )
        );

    normalize_phone(phone: string): string {
        var p = phone.replace(/[^\d]/g, "");
        if (phone.length == 10) {
            return p.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        }
        return phone;
    }
}
