import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    ViewEncapsulation,
} from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    Validators,
} from "@angular/forms";
import { BehaviorSubject, merge, Observable, partition } from "rxjs";
import {
    filter,
    tap,
    startWith,
    switchMap,
    mapTo,
    shareReplay,
    map,
} from "rxjs/operators";
import {
    ErrorMessageService,
    MasterDataService,
} from "src/app/core";
import { AccountService } from "../../services/account.service";
import { UserDetails } from "../../models/user-details.model";
import { ActivatedRoute } from "@angular/router";
import { InvitationDetails } from "src/app/app.model";

@Component({
    selector: "app-signup-form",
    templateUrl: "./signup-form.component.html",
    styleUrls: ["./signup-form.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class SignupFormComponent implements OnInit {
    @Output() registrationSuccess: EventEmitter<UserDetails> =
        new EventEmitter<UserDetails>();

    showPassword: boolean = false;
    signupForm: FormGroup;
    signupSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    sendingSignupRequest$: Observable<boolean>;
    hasToken$: Observable<InvitationDetails>;

    constructor(
        public readonly errorService: ErrorMessageService,
        private readonly accountService: AccountService,
        private readonly formBuilder: FormBuilder,
        private readonly formatting: MasterDataService,
        private readonly route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.signupForm = this.formBuilder.group({
            email: [
                null,
                [
                    Validators.required,
                    Validators.email,
                    Validators.maxLength(120),
                    Validators.pattern(this.formatting.emailValidations),
                ],
            ],
            phone: [
                null,
                [
                    Validators.required,
                    Validators.maxLength(10),
                    Validators.minLength(10),
                    Validators.pattern(this.formatting.onlyNumberPattern),
                ],
            ],
            password: [
                null,
                [
                    Validators.required,
                    Validators.pattern(this.formatting.password),
                    Validators.minLength(8),
                    Validators.maxLength(16),
                    this.formatting.matchValidator(
                        "confirmpassword",
                        true
                    ),
                ],
            ],
            confirmPassword: [
                null,
                [
                    Validators.required,
                    this.formatting.matchValidator("password"),
                ],
            ],
        });

        const token$ = this.route.queryParams.pipe(map(p => p['token']));

        this.hasToken$ = token$.pipe(
            filter(t => !!t),
            switchMap(this.accountService.loadInvitation),
            tap(i => !!i && this.signupForm.patchValue({email: i.email}))
        );

        const signupUser$ = this.signupSubject$.pipe(
            filter((x) => x),
            switchMap(this.signupUser),
            shareReplay()
        );

        const [success$, fail$] = partition(signupUser$, (x) => x);

        this.sendingSignupRequest$ = merge(
            fail$,
            success$.pipe(
                tap((_) =>
                    this.registrationSuccess.emit({
                        email: this.signupForm.value.email,
                        password: this.signupForm.value.password,
                    } as UserDetails)
                ),
                mapTo(false)
            ),
            this.signupSubject$.pipe(filter((x) => x))
        ).pipe(startWith(false));
    }

    signUp() {
        if (this.signupForm.valid) this.signupSubject$.next(true);
        else {
            this.signupForm.markAllAsTouched();
            this.signupForm.updateValueAndValidity();
        }
    }

    signupUser = () => this.accountService.signupUser(this.signupForm.value.email, this.signupForm.value.password, "+1" + this.signupForm.value.phone);
}
