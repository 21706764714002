import { Component, HostListener, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { ErrorMessageService, MasterDataService, SnackbarService } from '../../../core';

@Component({
  selector: 'app-upload-signature',
  templateUrl: './upload-signature.component.html',
  styleUrls: ['./upload-signature.component.css']
})
export class UploadSignatureComponent extends DialogContentBase implements OnInit {
    file: string = null;
    fileName: string = null;
    disabled: boolean = true;

    constructor(
        public dialog: DialogRef,
        public formatting: MasterDataService,
        public snackbar: SnackbarService,
        private translocoService: TranslocoService,
        public errorService: ErrorMessageService,
    ) { super(dialog); }

    ngOnInit() { }

    public onCancelAction = () => this.cancel();

    public onConfirmAction = () => this.dialog.close({ file: this.file, fileName: this.fileName });

    async onFileChange(event) {
        const eventFile = event.target.files[0];
        let allowedExtensions = this.formatting.associatedDocAllowedFileTypes;
        let ext = eventFile.name.split('.').pop().toLowerCase();
        if (allowedExtensions.indexOf(ext) === -1) {
            this.snackbar.snackbarError(this.translocoService.translate('public.lobbying.registration.pleaseUploadYourSignature'));
            this.file = null;
        } else {
            this.handleFileSelect(eventFile);
            this.fileName = eventFile.name;
            this.snackbar.snackbarSuccess(this.translocoService.translate('public.lobbying.registration.signatureUploadedSuccessfully'))
        }
    }

    handleFileSelect(eventFile) {
        this.disabled = true;
        var reader = new FileReader();
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsBinaryString(eventFile);
    }

    _handleReaderLoaded(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.file = 'data:image/png;base64,' + btoa(binaryString);
        this.disabled = false;
    }

    @HostListener("keydown.esc")
    public onEsc = () => this.cancel();

    cancel = () => {
        this.file = null;
        this.dialog.close();
    }
}
