import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FilerInformationService } from "../services";

@Injectable({
    providedIn: "root",
})
export class PrimaryUserRequiredGuard implements CanActivate {
    constructor(private readonly filer: FilerInformationService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.filer.current$.pipe(map((_) => _?.isPrimary));
    }
}
