import { formatDate } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { MasterDataService } from "src/app/core";

@Pipe({
    name: "targetTimezone",
})
export class TargetTimePipe implements PipeTransform {
    constructor(private readonly formatting: MasterDataService) {}

    transform(value: string | Date, ...args: unknown[]): string {
        const format =
            (args?.length ?? 0) > 0 && typeof args[0] === "string"
                ? (args[0] as string)
                : this.formatting.dateTimeFormat;

        /* 1. We assume our input is a Date or date string, but we will make it into a Date */
        const date = new Date(value);

        /* !. Handle not-a-date, this can be intentional, i.e, to show "Today" */
        if (!(date.getTime() > 0)) return value?.toString();

        /* 2. Get the active time zone offset for whatever current local time is 
              It's important that we do this using the target date to account for DSTs
              Then "remove" the offset so we are back at UTC (not really) */
        const localOffset = date.getTimezoneOffset();

        /* !. For some cases, when the date _does_ have timezone information, this breaks
              because we are trying to do this work twice
         */
        const shouldOffset = this.formatting.shouldOffset(value);

        const targetDate = new Date(
            date.getTime() -
                (shouldOffset
                    ? this.formatting.minutesToMilliseconds(localOffset)
                    : 0)
        );

        /* 3. We assume we'll _always_ use MST, that's UTC-7... except we actually don't */
        const result = formatDate(targetDate, format, "en-US", `UTC-${this.formatting.targetTimezoneOffset(targetDate)}`);

        return value == null ? "" : result;
    }
}
