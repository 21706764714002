import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { merge, Observable, BehaviorSubject, timer } from "rxjs";
import {
    distinctUntilChanged,
    switchMap,
    filter,
    map,
    startWith,
    shareReplay,
    withLatestFrom,
    debounceTime,
} from "rxjs/operators";
import { FilerTypeEnum } from "src/app/app.model";
import { ClientService, MasterUrlService } from "..";
import { FilerInformationService } from "./filer-information.service";
import { UserService } from "./user-service";

@Injectable({
    providedIn: "root",
})
export class UnreadMessageCountService {
    readonly count$: Observable<string>;
    private readonly reload$ = new BehaviorSubject<void>(null);

    constructor(
        private readonly router: Router,
        private readonly filer: FilerInformationService,
        private readonly user: UserService,
        private readonly urls: MasterUrlService,
        private readonly client: ClientService
    ) {
        const timed$ = timer(0, 10 * 60 * 1000);

        const pageChanged$ = this.router.events.pipe(
            filter((e) => e instanceof NavigationEnd)
        );

        const filerChanged$ = this.filer.current$.pipe(
            distinctUntilChanged((a, b) => a?.filerId === b?.filerId),
            shareReplay({ refCount: true, bufferSize: 1 })
        );

        const trigger$ = merge(
            filerChanged$,
            merge(this.reload$, pageChanged$, timed$).pipe(
                withLatestFrom(filerChanged$),
                map(([_, filer]) => filer)
            )
        ).pipe(
            debounceTime(300),
            withLatestFrom(this.user.getUserStatusType()),
            filter(([_, status]) => status === "LoggedIn"),
            map(([filers, _]) => filers)
        );

        this.count$ = trigger$.pipe(
            map((current) =>
                this.urls.getUnreadNotificationCount(
                    this.filer.isAdmin &&
                        (!current ||
                            current?.filerTypeId === FilerTypeEnum.Admin)
                        ? 0
                        : current?.filerId ?? 0
                )
            ),
            switchMap(
                (url): Observable<{ count: number }> => this.client.getData(url)
            ),
            map(({ count }) => (count < 0 ? "?" : count.toString())),
            startWith("?"),
            shareReplay({ refCount: true, bufferSize: 1 })
        );
    }

    triggerUpdate(): void {
        this.reload$.next();
    }
}
