import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { MasterDataService } from "../../../core";

@Component({
    selector: "info-list",
    templateUrl: "./info-list.component.html",
    styleUrls: ["./info-list.component.scss"],
})
export class InfoListComponent implements OnChanges, OnInit {
    @Input() infoList: any[];
    @Input() header: string;
    infoListFilter: any[];
    dateFormat;

    constructor(
        private readonly data: MasterDataService
    ) { }

    ngOnInit() {
        this.dateFormat = this.data.dateTimeFormat;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.infoListFilter =
            changes.infoList?.currentValue != null
                ? changes.infoList.currentValue.filter((x) => x.value != null)
                : changes.infoList.currentValue;
    }
}
