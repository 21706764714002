import { Component, Input, ViewEncapsulation } from "@angular/core";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { filter, map } from "rxjs/operators";
import { TranslocoService } from "@ngneat/transloco";
import { BusinessInformationType } from "../../models/app-committee-registration.models";

@Component({
    selector: "app-current-committee-business-information",
    templateUrl: "./current-committee-business-information.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class CurrentCommitteeBusinessInformationComponent {
    @Input() set details(data: BusinessInformationType) { this._details.next(data); }

    private readonly _details = new BehaviorSubject<BusinessInformationType>(null);

    constructor(private readonly i18n: TranslocoService) {}

    readonly details$: Observable<{ label: string; value: any }[]> =
        combineLatest([
            this.i18n.selectTranslateObject("committeeRegistration.committeeContact"),
            this.i18n.selectTranslateObject("committeeRegistration.businessInformation"),
            this.i18n.selectTranslateObject("occupation"),
            this.i18n.selectTranslateObject("employer"),
            this._details,
        ]).pipe(
            filter(([_1, _2, _3, _4, info]) => !!info),
            map(([labels, bi, occupation, employer, info]) => [
                { label: labels.address1, value: info?.address1 },
                { label: labels.address2, value: info?.address2 },
                { label: labels.city, value: info?.city },
                { label: labels.state, value: info?.stateCode },
                { label: labels.zipcode, value: info?.zipCode },
                { label: bi.businessPhone, value: info?.businessPhone, type: "phone" },
                { label: bi.businessEmail, value: info?.businessEmail },
                { label: occupation, value: info?.occupation },
                { label: employer, value: info?.employer },
            ].filter(x => !!(typeof x.value === "string" ? x.value.trim().length : x.value)))
        );
}
