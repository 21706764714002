import {Component, EventEmitter, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ErrorMessageService} from '../../../../configs/error-message';

@Component({
    selector: "forgot-password-init-flow",
    templateUrl: "./forgot-password-init-flow.component.html",
    styleUrls: ["./forgot-password-init-flow.component.scss"],
})
export class ForgotPasswordInitFlowComponent {
    @Output() submitEmail = new EventEmitter();

    emailForm: UntypedFormGroup;

    constructor(
        public errorService: ErrorMessageService,
        private readonly fb: UntypedFormBuilder
    ) {
        this.initCreateEmailForm();
    }

    initCreateEmailForm() {
        this.emailForm = this.fb.group({
            email: ["", [Validators.required, Validators.email]],
        });
    }

    submit() {
        if (this.emailForm.valid) {
            this.submitEmail.emit(this.emailForm.value.email);
        } else {
            this.emailForm.markAllAsTouched();
        }
    }
}
