import { Component, Input, ViewEncapsulation } from "@angular/core";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { filter, map } from "rxjs/operators";
import { TranslocoService } from "@ngneat/transloco";
import { FinancialdisclosureContactInformation } from "src/app/modules/Filing/filing.models";

type Contact = FinancialdisclosureContactInformation & {
    zip: string;
    altEmail: string;
};

@Component({
    selector: "app-current-committee-contact-information",
    templateUrl: "./current-committee-contact-information.component.html",
    styleUrls: ["./current-committee-contact-information.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class CurrentCommitteeContactInformationComponent {
    @Input() set details(data: Contact) {
        this._details.next(data);
    }

    private readonly _details = new BehaviorSubject<Contact>(null);

    constructor(private readonly i18n: TranslocoService) {}

    readonly details$: Observable<{ label: string; value: any }[]> =
        combineLatest([
            this.i18n.selectTranslateObject(
                "committeeRegistration.committeeContact"
            ),
            this._details,
        ]).pipe(
            filter(([_, info]) => !!info),
            map(([labels, info]) => [
                { label: labels.address1, value: info.address1 },
                { label: labels.address2, value: info.address2 },
                { label: labels.city, value: info.city },
                { label: labels.state, value: info.stateName },
                { label: labels.zipcode, value: info.zip },
                { label: labels.campPhone, value: info.phone, type: "phone" },
                { label: labels.campEmail, value: info.email },
                { label: labels.altEmail, value: info.altEmail },
                { label: labels.commWebsite, value: info.campaignWebsite },
            ])
        );
}
