import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-search-independent-expenditure',
  templateUrl: './search-independent-expenditure.component.html',
  styleUrls: ['./search-independent-expenditure.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchIndependentExpenditureComponent implements OnInit {
  @ViewChild("myInput") inputEl: ElementRef;
  @Input() isFromSwitchComm: any = false;
  @Input() options: any;  
  @Input() selectedItems: any;
  @Output() addSelectedEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() addSearchEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() navigatePath: EventEmitter<any> = new EventEmitter<any>();
  
  searchPopular: any;
  txtQueryChanged: Subject<string> = new Subject<string>();
  constructor() {
    //Search Debounce
    this.txtQueryChanged.pipe(
      debounceTime(1000),// wait 1 sec after the last event before emitting last event
      distinctUntilChanged())// only emit if value is different from previous value
      .subscribe(model => {
        this.searchPopular = model;
        this.addSearchEmitter.emit(this.searchPopular);
      });
  }

  ngOnInit(): void {
  }

    selectedItem(data: any) {
        const index = this.options.findIndex(x => x.name === data)
        if (index != -1) {
            const element = this.options[index];
            this.addSelectedEmitter.emit(element);
        }
    }

  routeTopath(data: any) {
    this.navigatePath.emit(data);
  }
}
