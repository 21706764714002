import {
    Component,
    OnInit,
    OnDestroy,
    Input,
    EventEmitter,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { Subject } from "rxjs";
import {
    MasterDataService,
    MasterUrlService,
    SharedService,
} from "src/app/core";
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { Contributions } from "../../../app.model";
import { ClientService } from "../../../core/api-services/client.service";
import { FilerTypeEnum } from "src/app/shared/models/app-enums.model";

export interface MonetaryContributionsResponseModel extends Contributions {
    contributionLimitError: boolean;
}

@Component({
    selector: "app-monetary-contributions",
    templateUrl: "./monetary-contributions.component.html",
    styleUrls: ["./monetary-contributions.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class MonetaryContributionsComponent implements OnInit, OnDestroy {
    @Input() hideActions: boolean = false;

    total: number;
    @Input() data: MonetaryContributionsResponseModel[] = [];
    @Input() monetaryContributionUnitemizedTotal: number = 0;
    @Output() onSaveNote: EventEmitter<any> = new EventEmitter<any>();
    @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
    @Input() filerTypeId: number;
    @Input() electionCycle: any;
    @Input() fefparticipationstatus: number;

    public expandedDetailKeys: any[] = [];
    private _unsubscribeAll: Subject<any>;
    isAdmin: boolean = false;
    isPublicView: boolean = false;
    noteWindow: boolean = false;
    addNoteForm: UntypedFormGroup;
    displayAttachment: string = "";
    showFairElectionFundFlag: boolean = false;
    showPopup: boolean = false;
    file: any;

    constructor(
        public formatting: MasterDataService,
        private urlService: MasterUrlService,
        private sharedService: SharedService,
        private formBuilder: UntypedFormBuilder,
        private clientService: ClientService
    ) {
        this.addNoteForm = this.formBuilder.group({
            recordId: new UntypedFormControl(""),
            notetext: new UntypedFormControl("", Validators.required),
        });
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.isPublicView = this.sharedService.isPublicView();

        let filter;
        if (this.isPublicView) {
            filter = this.sharedService.getCurrentSelectedPublicFiler();
        } else {
            filter = this.sharedService.getCurrentSelectedUserFiler();
        }

        if (filter) {
            this.isAdmin = filter.isAdmin;
        }
    }

    checkParticipation(item: MonetaryContributionsResponseModel): boolean {
        const participating = (
            this.fefparticipationstatus === 2 || this.fefparticipationstatus === 3
        );

        if (!participating) return false;

        return (
            (item.contactTypeId) === 2 ||
            (item.contactTypeId === 3 && item.committeeTypeId && item.committeeTypeId !== 7)
        )
    }

    editTransaction(transactionId: number) {
        this.onEdit.emit({ id: transactionId, type: "monetary" });
    }

    deleteTransaction(transactionId: number) {
        this.onDelete.emit({ id: transactionId, type: "monetary" });
    }

    public expandDetailsBy = (
        dataItem: MonetaryContributionsResponseModel
    ): any => {
        return dataItem.transactionId;
    };

    addNote(event: any) {
        this.addNoteForm.controls.recordId.setValue(event.transactionId);
        this.noteWindow = true;
    }
    closeNote() {
        this.noteWindow = false;
        this.addNoteForm.reset();
    }

    saveNote() {
        this.onSaveNote.emit(this.addNoteForm.value);
    }

    ngOnDestroy() {
        this._unsubscribeAll.unsubscribe();
    }

    showUnitemizedSection(): boolean {
        return this.filerTypeId == FilerTypeEnum.IndependentExpenditure
            ? false
            : true;
    }

    downloadFile(data: MonetaryContributionsResponseModel) {
        return this.clientService
            .downloadBlobAttachment(
                this.urlService.blobAttachment +
                    "?attachmentId=" +
                    data.attachmentId
            )
            .subscribe();
    }
    onViewReceipt(data: MonetaryContributionsResponseModel): void {
        this.clientService
            .getBlobAttachment(
                this.urlService.blobAttachment +
                    "?attachmentId=" +
                    data.attachmentId
            )
            .subscribe((res) => {
                this.showPopup = true;
                this.file = res;
            });
    }
}
