export interface OfficersApiModel {
    index?: number;
    officerId: number;
    contactId: number;
    firstName: string;
    moddleName: string;
    lastName: string;
    organizationName: string;
    address1: string;
    address2: string;
    city: string;
    stateCode: string;
    countryCode: string;
    zip: string;
    email: string;
    phone: string;
    description: string;
    committeeId: number;
    officerTypeId: number;
}

export interface FilerDetails {
    candidateName: string;
    committeeName: string;
    committeeId: number;
    office: string;
    filerId: number;
    electionCycleId: number;
    electionCycleName: string;
    electionDate: string;
    district: number | string;
    dateSubmitted: Date;
}

export interface FilingInformation {
    candidateName: string;
    committeeName: string;
    committeeId: number;
    officeSought: string;
    electionCycleId: number;
    electionCycleName: string;
    electionDate: string;
    district: number;
}

export interface FEFParticipation {
    isRecorded: boolean;
    participationStatusType: number;
    showIntent: boolean;
}

export interface ContributionItem {
    date: string | Date;
    amount: number;
    monetaryType: string;
    contributorName: string;
    cycleToDateTotal: number;
    publicFundingStatus: string;
    transactionId: number;
    address1: string;
    address2: string;
    city: string;
    stateCode: string;
    zip: number;
    employer: string;
    occupation: string;
    election: string;
    attachedReceiptURL: string;
    attachmentId: number;
}

export interface ContributionHistoryItem {
    date: string | Date;
    amount: number;
    monetaryType: string;
    contributorName: string;
    cycleToDateTotal: number;
    publicFundingStatus: string;
    transactionId: number;
    address1: string;
    address2: string;
    city: string;
    stateCode: string;
    zip: number;
    employer: string;
    occupation: string;
    election: string;
    attachedReceiptURL: string;
    adminNotes: string;
    addressValidationMessage: string;
    fairElectionFundStatusName: string;
    fairElectionFundStatusTypeId: number;
    fefTransactionStatusHistorys: fefTransactionStatusHistorys[];
    receiptImageUrl: string;
    contributionLimitError: boolean;
    reason: string;
    userName: string;
    disbursementId: number;
    attachmentId: number;
    contactType: number;
    committeTypeId: number;
}

export interface fefTransactionStatusHistorys {}

export interface OfficerModel {
    committeeUserTypeName?: string;
    index?: number;
    id: string;
    firstName: string;
    lastName: string;
    committeeUserTypeId: number;
    name: string;
    description?: string;
    address1: string;
    address2: string;
    city: string;
    stateCode: string;
    zip: string;
    email: string;
    phone: string;
    tabId?: number;
    applicationUserId: string | null;
    committeeUserId?: number | null;
    isAllowedEdit?: boolean;
}

export class Officer implements OfficerModel {
    address1: string;
    address2: string;
    city: string;
    committeeUserTypeId: number;
    description: string;
    email: string;
    firstName: string;
    id: string;
    index: number;
    lastName: string;
    name: string;
    phone: string;
    stateCode: string;
    tabId: number;
    zip: string;
    applicationUserId: string;
    committeeUserId: number;
    isAllowedEdit: boolean;
    constructor(model?: OfficerModel) {
        this.address1 = model?.address1;
        this.address2 = model?.address2;
        this.city = model?.city;
        this.committeeUserTypeId = model?.committeeUserTypeId;
        this.description = model?.description;
        this.email = model?.email;
        this.firstName = model?.firstName;
        this.id = model?.id;
        this.index = model?.index;
        this.lastName = model?.lastName;
        this.name = model?.name;
        this.phone = model?.phone;
        this.stateCode = model?.stateCode;
        this.tabId = model?.tabId;
        this.zip = model?.zip;
        this.applicationUserId = model?.applicationUserId;
        this.committeeUserId = model?.committeeUserId;
        this.isAllowedEdit = model?.isAllowedEdit;
    }
}

export interface CertificationData {
    candidateName: string;
    committeeName: string;
    committeeId: number;
    officeSought: string;
    electionDate: string;
    district: string;
}

export interface ElectionCycleDetailResponseApiModel {
    electionCycleId: number;
    name: string;
    startDate?: any;
    endDate?: any;
    electionDate: any;
    desc: string;
    ieStartDate?: any;
    ieEndDate?: any;
    electionTypeId: number;
    electionTypeName: string;
    electionCycleStatusId: number;
    electionCycleStatusName: string;
    districtId?: number;
    districtName: string;
    tenantId?: number;
    createdBy: string;
    createdAt?: any;
    updatedBy: string;
    updatedOn?: any;
    recordStatusId: number;
}

export interface ElectionCycleDropdownModel {
    id: number;
    name: string;
}

export interface FairElectionFundingRequestApiModel {
    disclosure1: boolean;
    disclosure2: boolean;
    disclosure3: boolean;
    signImageUrl: string;
    filerId: number;
}

export interface FEFApplicationQualifyForPublicFundingRequestApiModel {
    filerId: number | null;
    electionCycleId: number | null;
    affirmationFlag1: boolean | null;
    affirmationFlag2: boolean | null;
    signImageURL: string;
    transactionIds: number[];
    applicationUserId: string;
}

export interface FairElectionSummaryModel {
    previouslySubmittedQualifyingContributors: number;
    approvedQualifyingContributors: number;
    deniedQualifyingContributors: number;
    newQualifyingContributorsOnThisReport: number;
    intentToQualifySubmissionDate: string;
    numberOfRequiredQualifyingContributors: number;
    additionalQualifyingContributorsNeeded: number;
}

export interface FairElectionFundingApiModel {
    fairElectionFundingId: number;
    disclosure1: boolean;
    disclosure2: boolean;
    disclosure3: boolean;
    signImageUrl: string;
    filerId: number;
    fairElectionFundStatusTypeId: number;
    committeeId: number;
    committeeName: string;
    candidateName: string;
    officeSought: string;
    electionDate: string;
    district: string;
    previouslySubmittedQualifyingContributors: number;
    approvedQualifyingContributors: number;
    deniedQualifyingContributors: number;
    newQualifyingContributorsOnThisReport: number;
    intentToQualifySubmissionDate: string;
    numberOfRequiredQualifyingContributors: number;
    additionalQualifyingContributorsNeeded: number;
}

export interface CommitteeBallotIssueApiModel {
    ballotIssueId: number;
    ballotIssue: string;
    positionTypeId: number;
    positionType: string;
}
export interface UserEntityDetailsResponseApiModel {
    contactId?: number;
    entityName: string;
    entityType: string;
    entityTypeId?: number;
    committeeTypeId: number;
    committeeType: string;
    orgName: string;
    primaryName: string;
    candidateName: string;
    treasurerName: string;
    electionDate?: Date;
    publicFund: string;
    ballotIssues: CommitteeBallotIssueApiModel[];
    purposeDesc: string;
    occupationDesc: string;
    committeeId: number;
    committeeName: string;
    district: string;
    office: string;
    filerId: number;
    filerTypeId: number;
    entityId: number;
    electionCycle: string;
    electionCycleId: number;
    filingPeriod: string;
    filingPeriodId: number;
    filingId: number;
    filingVersion: number;
    submittedBy: string;
    dateSubmitted: Date;
    dueDate?: Date;
    timeLate: string;
    isPrimary?: boolean;
    position?: number;
    positionName: string;
    ballotIssueNotes: string;
}

export interface FEFWithdrawalQualifyForPublicFundingRequestApiModel {
    filerId: number | null;
    electionCycleId: number | null;
    affirmationFlag1: boolean | null;
    signImageURL: string;
    applicationUserId: string;
}

export interface CommitteeRegistrationInfoApiModel {
    userId: string;
    committee: CommitteeRequestApiModel;
    candidate: CandidateRequestApiModel;
    officers: OfficerRequestApiModel[];
    invited: OfficerRequestApiModel[];
}

export interface CommitteeRequestApiModel {
    committeeId: number;
    committeeName: string;
    committeeTypeId: number;
    committeeTypeName: string;
    officeSoughtId: number | null;
    officeSoughtName: string;
    districtId: number | null;
    districtName: string;
    ballotIssueId: number | null;
    ballotIssueNo: string;
    electionCycleId: number | null;
    electionDate: Date;
    positionTypeName: string;
    purpose: string;
    address1: string;
    address2: string;
    city: string;
    stateCode: string;
    zipCode: string;
    businessPhone: string;
    businessEmail: string;
    altPhone: string;
    altEmail: string;
    occupation: string;
    employer: string;
    filerId: number;
    committeeRegistrationStatusId: number;
    ballotIssues: CommitteeBallotIssueApiModel;
}

export interface CandidateRequestApiModel {
    firstName: string;
    lastName: string;
    description: string;
    address1: string;
    address2: string;
    city: string;
    stateCode: string;
    zip: string;
    phone: string;
    email: string;
}

export interface OfficerRequestApiModel {
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    description: string;
    address1: string;
    address2: string;
    city: string;
    stateCode: string;
    zip: string;
    phone: string;
    email: string;
    committeeUserTypeId: number;
    committeeUserId: number;
    applicationUserId: string;
    committeeUserTypeName: string;
}

export interface FilerDetailsApiModel {
    filerId: number;
    filerTypeId: number;
    filerTypeName: string;
    filerStatusId: number;
    filerStatusName: string;
    committeeIds: number[];
    lobbyistIds: number[];
    accessEthicseIds: number[];
    independentExpenditureIds: number[];
    isTerminated: boolean;
}
