import { Injectable } from "@angular/core";
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UserService } from "../services/user-service";

enum UserAccountStatus { NoUser, RequiresAccountCreation, Ok };

@Injectable({
    providedIn: "root",
})
export class AuthorizeGuard implements CanActivate {
    constructor(
        private readonly user: UserService,
        private readonly router: Router
    ) {}

    canActivate(
        _next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        const result$ = this.user.getUserStatus()
            .pipe(
                map(s => {
                    if(s.status !== "LoggedIn") return UserAccountStatus.NoUser;
                    else if(!!s.user) return UserAccountStatus.Ok;
                    else return UserAccountStatus.RequiresAccountCreation;
                })
            );
        
        return result$.pipe(
            map(s => {
                switch (s) {
                    case UserAccountStatus.RequiresAccountCreation: return this.redirect(['account', 'create'], state.url);
                    case UserAccountStatus.NoUser: return this.redirect(['authentication', 'login'], state.url);
                    default: return true;
                }
            })
        );
    }

    private redirect = (urlParameters: string[], returnUrl: string): UrlTree => this.router.createUrlTree(urlParameters, {queryParams: { returnUrl }});
}
