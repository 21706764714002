import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'app-select-create-independent-expenditure',
  templateUrl: './select-create-independent-expenditure.component.html',
  styleUrls: ['./select-create-independent-expenditure.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectCreateIndependentExpenditureComponent implements OnInit {  
  @Output() selectedEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  showSelect(showSel: any) {
    this.selectedEmitter.emit(showSel);
  }
}
