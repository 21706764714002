import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable, of, partition, merge, Subject } from 'rxjs';
import { catchError, filter, mapTo, switchMap, shareReplay, tap, startWith, share } from 'rxjs/operators';
import { SnackbarService } from 'src/app/core';
import { UserService } from 'src/app/core/services/user-service';

@Component({
  selector: 'app-mfa-form',
  templateUrl: './mfa-form.component.html',
  styleUrls: ['./mfa-form.component.scss']
})
export class MfaFormComponent implements OnInit {
  @Output() success: EventEmitter<void> = new EventEmitter<void>();

  form: FormControl;
  private sendMfaCodeSubject$: Subject<boolean> = new Subject<boolean>();
  sendingMfaCodeRequest$: Observable<boolean>;

  constructor(
    private readonly userService: UserService,
    private readonly snackbarService: SnackbarService,
    private readonly translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.form = new FormControl(null, [Validators.required]);

    const sendMfaCode$ = this.sendMfaCodeSubject$.pipe(
      filter(x => x),
      switchMap(this.sendMfaCode),
      share()
    );

    const [success$, fail$] = partition(sendMfaCode$, x => x);

    this.sendingMfaCodeRequest$ = merge(
      success$.pipe(tap(_ => this.success.emit()), mapTo(false)),
      fail$,
      this.sendMfaCodeSubject$.pipe(filter(x => x))
    ).pipe(startWith(false), share());
  }

  requestSendMfaCode = () => {
    if(this.form.valid) this.sendMfaCodeSubject$.next(true);
    else {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
    }
  }

  sendMfaCode = () => this.userService.sendMfaCode(this.form.value).pipe(
    mapTo(true),
    catchError(_ => {
      this.snackbarService.snackbarError(this.translocoService.translate('mfaForm.invalidCode'));
      return of(false);
    })
  )
}
