import { AttachmentDisplayComponent } from './../components/attachment-display/attachment-display.component';
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DialogModule } from "@progress/kendo-angular-dialog";
@NgModule({
    declarations: [AttachmentDisplayComponent],
    imports: [CommonModule, DialogModule],
    exports: [AttachmentDisplayComponent]
})
export class AttachmentDisplayModule {}
