import { withLatestFrom } from 'rxjs/operators';
import { Component, Input, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { ClientService } from 'src/app/core/api-services/client.service';
import { MasterUrlService } from 'src/app/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActiveLobbyistRegistrationService } from 'src/app/modules/Manage-Committee/services/active-lobbyist-registration.service';
import { LobbyistsTypeEnum } from '../../models';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-lobbyist-contact-information',
  templateUrl: './lobbyist-contact-information.component.html',
  styleUrls: ['./lobbyist-contact-information.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LobbyistContactInformationComponent implements OnInit{

  @Input() filerId: number;
  @Input() title: string;
  data$: Observable<any[]>;
  constructor(
      private readonly client: ClientService,
      private readonly urlService: MasterUrlService,
      private readonly translocoService: TranslocoService,
      @Optional() private readonly lobbyist: ActiveLobbyistRegistrationService
    ) { }

  ngOnInit(): void {
      const lobbyistInformation$ = this.translocoService.selectTranslateObject('public.lobbying.grid')  

      this.data$ = (this.lobbyist?.details$ ?? this.getLobbyistContactInformation(this.filerId)).pipe(
          withLatestFrom(lobbyistInformation$),
          map(([ x, lobbyistInformation]) => {
            let itemList = [
                { label: lobbyistInformation.name, value: x.nameOfFirmOrOrganization },
                { label: lobbyistInformation.year, value: x.year },
                { label: lobbyistInformation.filerId, value: x.filerId },
                { label: lobbyistInformation.lobbyType, value: x.orgType },
                { label: lobbyistInformation.businessAddress + ' 1' , value: x.address1 },
                { label: lobbyistInformation.city, value: x.city },
                { label: lobbyistInformation.state, value: x.stateCode },
                { label: lobbyistInformation.zipCode, value: x.zipCode },
                { label: lobbyistInformation.phone, value: this.normalize_phone(x.businessPhone) },
                { label: lobbyistInformation.email, value: x.email }              
              ];

              if(x.address2 != null){
                itemList.splice(5,0,{
                  label: lobbyistInformation.businessAddress + ' 2',
                  value: x.address2
                });
              }

              if (x.orgTypeId == LobbyistsTypeEnum.Organization){
                itemList.push({
                  label: lobbyistInformation.primaryRegisteredLobbyist,
                  value: x.primaryRegisterLobbyist
                });
              }
              return itemList;                     
          }),
      );      
  }

  private readonly getLobbyistContactInformation = (filerId: number) =>
    this.client.getData(this.urlService.lobbyistContactInformation + "?filerId=" + filerId);

  normalize_phone(phone) {
    var p = phone.replace(/[^\d]/g, "");
    if (phone.length == 10) {
        return p.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    }
    return phone;
  }
  
}
