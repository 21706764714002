import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslocoModule } from "@ngneat/transloco";
import { ButtonModule } from "@progress/kendo-angular-buttons";
import { IconModule } from '@progress/kendo-angular-icons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { ConfirmEmailFormComponent } from "../components/confirm-email-form/confirm-email-form.component";
@NgModule({
    declarations: [ConfirmEmailFormComponent],
    imports: [
        IconModule,
        InputsModule,
        LabelModule,
        ReactiveFormsModule,
        TranslocoModule,
        ButtonModule,
    ],
    exports: [ConfirmEmailFormComponent]
})
export class ConfirmEmailFormModule {}
