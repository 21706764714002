import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

//Component
import { HomeLayoutComponent } from "./shared/components/home-layout/home-layout.component";
import { SimpleLayoutComponent } from "./shared/components/simple-layout/simple-layout.component";
import { FilerLayoutComponent } from "./shared/components/filer-layout/filer-layout.component";
import {
    OnlyPublicGuard,
    AuthorizeGuard,
    FilerRequiredGuard,
} from "./core/guards";

const routes: Routes = [
    {
        path: "messages",
        component: HomeLayoutComponent,
        loadChildren: () =>
            import("./modules/messages/messages.module").then(
                (m) => m.MessagesModule
            ),
    },
    {
        path: "",
        canActivate: [OnlyPublicGuard],
        component: FilerLayoutComponent,
    },
    {
        path: "home",
        component: FilerLayoutComponent,
    },
    {
        path: "lobbyist",
        component: HomeLayoutComponent,
        loadChildren: () =>
            import("./modules/Lobbyist/lobbyist.module").then(
                (m) => m.LobbyistModule
            ),
    },
    {
        path: "independent-expenditure",
        component: HomeLayoutComponent,
        loadChildren: () =>
            import(
                "./modules/independent-expenditure/independent-expenditure.module"
            ).then((m) => m.IndependentExpenditureModule),
    },
    {
        /* TODO: Ethics is still WIP */
        path: "ethics",
        component: HomeLayoutComponent,
        canActivate: [AuthorizeGuard],
        loadChildren: () =>
            import(
                "./modules/ethics/ethics.module"
            ).then((m) => m.EthicsModule),
    },
    {
        path: "committee",
        component: HomeLayoutComponent,
        loadChildren: () =>
            import("./modules/Committee/committee.module").then(
                (m) => m.CommitteeModule
            ),
    },
    {
        path: "switch",
        component: HomeLayoutComponent,
        canActivate: [AuthorizeGuard],
        loadChildren: () =>
            import("./modules/switch-committee/switch-committee.module").then(
                (m) => m.SwitchCommitteeModule
            ),
    },
    {
        path: "invitations",
        // component: HomeLayoutComponent,
        loadChildren: () =>
            import("./modules/invitations/invitations.module").then(
                (_) => _.InvitationsModule
            ),
    },
    {
        path: "error",
        loadChildren: () =>
            import("./modules/error/error.module").then(
                (_) => _.ErrorModule
            ),
    },
    {
        path: "dashboard",
        component: HomeLayoutComponent,
        canActivate: [AuthorizeGuard, FilerRequiredGuard],
        loadChildren: () =>
            import("./modules/Dashboard/dashboard.module").then(
                (m) => m.DashboardModule
            ),
    },
    {
        path: "committee-information",
        component: HomeLayoutComponent,
        canActivate: [AuthorizeGuard, FilerRequiredGuard],
        loadChildren: () =>
            import(
                "./modules/committee-information/committee-information.module"
            ).then((m) => m.CommitteeInformationModule),
    },
    {
        path: "independent-expenditure",
        component: HomeLayoutComponent,
        loadChildren: () =>
            import(
                "./modules/independent-expenditure/independent-expenditure.module"
            ).then((m) => m.IndependentExpenditureModule),
    },
    {
        path: "system",
        component: HomeLayoutComponent,
        loadChildren: () =>
            import("./modules/System-Management/system-management.module").then(
                (m) => m.SystemManagementModule
            ),
    },
    {
        path: "manage",
        component: HomeLayoutComponent,
        //canActivate: [AuthorizeGuard],
        loadChildren: () =>
            import("./modules/Manage-Committee/manage-committee.module").then(
                (m) => m.ManageCommitteeModule
            ),
    },
    {
        path: "calendar",
        component: HomeLayoutComponent,
        //canActivate: [AuthorizeGuard],
        loadChildren: () =>
            import("./modules/calendar/calendar.module").then(
                (m) => m.CalendarModule
            ),
    },

    {
        path: "filing",
        component: HomeLayoutComponent,
        //canActivate: [AuthorizeGuard],
        loadChildren: () =>
            import("./modules/Filing/filing.module").then(
                (m) => m.FilingModule
            ),
    },

    {
        path: "public",
        component: SimpleLayoutComponent,
        //canActivate: [AuthorizeGuard],
        loadChildren: () =>
            import("./modules/public/public.module").then(
                (m) => m.PublicModule
            ),
    },
    {
        path: "help",
        //canActivate: [AuthorizeGuard],
        loadChildren: () =>
            import("./modules/helps/helps.module").then((m) => m.HelpsModule),
    },
    {
        path: "committee-info-dashboard",
        component: HomeLayoutComponent,
        canActivate: [AuthorizeGuard, FilerRequiredGuard],
        loadChildren: () =>
            import(
                "./modules/committee-information/committee-information.module"
            ).then((m) => m.CommitteeInformationModule),
    },
    {
        path: "fine-and-fees",
        component: HomeLayoutComponent,
        //canActivate: [AuthorizeGuard],
        loadChildren: () =>
            import(
                "./modules/fine-and-fees-dashboard/fine-and-fees-dashboard.module"
            ).then((m) => m.FineAndFeesDashboardModule),
    },
    {
        path: "account",
        // component: HomeLayoutComponent,
        loadChildren: () =>
            import("./modules/account/account.module").then((m) => m.AccountModule),
    },
    {
        path: "messages",
        component: HomeLayoutComponent,
        loadChildren: () =>
            import("./modules/messages/messages.module").then(
                (m) => m.MessagesModule
            ),
    },
    {
        path: "Auth/Login",
        redirectTo: "Identity/Account/Login",
        pathMatch: "full",
    },
    {
        path: "ethics-dashboard",
        component: HomeLayoutComponent,
        canActivate: [AuthorizeGuard, FilerRequiredGuard],
        loadChildren: () =>
            import(
                "./modules/ethics/ethics.module"
            ).then((m) => m.EthicsModule),
    },
    {
        path: "**",
        redirectTo: "public",
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}