import { Component, OnInit, OnDestroy, ViewChild,Input, EventEmitter, Output } from '@angular/core';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { Subject } from 'rxjs';
import { MasterDataService, MasterUrlService, SharedService } from 'src/app/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { ClientService } from '../../../core/api-services/client.service';
export interface UnpaidObligationResponseModel {
  transactionId: number;
  dueDate: Date;
  payeeName: string;
  payeeId: number;
  payeeType: string;
  payeeTypeId: number;
  expenditureCategoryId: number;
  expendituresCategory: string;
  purpose: string;
  fairElectionFund: string;
  fairElectionFundFlag: boolean;
  receiptUrl: string;
  attachmentId: number;
}

@Component({
  selector: 'app-unpaid-obligations',
  templateUrl: './unpaid-obligations.component.html',
  styleUrls: ['./unpaid-obligations.component.scss']
})
export class UnpaidObligationsComponent implements OnInit, OnDestroy {
  @Input() hideActions: boolean = false;
  
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  @Input() data: UnpaidObligationResponseModel[] = [];
  @Input() fefparticipationstatus: number;
  @Output() onSaveNote: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();

  public expandedDetailKeys: any[] = [];
  private _unsubscribeAll: Subject<any>;
  isAdmin: boolean = false;
  isPublicView: boolean = false;
  entityId: number;
  filerTypeId: number;
  displayAttachment: string = "";
  showPopup: boolean = false;
  file: any;
  addNoteForm: UntypedFormGroup;
  noteWindow: boolean = false;

  constructor(
    public formatting: MasterDataService,
    private sharedService: SharedService,
    private formBuilder: UntypedFormBuilder,
    private urlService: MasterUrlService,
    private clientService: ClientService
  ) {
    this.addNoteForm = this.formBuilder.group({
      recordId : new UntypedFormControl(''),
      notetext: new UntypedFormControl('', Validators.required)
    });
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.isPublicView = this.sharedService.isPublicView();

    let filter;
    if (this.isPublicView) {
      filter = this.sharedService.getCurrentSelectedPublicFiler();
    } else {
      filter = this.sharedService.getCurrentSelectedUserFiler();
    }

    if (filter) {
      this.isAdmin = filter.isAdmin;
    }
  }

  public expandDetailsBy = (dataItem: UnpaidObligationResponseModel): any => {
    return dataItem.transactionId;
  }

  addNote(event: any) {
    console.log(event);
    this.addNoteForm.controls.recordId.setValue(event.transactionId);
    this.noteWindow = true;
  }
  closeNote() {
    this.noteWindow = false;
    this.addNoteForm.reset();
  }

  saveNote(){
    this.onSaveNote.emit(this.addNoteForm.value);
  }

  editTransaction(transactionId: number) {
    this.onEdit.emit({id: transactionId, type:'expenditure'});
  }

  deleteTransaction(transactionId: number) {
    this.onDelete.emit({id: transactionId, type:'monetary'});
  }

  ngOnDestroy() {
    this._unsubscribeAll.unsubscribe();
  }

  downloadFile(data: UnpaidObligationResponseModel) {
    return this.clientService.downloadBlobAttachment(this.urlService.blobAttachment +
            "?attachmentId=" + data.attachmentId).subscribe();
  }
  onViewReceipt(data: UnpaidObligationResponseModel): void {
      this.clientService.getBlobAttachment(this.urlService.blobAttachment +
          "?attachmentId=" + data.attachmentId).subscribe(res => {
              this.showPopup = true;
              this.file = res;   
          });
  }

}
