import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { ClientService } from "../api-services/client.service";
import { LocalstorageService } from "../localstorage/localstorage.service";
import { MasterUrlService } from "src/app/configs/api-urls";
import { ApplicationPaths } from "../api-services/authorization/api-authorization.constants";
import { Router } from "@angular/router";
import {
    FilerTypeEnum,
    FilerTypeStringEnum,
    FilingTypeEnum,
} from "../../shared/models/app-enums.model";
import {
    ApiResponse,
    SelectedFiler,
    UserApiModel,
    UserFilers,
    UserMenuResponseApiModel,
} from "../../app.model";
import { tap } from "rxjs/operators";
@Injectable()
export class SharedService {
    userId: Observable<string>;
    public canRedirect: boolean = true;
    constructor(
        private service: ClientService,
        private localStore: LocalstorageService,
        private constants: MasterUrlService,
        private router: Router,
        private urls: MasterUrlService
    ) {}

    public currentUserData(): any {
        return this.localStore.getLocalStorage(this.constants.UserData);
    }

    public GetLoggedInUser(force = false): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                if (!force && !this.IsUserStorageEmpty()) {
                    resolve(true);
                    return;
                }
                this.service
                    .getData(`${ApplicationPaths.GetLoggedInUser}`)
                    .subscribe((res: UserApiModel) => {
                        this.localStore.removeStorage([
                            this.constants.UserData,
                        ]);
                        this.localStore.setLocalStorage(
                            this.constants.UserData,
                            res
                        );
                        resolve(true);
                    });
            } catch (error) {
                console.log(`There was an error trying to log out '${error}'.`);
                resolve(false);
            }
        });
    }

    public getAllUserEntityList(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                if (!this.IsUserEntityStorageEmpty()) {
                    resolve(true);
                    return;
                }
                this.service
                    .getData(`${ApplicationPaths.GetAllUserEntityList}`)
                    .subscribe((res: UserMenuResponseApiModel) => {
                        if (
                            res.userFilersResponseApiModels != null &&
                            res.userFilersResponseApiModels.length > 0
                        ) {
                            res.userFilersResponseApiModels[0].isActive = true;
                            this.localStore.setLocalStorage(
                                this.constants.UserFilers,
                                res
                            );
                        } else {
                            this.localStore.setLocalStorage(
                                this.constants.UserFilers,
                                res
                            );
                        }
                        resolve(true);
                    });
            } catch (error) {
                console.log(`There was an error trying to log out '${error}'.`);
                resolve(false);
            }
        });
    }

    private saveUserDataToStorage(data: UserMenuResponseApiModel): void {
        if (data?.userFilersResponseApiModels?.length) {
            data.userFilersResponseApiModels[0].isActive = true;
        }

        this.localStore.setLocalStorage(this.constants.UserFilers, data);
    }

    private loadUserData(): Observable<UserMenuResponseApiModel> {
        return this.service
            .getData(`${ApplicationPaths.GetAllUserEntityList}`)
            .pipe(tap((_) => this.saveUserDataToStorage(_)));
    }

    public loadUserEntities(
        forceRemote: boolean = false
    ): Observable<UserMenuResponseApiModel> {
        return this.IsUserEntityStorageEmpty() || forceRemote
            ? this.loadUserData()
            : of(this.localStore.getLocalStorage(this.constants.UserFilers));
    }

    public setCurrentCommittee(filer: UserFilers, insert: boolean = false) {
        let data: UserMenuResponseApiModel = this.localStore.getLocalStorage(
            this.constants.UserFilers
        );

        if (!data) return;

        if (insert) {
            filer.isActive = true;
            data.userFilersResponseApiModels =
                data.userFilersResponseApiModels?.map((_) =>
                    _.filerId !== filer.filerId ? _ : filer
                );
            this.localStore.setLocalStorage(this.constants.UserFilers, data);
        }

        const toSet = {
            isAdmin: data?.isAdmin ?? false,
            userFilersResponseApiModels: data?.userFilersResponseApiModels
                ?.filter((x) => x.entityId === filer.entityId)
                ?.map<UserFilers>((f) => ({
                    ...f,
                    isActive: filer.filerId === f.filerId,
                    isPrimary:
                        filer.filerId === f.filerId
                            ? filer.isPrimary
                            : f.isPrimary,
                })),
        } as UserMenuResponseApiModel;

        this.localStore.setLocalStorage(this.constants.CurrentCommittee, toSet);

        return filer;
    }
    public getCurrentCommittee(): SelectedFiler {
        let userFilers = this.localStore.getLocalStorage(
            this.constants.CurrentCommittee
            //this.constants.UserFilers //setCurrentCommittee() sets the UserFilers, not CurrentCommittee.
        );

        if (userFilers != null) {
            // if (!userFilers.isAdmin) {
            if (
                userFilers.userFilersResponseApiModels != null &&
                userFilers.userFilersResponseApiModels.length > 0
            ) {
                const userFilerSelected =
                    userFilers.userFilersResponseApiModels.filter(
                        (x) => x.isActive
                    );
                if (userFilerSelected.length > 0) {
                    return {
                        isAdmin: userFilers.isAdmin,
                        selectedFiler: userFilerSelected[0],
                    };
                } else {
                    return {
                        isAdmin: userFilers.isAdmin,
                        selectedFiler: null,
                    };
                }
            } else {
                return { isAdmin: userFilers.isAdmin, selectedFiler: null };
            }
            // }
        } else {
            return null;
        }
    }

    public setCurrentFiler(filerId: number) {
        const data: UserMenuResponseApiModel = this.localStore.getLocalStorage(
            this.constants.UserFilers
        );

        if (!data) return;

        let found: UserFilers = undefined;

        this.localStore.setLocalStorage(this.constants.UserFilers, {
            isAdmin: data?.isAdmin ?? false,
            userFilersResponseApiModels:
                data?.userFilersResponseApiModels?.map<UserFilers>((filer) => {
                    const isActive = filerId === filer.filerId;

                    const updated = { ...filer, isActive };

                    if (isActive) found = updated;

                    return updated;
                }),
        } as UserMenuResponseApiModel);

        return found;
    }

    private IsUserStorageEmpty(): boolean {
        let userdata = this.localStore.getLocalStorage(this.constants.UserData);
        if (userdata != null && userdata != undefined && userdata != "") {
            return false;
        } else {
            return true;
        }
    }

    private IsUserEntityStorageEmpty(): boolean {
        let userFilers = this.localStore.getLocalStorage(
            this.constants.UserFilers
        );
        if (userFilers != null && userFilers != undefined && userFilers != "") {
            return false;
        } else {
            return true;
        }
    }

    public getCurrentSelectedUserFiler(
        forSelfReference: boolean = false
    ): SelectedFiler {
        let userFilers = this.localStore.getLocalStorage(
            this.constants.UserFilers
        );

        if (userFilers != null) {
            if (!userFilers.isAdmin || forSelfReference) {
                if (
                    userFilers.userFilersResponseApiModels != null &&
                    userFilers.userFilersResponseApiModels.length > 0
                ) {
                    let userFilerSelected =
                        userFilers.userFilersResponseApiModels.filter(
                            (x) => x.isActive
                        );
                    if (userFilerSelected.length > 0) {
                        return {
                            isAdmin: userFilers.isAdmin,
                            selectedFiler: userFilerSelected[0],
                        };
                    } else {
                        return {
                            isAdmin: userFilers.isAdmin,
                            selectedFiler: null,
                        };
                    }
                } else {
                    return { isAdmin: userFilers.isAdmin, selectedFiler: null };
                }
            } else {
                let adminUserFilers = this.localStore.getLocalStorage(
                    this.constants.AdminSelectedUserFilers
                );
                if (adminUserFilers) {
                    userFilers.selectedFiler = adminUserFilers.selectedFiler;
                    return userFilers;
                } else if (!userFilers.userFilersResponseApiModels) {
                    return {
                        isAdmin: userFilers.isAdmin,
                        selectedFiler: userFilers.userFilersResponseApiModels,
                    };
                }
            }
        } else {
            return null;
        }
    }

    public hasEthicsFiler(): boolean {
        const data = this.localStore.getLocalStorage(this.constants.UserFilers);

        if (!data || data.isAdmin) return true;

        const check = data.userFilersResponseApiModels;

        return check.some(({ filerTypeId }) => filerTypeId === 4);
    }

    public getuserFilersResponseApiModels(): SelectedFiler[] {
        let userFilers = this.localStore.getLocalStorage(
            this.constants.UserFilers
        );
        if (userFilers != null) {
            if (!userFilers.isAdmin) {
                if (
                    userFilers.userFilersResponseApiModels != null &&
                    userFilers.userFilersResponseApiModels.length > 0
                ) {
                    let userFilerSelected =
                        userFilers.userFilersResponseApiModels;
                    if (userFilerSelected.length > 0) {
                        return userFilerSelected;
                    }
                }
            }
        }
        return null;
    }
    public getCurrentFilers(): number {
        let userFilers = this.localStore.getLocalStorage(
            this.constants.UserFilers
        );
        if (userFilers != null) {
            if (!userFilers.isAdmin) {
                if (
                    userFilers.userFilersResponseApiModels != null &&
                    userFilers.userFilersResponseApiModels.length - 1 > 0
                ) {
                    return userFilers.userFilersResponseApiModels.length;
                }
            }
        }
        return 0;
    }

    public getCurrentSelectedPublicFiler(): SelectedFiler {
        return this.localStore.getLocalStorage(
            this.constants.PublicSelectedUserFilers
        );
    }

    public isAdminUser(): boolean {
        let userFilers = this.localStore.getLocalStorage(
            this.constants.UserFilers
        );
        if (userFilers != null) {
            return userFilers.isAdmin;
        } else {
            return false;
        }
    }

    public isPublicView(): boolean {
        const splittedRoute = this.router.url?.split("/");

        if (splittedRoute && splittedRoute[1] == "public") {
            return true;
        } else {
            return false;
        }
    }

    public async redirectBasedOnFilers(): Promise<boolean> {
        if (!this.canRedirect) return false;
        this.canRedirect = false;

        const filer = this.getCurrentSelectedUserFiler();

        if (!filer) return false;

        if (filer.isAdmin) {
            return await this.router.navigate(["/manage/committee"]);
        }

        switch (filer.selectedFiler.filerTypeId) {
            case FilerTypeEnum.Lobbyists: {
                return await this.router.navigate([
                    "/committee-information/lobby-info",
                ]);
            }
            case FilerTypeEnum.IndependentExpenditure: {
                return await this.router.navigate(["/dashboard/ie-home"]);
            }
            case FilerTypeEnum.Campaign: {
                return await this.router.navigate([
                    "/committee-info-dashboard",
                ]);
            }
            case FilerTypeEnum.EthicsFiler: {
                return await this.router.navigate(["/filing/Gift_Report"]);
            }
            default: {
                return false;
            }
        }
    }

    public isEmailExist(email: string): Observable<ApiResponse[]> {
        return this.service.postData(
            `${
                this.constants.validateAspNetUserEmailCheck
            }${email.toLowerCase()}`,
            {}
        );
    }

    public redirectBasedOnFilingType(filingTypeId: number, filingId: number) {
        if (filingTypeId && filingTypeId != 0) {
            switch (filingTypeId) {
                case FilingTypeEnum.FilingCertificationReport: {
                    this.router.navigate(["/filing/certification/" + filingId]);
                    break;
                }
                case FilingTypeEnum.MajorContributionsReport: {
                    this.router.navigate([
                        "/filing/major-contribution-report/" + filingId,
                    ]);
                    break;
                }
                case FilingTypeEnum.GiftsReport: {
                    this.router.navigate(["/committee-information/lobby-info"]);
                    break;
                }
                case FilingTypeEnum.CityItemsReport: {
                    this.router.navigate(["/dashboard/ie-home"]);
                    break;
                }
                case FilingTypeEnum.Lobbyists: {
                    this.router.navigate(["/committee-info-dashboard"]);
                    break;
                }
                case FilingTypeEnum.IndependentExpenditure:
                case FilingTypeEnum.AdHocReport: {
                    this.router.navigate(["/filing/review-filing-detail"]);
                    break;
                }
                case FilingTypeEnum.CampaignFinanceReport: {
                    this.router.navigate([
                        "/system/management/campaign-finance-report/submitted/" +
                            filingId,
                    ]);
                    break;
                }

                case FilingTypeEnum.FefWithdrawal:
                    this.router.navigate([
                        "/system/Management/fair-election-funding/withdraw",
                    ]);
                    break;
                case FilingTypeEnum.FefApplication: {
                    const id = this.localStore.getLocalStorage(
                        this.urls.AdminSelectedUserFilers
                    )?.selectedFiler?.filerId;
                    this.router.navigate([
                        "/system/Management/fair-election-funding/" +
                            id +
                            "/application",
                    ]);
                    break;
                }
                case FilingTypeEnum.FefIntent: {
                    const id = this.localStore.getLocalStorage(
                        this.urls.AdminSelectedUserFilers
                    )?.selectedFiler?.filerId;
                    this.router.navigate([
                        "/system/Management/fair-election-funding/" +
                            id +
                            "/intent",
                    ]);
                    break;
                }

                default: {
                    break;
                }
            }
        }
    }

    checkIfCertificationFilingAvailable(): Observable<boolean> {
        return this.service.getData(
            `${this.urls.checkIfCertificationFilingAvailable}`
        );
    }
}
// Shared/Common methods

@Injectable()
export class CommonMethods {
    isReloaded(flag = "true") {
        sessionStorage.setItem("reloaded", flag);
    }
    getIsReloaded() {
        return sessionStorage.getItem("reloaded") || "false";
    }
    downloadCSV(ObjList, header, filename) {
        if (ObjList.length == 0) return;
        var csvData = this.ConvertToCSV(ObjList, header);
        var blob = new Blob([csvData], { type: "text/csv" });
        var url = window.URL.createObjectURL(blob);
        const nav = window.navigator as any;
        if (nav.msSaveOrOpenBlob) {
            nav.msSaveOrOpenBlob(blob, "Test");
        } else {
            var a = document.createElement("a");
            a.href = url;
            a.download = filename + ".csv";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
        window.URL.revokeObjectURL(url);
    }

    ConvertToCSV(objArray: any, header: any): string {
        const separator = ",";
        let str =
            header.join(separator) +
            "\r\n" +
            objArray
                .map((row) => {
                    return Object.values(row)
                        .map((k) => {
                            let cell =
                                k === null || k === undefined ? "" : (k as any);
                            cell =
                                cell instanceof Date
                                    ? cell.toLocaleString()
                                    : cell.toString().replace(/"/g, '""');
                            if (cell.search(/("|,|\n)/g) >= 0) {
                                cell = `"${cell}"`;
                            }
                            return cell;
                        })
                        .join(separator);
                })
                .join("\r\n");
        return str;
    }

    getFilerTypeName(filerTypeId: number): string {
        switch (filerTypeId) {
            case FilerTypeEnum.Campaign:
                return FilerTypeStringEnum.Campaigns;
            case FilerTypeEnum.EthicsFiler:
                return FilerTypeStringEnum.EthicsFiler;
            case FilerTypeEnum.IndependentExpenditure:
                return FilerTypeStringEnum.IndependentExpenditure;
            case FilerTypeEnum.Lobbyists:
                return FilerTypeStringEnum.Lobbyists;
            default:
                return "";
        }
    }

    getFilerTypeId(filerType: string): number {
        switch (filerType) {
            case FilerTypeStringEnum.Campaigns:
                return FilerTypeEnum.Campaign;
            case FilerTypeStringEnum.EthicsFiler:
                return FilerTypeEnum.EthicsFiler;
            case FilerTypeStringEnum.IndependentExpenditure:
                return FilerTypeEnum.IndependentExpenditure;
            case FilerTypeStringEnum.Lobbyists:
                return FilerTypeEnum.Lobbyists;
            default:
                return 0;
        }
    }
}
