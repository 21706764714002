import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, of } from "rxjs";
import {
    catchError,
    distinctUntilChanged,
    filter,
    map,
    shareReplay,
    switchMap,
} from "rxjs/operators";
import { Lobbyists } from "src/app/app.model";
import { ClientService, MasterUrlService } from "src/app/core";

@Injectable()
export class ActiveLobbyistRegistrationService {
    readonly target$: Observable<number>;
    readonly details$: Observable<Lobbyists>;
    readonly name$: Observable<string>;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly urls: MasterUrlService,
        private readonly client: ClientService
    ) {
        this.target$ = this.route.paramMap.pipe(
            map((params) => +(params.get("id") ?? 0)),
            filter((_) => !!_),
            distinctUntilChanged(),
            shareReplay(1)
        );

        this.details$ = this.target$.pipe(
            switchMap((id) =>
                this.client
                    .getData(
                        `${this.urls.lobbyistContactInformation}?filerId=${id}`
                    )
                    .pipe(catchError(() => of(null)))
            ),
            filter((_) => !!_),
            shareReplay(1)
        );

        this.name$ = this.details$.pipe(map(({ name }) => name));
    }
}
