import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Output,
    ViewEncapsulation,
    Input
} from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";

@Component({
    selector: "app-login-form",
    templateUrl: "./login-form.component.html",
    styleUrls: ["./login-form.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent {
    @Input() set email(value: string) {
        this.loginForm.controls.email.setValue(value);
    }
    showPassword = false;

    private readonly nn = this.fb.nonNullable;

    constructor(private readonly fb: FormBuilder) {}

    readonly loginForm = this.fb.group({
        password: this.nn.control("", [Validators.required]),
        rememberMe: this.nn.control(false),
        email: this.nn.control("", [Validators.required, Validators.email]),
    });

    @Output() loginRequest = new EventEmitter<typeof this.loginForm.value>();

    logIn() {
        if (this.loginForm.valid) {
            this.loginRequest.emit(this.loginForm.value);
        } else {
            this.loginForm.markAllAsTouched();
            this.loginForm.updateValueAndValidity();
        }
    }

    onKeyPress(event: KeyboardEvent) {
        if (event.key === 'Enter') {
          event.preventDefault();
          this.logIn();
        }
    }
}
