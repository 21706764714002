import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MasterUrlService } from "src/app/core";
import { ClientService } from "src/app/core/api-services/client.service";

@Component({
    selector: "app-current-committee-bank-information",
    templateUrl: "./current-committee-bank-information.component.html",
    styleUrls: ["./current-committee-bank-information.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentCommitteeBankInformationComponent implements OnInit {
    @Input() committeeId: number;

    data$: Observable<{
        bankName: string;
        bankAddress1: string;
        bankAddress2: string;
        bankCity: string;
        bankStateName: string;
        bankZip: string;
    }>;

    constructor(
        private readonly service: ClientService,
        private readonly urlService: MasterUrlService
    ) {}

    ngOnInit(): void {
        this.data$ = this.service
            .getData(this.urlService.GetBankInfo + this.committeeId)
            .pipe(map(([value, ..._]) => value));
    }
}
