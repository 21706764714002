import {
    Component,
    HostListener,
    Input,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { DialogContentBase, DialogRef } from "@progress/kendo-angular-dialog";

@Component({
    selector: "app-confirm-popup",
    templateUrl: "./confirm-popup.component.html",
    styleUrls: ["./confirm-popup.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ConfirmPopupComponent extends DialogContentBase implements OnInit {
    @Input() title: string;
    @Input() message: string;
    @Input() cancelText: string;
    @Input() confirmText: string;

    constructor(public dialog: DialogRef) {
        super(dialog);
    }

    ngOnInit() {}

    public onCancelAction = () => this.dialog.close();

    public onConfirmAction = () => this.dialog.close({ text: "ok" });

    @HostListener("keydown.esc")
    public onEsc = () => this.onCancelAction();
}
