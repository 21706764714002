import { Component, Input } from "@angular/core";

@Component({
    selector: "section-heading",
    templateUrl: './section-heading.component.html'
  })
  export class SectionHeadingComponent {
    @Input() backButton: boolean;
    @Input() title: string;
    @Input() subTitle: string;
    @Input() public back: (param:any) => void;
  }