import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslocoService } from "@ngneat/transloco";
import { Observable, Subject, merge, partition } from "rxjs";
import {
    mapTo,
    switchMap,
    filter,
    share,
    startWith,
    tap,
} from "rxjs/operators";
import {
    ErrorMessageService,
    MasterDataService,
    SnackbarService,
} from "src/app/core";
import { AccountService } from "../../services/account.service";

@Component({
    selector: "app-manage-change-password",
    templateUrl: "./manage-change-password.component.html",
    styleUrls: ["./manage-change-password.component.scss"],
})
export class ManageChangePasswordComponent implements OnInit {
    showPassword: boolean = false;
    changePasswordForm: FormGroup;

    private changePasswordSubject$: Subject<boolean> = new Subject<boolean>();
    sendingChangePasswordRequest$: Observable<boolean>;

    constructor(
        private readonly accountService: AccountService,
        private readonly formBuilder: FormBuilder,
        private readonly formatting: MasterDataService,
        public readonly errorService: ErrorMessageService,
        private readonly snackbarService: SnackbarService,
        private readonly translocoService: TranslocoService
    ) {}

    ngOnInit(): void {
        this.changePasswordForm = this.formBuilder.group({
            oldPassword: [
                null,
                [
                    Validators.required,
                    Validators.pattern(this.formatting.password),
                    Validators.minLength(8),
                    Validators.maxLength(16),
                ],
            ],
            newPassword: [
                null,
                [
                    Validators.required,
                    Validators.pattern(this.formatting.password),
                    Validators.minLength(8),
                    Validators.maxLength(16),
                    this.formatting.matchValidator("confirmPassword", true),
                ],
            ],
            confirmPassword: [
                null,
                [
                    Validators.required,
                    this.formatting.matchValidator("newPassword"),
                ],
            ],
        });

        const sendChangePasswordRequest$ = this.changePasswordSubject$.pipe(
            filter((x) => x),
            switchMap(this.changePassword),
            share()
        );

        const [success$, fail$] = partition(
            sendChangePasswordRequest$,
            (x) => x
        );

        this.sendingChangePasswordRequest$ = merge(
            success$.pipe(
                tap((_) =>
                    this.snackbarService.snackbarInfo(
                        this.translocoService.translate(
                            "changePassword.yourPasswordWasSuccessfullyChanged"
                        )
                    )
                ),
                tap((_) => this.changePasswordForm.reset()),
                mapTo(false)
            ),
            fail$,
            this.changePasswordSubject$.pipe(filter((x) => x))
        ).pipe(startWith(false));
    }

    requestChangePassword = () => {
        if (this.changePasswordForm.valid)
            this.changePasswordSubject$.next(true);
        else {
            this.changePasswordForm.markAllAsTouched();
            this.changePasswordForm.updateValueAndValidity();
        }
    };

    changePassword = () =>
        this.accountService.changePassword(
            this.changePasswordForm.value.oldPassword,
            this.changePasswordForm.value.newPassword
        );
}
