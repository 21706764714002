import {Component, OnInit, HostListener, ViewEncapsulation, Input} from "@angular/core";
import { DialogContentBase, DialogRef } from "@progress/kendo-angular-dialog";

@Component({
    selector: "app-contact-update-check",
    templateUrl: "./contact-update-check.component.html",
    styleUrls: ["./contact-update-check.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ContactUpdateCheckComponent extends DialogContentBase implements OnInit {
    @Input() contactName: string;

    constructor(public dialog: DialogRef) { super(dialog); }

    ngOnInit(): void {}

    public onCloseAction = () => this.dialog.close();

    public onUpdateAction = () => this.dialog.close({ text: 'update' });

    public onNewAction = () => this.dialog.close({ text: 'new' });

    @HostListener("keydown.esc")
    keyboardClose = () => this.dialog.close();
}
