import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, merge, Subject } from "rxjs";
import {
    distinctUntilChanged,
    filter,
    map,
    switchMap,
    takeUntil,
} from "rxjs/operators";
import { AccountService } from "../../services/account.service";
import { UserDetails } from "../../models/user-details.model";

enum CurrentPage {
    EmailVerificationForm,
    LoginForm,
    MFAVerificationForm,
    Loading,
}

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, OnDestroy {
    readonly CurrentPageEnum = CurrentPage;

    private readonly login$ = new BehaviorSubject<Partial<UserDetails>>(null);
    private readonly verifyingMfa$ = new Subject<void>();
    private readonly destroy$ = new Subject<void>();

    readonly email$ = this.login$.pipe(map((it) => it?.email ?? ""));

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly account: AccountService
    ) {}

    private readonly loginAction$ = this.login$.pipe(filter((it) => !!it));

    ngOnInit(): void {
        const action$ = this.loginAction$.pipe(
            switchMap((it) => this.doLogin(it)),
            takeUntil(this.destroy$)
        );

        action$.subscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    private readonly loginForm$ = this.account
        .getUserStatusType("NoUser")
        .pipe(map(() => CurrentPage.LoginForm));

    private readonly mfaForm$ = this.account
        .getUserStatusType("RequiresMFA")
        .pipe(map(() => CurrentPage.MFAVerificationForm));

    private readonly emailForm$ = this.account
        .getUserStatusType("RequiresConfirmation")
        .pipe(map(() => CurrentPage.EmailVerificationForm));

    private readonly redirectingWhenLoggedIn$ = this.account
        .getUserStatusType("LoggedIn")
        .pipe(
            switchMap(() =>
                this.route.queryParamMap.pipe(
                    map((params) => params.get("returnUrl")),
                    switchMap((url) => this.redirect(url))
                )
            )
        );

    private readonly loading$ = merge(
        this.loginAction$,
        this.redirectingWhenLoggedIn$,
        this.verifyingMfa$
    ).pipe(map(() => CurrentPage.Loading));

    readonly currentPage$ = merge(
        this.loading$,
        this.emailForm$,
        this.mfaForm$,
        this.loginForm$
    ).pipe(distinctUntilChanged());

    private redirect(url: string) {
        return !url
            ? this.router.navigate(["switch"], { queryParams: { login: 1 } })
            : this.router.navigateByUrl(url);
    }

    private doLogin(data: Partial<UserDetails>) {
        return this.account.loginUser(
            data.email,
            data.password,
            data.rememberMe,
            "login.invalidLoginAttempt"
        );
    }

    loginRequest(e: Partial<UserDetails>) {
        this.login$.next(e);
    }

    confirmationSuccess() {
        this.login$.next(this.login$.value);
    }

    mfaVerificationSuccess = () => this.verifyingMfa$.next();
}
