import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeleteModalComponent extends DialogContentBase implements OnInit {

    constructor(public dialog: DialogRef) { super(dialog); }

    ngOnInit() { }

    public onNoAction = () => this.dialog.close();

    public onYesAction = () => this.dialog.close({ text: 'yes' });
}
