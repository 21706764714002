import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { Subject } from 'rxjs';
import { MasterDataService, SharedService } from 'src/app/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ConfirmPopupComponent } from "src/app/shared/components/confirm-popup/confirm-popup.component";
import { TranslocoService } from '@ngneat/transloco';
import { DialogService } from '@progress/kendo-angular-dialog';
export interface CampaignFinanceReportLoanTransactionResponseApiModel {
  contactId: number;
  contactTypeId: number;
  contactTypeName: string;
  loanId: number;
  loanTypeId: number;
  loanType: string;
  amount: number;
  dateOfLoan?: Date;
  lenderName: string;
  address1: string;
  city: string;
  stateCode: string;
  zip: string;
  guarantor: string;
  amountGuaranteed?: number;
  interestTerms: string;
  dueDate?: Date;
  totalForgiven: number;
  totalRepaid: number;
  balance: number;
  filerId: number;
  campaignFinanceReportLoanSubTransactionResponseApiModel: CampaignFinanceReportLoanSubTransactionResponseApiModel[];
}

export interface CampaignFinanceReportLoanSubTransactionResponseApiModel {
  loanSubTransactionId: number;
  amount: number;
  subLoanDate?: Date;
  loanId: number;
  loanSubTransactionTypeId: number;
  loanSubTransactionType: string;
}

interface GetfilerLoandetailsApiModel {
  id: number;
  lendername: string;
  address1: string;
  city: string;
  statecode: string;
  zip: string;
  loanTypeName: string,
  loanAmount: number | null;
  guarantorName: string;
  guaranteedAmount: number | null;
  interestRate: string;
  loanDate: Date | null;
  dueDate: Date | null;
  totalForgiven: number;
  totalRepaid: number,
  balance: number;
  loanPayments: LoanPayment[]
}

interface LoanPayment {
  paymentName: string;
  paymentDate: Date;
  amount: number;
}

@Component({
  selector: 'app-loans-grid',
  templateUrl: './loans-grid.component.html',
  styleUrls: ['./loans-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoansGridComponent implements OnInit, OnDestroy {
  @Input() hideActions: boolean = false;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  @Input() data: CampaignFinanceReportLoanTransactionResponseApiModel[] = [];
  @Output() onSaveNote: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();

  mySelection: string[] = []
  public expandedDetailKeys: any[] = [];
  private _unsubscribeAll: Subject<any>;
  @Input() isAdmin: boolean = false;
  isPublicView: boolean = false;
  noteWindow: boolean = false;
  addNoteForm: UntypedFormGroup;
  constructor(
    public formatting: MasterDataService,
    public router: Router,
    private sharedService: SharedService,
    private formBuilder: UntypedFormBuilder,
    private readonly translocoService: TranslocoService,
    private readonly dialogService: DialogService,
  ) {
    this.addNoteForm = this.formBuilder.group({
      recordId: new UntypedFormControl(''),
      notetext: new UntypedFormControl('', Validators.required)
    });
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.isPublicView = this.sharedService.isPublicView();

    let filter;
    if (this.isPublicView) {
      filter = this.sharedService.getCurrentSelectedPublicFiler();
    } else {
      filter = this.sharedService.getCurrentSelectedUserFiler();
    }

    if (filter) {
      this.isAdmin = filter.isAdmin;
    }

  }

  public expandDetailsBy = (dataItem: CampaignFinanceReportLoanTransactionResponseApiModel): any => {
    return dataItem.loanId;
  }

  saveNote(){
   this.onSaveNote.emit(this.addNoteForm.value);

  }
  editLoan(loan: CampaignFinanceReportLoanTransactionResponseApiModel){
    this.onEdit.emit({id: loan.loanId, type: 'loan'});
  }

  deleteTransaction(transactionId : number, hasSubtransactions : boolean) {
      if (hasSubtransactions) {
          const dialogRef = this.dialogService.open({ content: ConfirmPopupComponent });
          const userInfo = dialogRef.content.instance as ConfirmPopupComponent;
          userInfo.title = this.translocoService.translate('alert');
          userInfo.message = this.translocoService.translate('transactions.allLoanSubtransactionsMustBeDeletedBeforeTheLoanCanBeDeleted');
          userInfo.cancelText = '';
          userInfo.confirmText = this.translocoService.translate('accept');
      } else {
          this.onDelete.emit({id: transactionId, type: 'loan'});
      }
  }

  addNote(event: any) {
    this.addNoteForm.controls.recordId.setValue(event.loanId);
    this.noteWindow = true;
  }
  closeNote() {
    this.noteWindow = false;
    this.addNoteForm.reset();
  }

  ngOnDestroy() {
    this._unsubscribeAll.unsubscribe();
  }
}
