import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeLayoutComponent } from "../../shared/components/home-layout/home-layout.component";
import { HelpComponent } from "../helps/help/help.component";
import { ComingSoonComponent } from "./coming-soon/coming-soon.component";

const routes: Routes = [
    {
        path: "coming-soon",
        component: ComingSoonComponent,
    },

    {
        path: "",
        component: HomeLayoutComponent,
        children: [
            {
                path: "",
                
                component: HelpComponent,
            },  
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class HelpsRoutingModule {}
