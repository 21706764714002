import { Component, OnInit, Input } from "@angular/core";
import { BehaviorSubject, from, Observable, partition } from "rxjs";
import {
    distinctUntilChanged,
    map,
    mapTo,
    mergeAll,
    share,
    shareReplay,
    skip,
    switchMap,
    tap,
} from "rxjs/operators";
import { ApiResponse } from "src/app/app.model";
import { ClientService } from "src/app/core/api-services/client.service";
import {
    MasterUrlService,
    SnackbarService,
} from "../../../core";
import { TranslocoService } from "@ngneat/transloco";

type FilingStatus = { id: number; name: string };
type RawRequest = { status: number; notes: string };
type Response = ApiResponse<number | boolean>;

type PreparedRequest = {
    filingId: number;
    notes?: string;
    filingStatusId?: number;
};

type Bundle = { route: string } & PreparedRequest;

@Component({
    selector: "app-filing-notes",
    templateUrl: "./filing-notes.component.html",
    styleUrls: ["./filing-notes.component.css"],
})
export class FilingNotesComponent implements OnInit {
    @Input() filingId: number;
    @Input() note: string = "";
    @Input() currentStatus: number;

    constructor(
        private readonly urls: MasterUrlService,
        private readonly client: ClientService,
        private readonly snackbar: SnackbarService,
        private translocoService: TranslocoService
    ) {}

    loading$: Observable<boolean>;
    private readonly saveRequest$ = new BehaviorSubject<RawRequest>(null);

    readonly availableStatuses: FilingStatus[] = [
        { id: 1, name: "Submitted" },
        { id: 2, name: "Needs Information" },
        { id: 3, name: "Needs Audit" },
        { id: 4, name: "Approved" },
        { id: 5, name: "Approved (Not Audited)" },
        { id: 6, name: "Audit Completed" },
        { id: 7, name: "In Audit Process" },
        { id: 8, name: "Not Approved" },
        { id: 9, name: "Draft" },
    ];

    ngOnInit(): void {
        const saveNote$ = this.saveRequest$.pipe(
            distinctUntilChanged(this.onlyNoteChanged),
            skip(1),
            map(this.saveNote)
        );

        const saveStatus$ = this.saveRequest$.pipe(
            distinctUntilChanged(this.onlyStatusChanged),
            skip(1),
            map(this.saveStatus)
        );

        const saveBoth$ = this.saveRequest$.pipe(
            distinctUntilChanged(this.allPropsChanged),
            skip(1),
            map(this.saveBoth)
        );

        const validRequest$ = from([saveNote$, saveStatus$, saveBoth$]).pipe(
            mergeAll(),
            tap((_) => console.log(_)),
            share()
        );

        const start$ = validRequest$.pipe(mapTo(true));

        const saving$ = validRequest$.pipe(switchMap(this.doSave));

        const [success$, failure$] = partition(saving$, (_) => !!_?.pkId);

        const whenSuccess$ = success$.pipe(
            tap(() => this.snackbar.snackbarSuccess(this.translocoService.translate('lobbyingByMonthly.recordSaved'))),
            mapTo(false)
        );

        const whenFailure$ = failure$.pipe(
            tap(() => this.snackbar.snackbarError(this.translocoService.translate('lobbyingByMonthly.failedToSaveYourChanges'))),
            mapTo(false)
        );

        const complete$ = from([whenSuccess$, whenFailure$]).pipe(mergeAll());

        this.loading$ = from([start$, complete$]).pipe(
            mergeAll(),
            shareReplay()
        );

        this.saveRequest$.next({
            notes: this.note,
            status: this.currentStatus,
        });
    }

    private readonly onlyNoteChanged = (
        a: RawRequest,
        b: RawRequest
    ): boolean => a.notes === b.notes || a.status !== b.status;

    private readonly onlyStatusChanged = (
        a: RawRequest,
        b: RawRequest
    ): boolean => a.notes !== b.notes || a.status === b.status;

    private readonly allPropsChanged = (
        a: RawRequest,
        b: RawRequest
    ): boolean => a.notes === b.notes || a.status === b.status;

    private readonly asSingle = ([res, ..._]: Response[]): Response => res;

    private readonly saveNote = ({ notes }: RawRequest) => ({
        filingId: this.filingId,
        route: this.urls.updateFilingNote,
        notes,
    });

    private readonly saveStatus = ({ status }: RawRequest) => ({
        filingId: this.filingId,
        route: this.urls.updateFilingStatus,
        filingStatusId: status,
    });

    private readonly saveBoth = ({ notes, status }: RawRequest) => ({
        filingId: this.filingId,
        route: this.urls.updateFilingNoteOrStatus,
        filingStatusId: status,
        notes,
    });

    private readonly doSave = ({
        route,
        ...data
    }: Bundle): Observable<Response> =>
        this.client.postData(route, data).pipe(map(this.asSingle));

    save(): void {
        this.saveRequest$.next({
            status: this.currentStatus,
            notes: this.note,
        });
    }
}
