import { Component, Input, OnInit } from "@angular/core";
import { SummaryItemModel } from "../../../shared/models/app-summary-item.model";
@Component({
    selector: "app-committee-summary",
    templateUrl: "./committee-summary.component.html",
    styleUrls: ["./committee-summary.component.scss"],
})
export class CommitteeSummaryComponent implements OnInit {
    @Input() summary: SummaryItemModel[];

    constructor() {}

    ngOnInit(): void {}
}
