import { formatDate } from "@angular/common";
import {
    Component,
    Input,
    OnInit,
    ViewEncapsulation,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter,
} from "@angular/core";
import { BehaviorSubject, from, Observable } from "rxjs";
import { filter, map, mergeAll, shareReplay, switchMap } from "rxjs/operators";
import { MasterDataService, MasterUrlService } from "src/app/core";
import { ClientService } from "src/app/core/api-services/client.service";
import { UserEntityDetailsResponseApiModel as Model } from "../../../modules/committee-information/committee-information.model";
import {
    CommitteeTypeEnums as CommitteeType,
    FilerTypeEnum as FilerType,
    FilerTypeEnum,
    PositionTypeEnum,
} from "../../models";
import { TranslocoService } from "@ngneat/transloco";

type ListItem = { label: string; value: string | boolean | number };
type LoadRequest = { id: number; type: FilerType };

@Component({
    selector: "app-current-committee-information",
    templateUrl: "./current-committee-information.component.html",
    styleUrls: ["./current-committee-information.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class CurrentCommitteeInformationComponent implements OnInit, OnChanges {
    @Input() committeeInformation: Model;
    @Input() filerType: number;
    @Input() showHeader: boolean = true;
    @Input() header: string;
    @Input() center: boolean = false;
    @Input() type: FilerType;
    @Input() id: number;
    @Output() committeeType = new EventEmitter<CommitteeType>();

    data$: Observable<ListItem[]>;
    private readonly inputChanges$ = new BehaviorSubject<Model>(null);
    private readonly loadRequests$ = new BehaviorSubject<LoadRequest>(null);
    ballotIssuesSupportArray: String[];
    ballotIssuesOpposeArray: String[];

    constructor(
        private readonly formatting: MasterDataService,
        private readonly client: ClientService,
        private readonly urls: MasterUrlService,
        private readonly translocoService: TranslocoService
    ) {}

    ngOnInit(): void {
        const load$ = this.loadRequests$.pipe(
            filter((_) => !!_),
            switchMap(this.loadEntity)
        );
        const feed$ = this.inputChanges$.pipe(filter((_) => !!_));

        this.data$ = from([load$, feed$]).pipe(
            mergeAll(),
            map(this.prepareInfo),
            shareReplay()
        );
        if (this.committeeInformation) {
            this.inputChanges$.next(this.committeeInformation);
        } else if (this.type && this.id) {
            this.loadRequests$.next({ id: this.id, type: this.type });
        }
    }

    private readonly loadEntity = ({
        id,
        type,
    }: LoadRequest): Observable<Model> =>
        this.client.getData(
            `${this.urls.getUserEntitiesDetails}?entityId=${id}&filerTypeId=${type}`
        );

    ngOnChanges(changes: SimpleChanges): void {
        const info = changes.committeeInformation;
        const type = changes.type;
        const uuid = changes.id;
        if (info.currentValue?.entityId !== info.previousValue?.entityId) {
            this.inputChanges$.next(info.currentValue);
        } else if (
            type?.currentValue !== type?.previousValue ||
            uuid?.currentValue !== uuid?.previousValue
        ) {
            this.loadRequests$.next({
                id: uuid.currentValue,
                type: type.currentValue,
            });
        }
    }

    private readonly prepareInfo = (source: Model): ListItem[] => {
        this.ballotIssuesSupportArray = [];
        this.ballotIssuesOpposeArray = [];
        const isCommittee = source?.filerTypeId === FilerType.Campaign;

        if (isCommittee) {
            this.committeeType.emit(source?.committeeTypeId);
        }

        const shouldDisplayCandidate =
            isCommittee &&
            source?.committeeTypeId === CommitteeType.CandidateCommittee;

        const shouldDisplayDistrict =
            shouldDisplayCandidate && source?.office === "City Council";

        const shouldDisplayBallot =
            isCommittee &&
            source?.committeeTypeId === CommitteeType.IssueCommittee;

        let builder: ListItem[];

        switch (source?.filerTypeId) {
            case FilerTypeEnum.IndependentExpenditure:
            case FilerTypeEnum.Campaign:
                builder = [
                    {
                        label:
                            this.filerType == FilerType.IndependentExpenditure
                                ? this.translocoService.translate(
                                      "switch.filerName"
                                  )
                                : this.translocoService.translate(
                                      "switch.committeeName"
                                  ),
                        value: source?.committeeName ?? source?.entityName,
                    },
                    {
                        label:
                            this.filerType == FilerType.IndependentExpenditure
                                ? this.translocoService.translate(
                                      "switch.filerId"
                                  )
                                : this.translocoService.translate(
                                      "switch.committeeId"
                                  ),
                        value: source?.filerId,
                    },
                    {
                        label: this.translocoService.translate(
                            "switch.candidateName"
                        ),
                        value: shouldDisplayCandidate
                            ? source?.candidateName
                            : null,
                    },
                    {
                        label: this.translocoService.translate("switch.office"),
                        value: shouldDisplayCandidate ? source?.office : null,
                    },
                    {
                        label: this.translocoService.translate(
                            "switch.district"
                        ),
                        value: shouldDisplayDistrict ? source?.district : null,
                    },
                    {
                        label: this.translocoService.translate(
                            "switch.electionDate"
                        ),
                        value: shouldDisplayBallot
                            ? null
                            : source?.electionDate
                            ? formatDate(
                                  source.electionDate,
                                  this.formatting.dateFormat,
                                  "en_US",
                                  "formatting.timezone"
                              )
                            : null,
                    },
                ];
                break;
            case FilerTypeEnum.Lobbyists:
                builder = [
                    {
                        label: this.translocoService.translate(
                            "switch.lobbyistName"
                        ),
                        value: source?.entityName,
                    },
                    {
                        label: this.translocoService.translate(
                            "switch.lobbyistId"
                        ),
                        value: source?.filerId,
                    },
                    {
                        label: this.translocoService.translate(
                            "switch.lobbyistType"
                        ),
                        value: source?.entityType,
                    },
                    {
                        label: this.translocoService.translate(
                            "switch.lobbyistName"
                        ),
                        value:
                            source?.entityType === "Organization"
                                ? source?.primaryName
                                : undefined,
                    },
                ];
                break;
            case FilerTypeEnum.EthicsFiler:
                builder = [
                    {
                        label: this.translocoService.translate(
                            "switch.filerName"
                        ),
                        value: source?.entityName,
                    },
                    {
                        label: this.translocoService.translate(
                            "switch.filerId"
                        ),
                        value: source?.filerId,
                    },
                ];
                break;
            case FilerTypeEnum.Admin:
                builder = [
                    {
                        label: this.translocoService.translate(
                            "switch.userName"
                        ),
                        value: source?.entityName,
                    },
                    {
                        label: this.translocoService.translate("switch.access"),
                        value: "Administrator",
                    },
                ];
                break;
        }

        if (shouldDisplayBallot) {
            // TODO: What to do when there is more than one ballot_issue
            if (source?.ballotIssues.length === 0) {
                builder.push(
                    {
                        label: this.translocoService.translate(
                            "switch.purpose"
                        ),
                        value: source?.purposeDesc,
                    },
                    {
                        label: this.translocoService.translate(
                            "switch.position"
                        ),
                        value: source?.ballotIssueNotes,
                    }
                );
            } else {
                source?.ballotIssues.forEach((x) => {
                    if (x.ballotIssue != null) {
                        if (x.positionTypeId == PositionTypeEnum.Support)
                            this.ballotIssuesSupportArray.push(x.ballotIssue);
                        else this.ballotIssuesOpposeArray.push(x.ballotIssue);
                    }
                });

                builder.push(
                    {
                        label: this.translocoService.translate(
                            "switch.purpose"
                        ),
                        value: source?.purposeDesc,
                    },
                    {
                        label: this.translocoService.translate(
                            "switch.support"
                        ),
                        value:
                            this.ballotIssuesSupportArray.length > 0
                                ? "ballotIssuesSupportArray"
                                : null,
                    },
                    {
                        label: this.translocoService.translate("switch.oppose"),
                        value:
                            this.ballotIssuesOpposeArray.length > 0
                                ? "ballotIssuesOpposeArray"
                                : null,
                    }
                );
            }

            builder.push({
                label: this.translocoService.translate("switch.electionDate"),
                value: source?.electionDate
                    ? formatDate(
                          source.electionDate,
                          this.formatting.dateFormat,
                          "en_US",
                          "formatting.timezone"
                      )
                    : null,
            });
        }

        return builder.filter(this.isNotEmptyOrNull);
    };

    private readonly isNotEmptyOrNull = (_: {
        label: string;
        value: string | number;
    }): boolean =>
        !!(typeof _.value === "string" ? _.value.trim().length : _.value);
}
