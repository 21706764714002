import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-client-grid',
  templateUrl: './client-grid.component.html',
  styleUrls: ['./client-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ClientGridComponent implements OnInit {
  
  @Input() data: any[];

  constructor() { }

  ngOnInit(): void {
  }
  
}
