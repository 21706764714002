export enum CommitteeOrCandidateEnums {
    Committee = 1,
    Candidate = 2,
}

export enum CommitteeTypeEnums {
    CandidateCommittee = 1,
    NewCommitee = 2,
    PACCommittee = 3,
    IndependentFiler = 4,
    FilerType = 5,
    CityStaffs = 6,
    SmallDonorCommittee = 7,
    IssueCommittee = 8,
    CoveredOfficials = 9,
}

export enum FilerTypeEnum {
    AllFilers = 0,
    Lobbyists = 1,
    IndependentExpenditure = 2,
    Campaign = 3,
    EthicsFiler = 4,
    Unaffiliated = 5,
    Admin = 1000,
}

export enum FilerStatusEnum {
    Terminated = 1,
    Inactive = 2,
    New = 3,
    Active = 4,
}

export enum CommitteeStatusEnum {
    New = 1,
    Active = 2,
    Inactive = 3,
}

export enum ApplicationUserStatusesEnum {
    Active = 1,
    Inactive = 2,
    Invite = 3,
}

export enum FilingStatusEnum {
    Submitted = 1,
    NeedsInformation = 2,
    NeedsAudit = 3,
    Approved = 4,
    ApprovedNotAudited = 5,
    AuditCompleted = 6,
    InAuditProcess = 7,
    NotApproved = 8,
    Draft = 9,
}

export enum FilerTypeStringEnum {
    Lobbyists = "Lobbyists",
    IndependentExpenditure = "Independent Expenditure",
    Campaigns = "Campaigns",
    EthicsFiler = "Ethics Filer",
    Admin = "Administrator",
}

export enum ElectionTypeEnum {
    AllElections = 0,
    Candidate = 1,
    Issue = 2,
    Special = 3,
}

export enum ElectionTypeStringEnum {
    Candidate = "Candidate",
    Issue = "Issue",
    Special = "Special",
}

export enum IETypeStringEnum {
    Organization = "Organization",
    Individual = "Individual",
}

export enum ContactTypeEnum {
    Individual = 1,
    Organization = 2,
    Committee = 3,
}

export enum LobbyistsTypeEnum {
    Individual = 1,
    Organization = 2,
}

export enum ElectionCycleStatusEnum {
    Active = 1,
    Completed = 2,
    Canceled = 3,
}

export enum FilingTypeEnum {
    GiftsReport = 1,
    CityItemsReport = 2,
    Lobbyists = 3,
    IndependentExpenditure = 4,
    CampaignFinanceReport = 5,
    AdHocReport = 6,
    FefIntent = 7,
    FefApplication = 8,
    FefWithdrawal = 9,
    FinancialDisclosureStatement = 10,
    CfrExistingFiling = 394,
    GiftsEmployeeReport = 11,
    EthicsFinancialDisclosureStatement = 12,
    MajorContributionsReport = 13,
    FilingCertificationReport = 14,
}

export enum FilingMappingRecordTypeEnum {
    Transaction = 1,
    Loan = 2,
    Gift = 3,
    FinancialDisclosureAssetTransfer = 4,
    FinancialDisclosureBusinessInterest = 5,
    FinancialDisclosureCreditor = 6,
    FinancialDisclosureEmployer = 7,
    FinancialDisclosureLobbyingCompensation = 8,
    FinancialDisclosurePrivateTrust = 9,
    FinancialDisclosurePropertyOwnership = 10,
    FinancialDisclosureSourceofIncome = 11,
}

export enum AdminDocumentTypeEnum {
    CandidateAffidavit = 1,
    TreasurerDocuments = 2,
    Filings = 3,
    WaiverRequests = 4,
    AuditReports = 5,
    Complaints = 6,
    Miscellaneous = 7,
}

export enum TransactionTypeEnum {
    Expenditure = 1,
    UnpaidObligation = 2,
    IndependentExpenditure = 3,
    Loan = 4,
    Contribution = 5,
    FairElectionFund = 6,
}

export enum LoanTypeEnum {
    CommercialLoan = 1,
    PersonalLoan = 2,
}

export enum FEFParticipationStatusTypeEnum {
    NotParticipating = 1,
    NotYetQualified = 2,
    Participating = 3,
    Revoked = 4,
    Withdrawn = 5,
}
export enum VerificationStatusEnum {
    New = 1,
    Success = 2,
    Error = 3,
    Cancel = 4,
}
export enum OfficeTypeEnum {
    New = 1,
    CityCouncil = 2,
    Mayor = 3,
    ClerkandRecorder = 4,
    Treasurer = 5,
    Auditor = 6,
    Other = 7,
}
export enum CommitteeUserTypeEnum {
    Treasurer = 1,
    Officer = 2,
    Candidate = 3,
}
export enum LobbyistUserTypeEnum {
    PrimaryRegisteredLobbyist = 1,
    Employee = 3,
}

export enum IndependentExpenditureTargetTypeEnum {
    BallotIssue = 1,
    Candidate = 2,
}

export enum PositionTypeEnum {
    Support = 1,
    Oppose = 2,
}

export enum IndependentExpenditureFilerTypeEnum {
    Individual = 1,
    Organization = 2,
}

export enum LoanSubTransactionTypeEnum {
    InterestPayment = 1,
    Forgiveness = 2,
    Payment = 3,
}

export enum AccessEthicsUserTypesEnum {
    PrimaryUser = 1,
    SecondaryUser = 2,
}

export enum AccessEthicsTypesEnum {
    Elected = 1,
    Appointed = 2,
    Staff = 3,
    Undetermined = 4,
}

export enum OrganizationTypesEnum {
    "Non-Profit" = 1,
    "For Profit" = 2,
    "Governmental Entity" = 3,
}

export enum ReimbursementEnum {
    "YES" = 1,
    "NO" = 2,
}

export enum DidYouAttendThisEventEnum {
    "City" = 0,
    "Personal" = 1,
}

export enum RedactionStatusEnum {
    None = 0,
    Redacted = 1,
    LikelyMatch = 2,
}

export enum FilingSpecialReportEnum {
    Initial = -1,
    Final = -2
}