import { Component, Input, ViewEncapsulation } from "@angular/core";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { filter, map } from "rxjs/operators";
import { TranslocoService } from "@ngneat/transloco";
import { PersonalContactInformationType } from "../../models/app-committee-registration.models";

@Component({
    selector: "app-current-committee-personal-contact-information",
    templateUrl: "./current-committee-personal-contact-information.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class CurrentCommitteePersonalContactInformationComponent {
    @Input() set details(data: PersonalContactInformationType) { this._details.next(data); }

    private readonly _details = new BehaviorSubject<PersonalContactInformationType>(null);

    constructor(private readonly i18n: TranslocoService) {}

    readonly details$: Observable<{ label: string; value: any }[]> =
        combineLatest([
            this.i18n.selectTranslateObject("committeeRegistration.personalContactInformation"),
            this._details,
        ]).pipe(
            filter(([_, info]) => !!info),
            map(([labels, info]) => [
                { label: labels.personalPhone, value: info?.altPhone, type: "phone" },
                { label: labels.personalEmail, value: info?.altEmail },
            ].filter(x => !!(typeof x.value === "string" ? x.value.trim().length : x.value)))
        );
}
