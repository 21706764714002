import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { FilerInformationService } from "src/app/core";
import { NavigationEnd, Router } from "@angular/router";
import { FilerTypeEnum } from "../models";
import { MenuItem } from "@progress/kendo-angular-menu";
import {
    distinctUntilChanged,
    filter,
    map,
    shareReplay,
    startWith,
} from "rxjs/operators";
import { combineLatest, Observable } from "rxjs";
import { UnreadMessageCountService } from "src/app/core/services/unread-message-count.service";
import { TranslocoService } from "@ngneat/transloco";

@Component({
    selector: "app-sidenavcomponent",
    templateUrl: "./sidenavcomponent.component.html",
    styleUrls: ["./sidenavcomponent.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavComponent implements OnInit {
    isnoentity: any;
    hide$: Observable<boolean>;
    items$: Observable<(MenuItem & { path: string })[]>;
    unread$: Observable<string>;

    constructor(
        private readonly router: Router,
        private readonly filer: FilerInformationService,
        private readonly unread: UnreadMessageCountService,
        private translocoService: TranslocoService
    ) {}

    ngOnInit(): void {
        this.unread$ = this.unread.count$;

        const url$ = this.router.events.pipe(
            filter((_) => _ instanceof NavigationEnd),
            map((_: NavigationEnd) => _.url),
            startWith(this.router.url),
            distinctUntilChanged()
        );

        this.hide$ = url$.pipe(
            map((url) => url?.split(/[/?]/g) ?? []),
            map((pieces) =>
                pieces.some((piece) => this.checks.includes(piece))
            ),
            distinctUntilChanged(),
            shareReplay(1)
        );

        const items$ =
            this.translocoService.selectTranslateObject("headerSection");

        this.items$ = combineLatest([
            this.filer.current$.pipe(
                map((data) => data?.filerTypeId),
                distinctUntilChanged()
            ),
            this.hide$,
            items$,
        ]).pipe(
            map(([current, hideSideNav, items]) =>
                hideSideNav
                    ? []
                    : this.buildLinkList(
                          current,
                          items.manageFilers,
                          items.reviewFiling,
                          items.finesAndFees,
                          items.notification,
                          items.systemManagement,
                          items.calendar,
                          items.filings,
                          items.registration,
                          items.committeeInformation
                      )
            ),
            shareReplay(1)
        );
    }

    public onSelect({ item }): void {
        if (!item.items) {
            this.router.navigate([item.path]);
        }
    }

    private readonly activeClass = (pathCheck: string): boolean =>
        this.router.url.includes(pathCheck);

    private readonly buildMenuItem = (
        text: string,
        path: string,
        icon: string
    ): MenuItem & { path: string } => ({
        text,
        path,
        icon,
        cssClass: { "ml-nav-fix-target": true, active: this.activeClass(path) },
    });

    private readonly checks = [
        "switch",
        "committee-registration",
        "createlobbyist",
        "independent-expenditure-registration",
    ];

    private buildLinkList(
        current: FilerTypeEnum,
        manageFilers: string,
        reviewFiling: string,
        finesAndFees: string,
        notification: string,
        systemManagement: string,
        calendar: string,
        filings: string,
        registration: string,
        committeeInformation: string
    ) {
        let data: (MenuItem & { path: string })[] = [];

        if (
            this.filer.isAdmin &&
            (current === FilerTypeEnum.Admin || !current)
        ) {
            data = data.concat(
                this.buildMenuItem(manageFilers, "/manage/committee", "user"),
                this.buildMenuItem(
                    reviewFiling,
                    "/filing/review-filing",
                    "file-txt"
                ),

                this.buildMenuItem(
                    notification,
                    "/messages/admin/inbox",
                    "notification"
                ),
                this.buildMenuItem(
                    systemManagement,
                    "/system/Management",
                    "gear"
                ),
                this.buildMenuItem(calendar, "/calendar/calendar", "calendar")
            );

            return data;
        }

        if (current === FilerTypeEnum.IndependentExpenditure) {
            data = data.concat(
                this.buildMenuItem(filings, "/dashboard/ie-home", "file-txt"),
                this.buildMenuItem(
                    registration,
                    "/independent-expenditure",
                    "user"
                )
            );
        }

        if (current === FilerTypeEnum.Campaign) {
            data = data.concat(
                this.buildMenuItem(filings, "/dashboard/candidate", "file-txt"),
                this.buildMenuItem(
                    committeeInformation,
                    "/committee-info-dashboard",
                    "user"
                )
            );
        }

        if (current === FilerTypeEnum.Lobbyists) {
            data = data.concat(
                this.buildMenuItem(
                    filings,
                    "/committee-information/lobby-info",
                    "file-txt"
                ),
                this.buildMenuItem(registration, "/lobbyist", "user")
            );
        }

        if (current === FilerTypeEnum.EthicsFiler) {
            data = data.concat(
                this.buildMenuItem(filings, "/ethics-dashboard", "file-txt"),
                this.buildMenuItem(registration, "/ethics/registration", "user")
            );
        }

        return data.concat(
            this.buildMenuItem(notification, "/messages/view", "notification"),
            this.buildMenuItem(calendar, "/calendar/calendar", "calendar")
        );
    }
}
