import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import {
    MasterDataService,
    MasterUrlService,
    ErrorMessageService,
    SnackbarService,
    LocalstorageService,
} from "./index";

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [
        MasterDataService,
        MasterUrlService,
        ErrorMessageService,
        SnackbarService,
        LocalstorageService,
    ],
})
export class CoreModule {}
