import {
    Component,
    OnInit,
    OnDestroy,
    ViewEncapsulation,
    Input,
    EventEmitter,
    Output,
} from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    UntypedFormControl,
    Validators,
} from "@angular/forms";
import { Subject } from "rxjs";
import {
    MasterDataService,
    MasterUrlService,
    SharedService,
} from "src/app/core";
import { Contributions } from "../../../app.model";
import { FillingService } from "../../../core/api-services/filling.service";
import { ClientService } from "../../../core/api-services/client.service";

export interface FEFQContributionsResponseModel extends Contributions {
    monetaryTypeId?: number;
    monetaryTypeName: string;
    fairElectionFundStatusTypeId?: number;
    fairElectionFundStatusName: string;
    publicFundingStatus: string;
    statusReason: string;
    electionCycleId?: number;
    electionCycleName: string;
    contributionLimitError: boolean;
}

@Component({
    selector: "app-fef-qualifying-contributions",
    templateUrl: "./fef-qualifying-contributions.component.html",
    styleUrls: ["./fef-qualifying-contributions.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class FefQualifyingContributionsComponent implements OnInit, OnDestroy {
    addNoteForm: UntypedFormGroup;
    noteWindow: boolean = false;

    @Input() hideActions: boolean = false;

    public expandedDetailKeys: any[] = [];
    private _unsubscribeAll: Subject<any>;
    @Input() data: FEFQContributionsResponseModel[];
    @Output() onSaveNote: EventEmitter<any> = new EventEmitter<any>();
    @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
    @Input() fefparticipationstatus: number;
    isAdmin: boolean = false;
    isPublicView: boolean = false;
    fefHistory: any[] = [];
    displayAttachment: string = "";
    showPopup: boolean = false;
    file: any;
    constructor(
        public filingService: FillingService,
        readonly formatting: MasterDataService,
        private sharedService: SharedService,
        private urlService: MasterUrlService,
        private formBuilder: UntypedFormBuilder,
        private clientService: ClientService
    ) {
        this._unsubscribeAll = new Subject();
        this.addNoteForm = this.formBuilder.group({
            recordId: new UntypedFormControl(""),
            notetext: new UntypedFormControl("", Validators.required),
        });
    }

    ngOnInit(): void {
        this.isPublicView = this.sharedService.isPublicView();

        let filter;
        if (this.isPublicView) {
            filter = this.sharedService.getCurrentSelectedPublicFiler();
        } else {
            filter = this.sharedService.getCurrentSelectedUserFiler();
        }

        if (filter) {
            this.isAdmin = filter.isAdmin;
        }
    }

    public expandDetailsBy = (
        dataItem: FEFQContributionsResponseModel
    ): any => {
        return dataItem.transactionId;
    };

    ngOnDestroy() {
        this._unsubscribeAll.unsubscribe();
    }

    editTransaction(transactionId: number) {
        this.onEdit.emit({ id: transactionId, type: "fefcontribution" });
    }

    deleteTransaction(transactionId: number) {
        this.onDelete.emit({ id: transactionId, type: "monetary" });
    }

    addNote(event: any) {
        this.addNoteForm.controls.recordId.setValue(event.transactionId);
        this.noteWindow = true;
    }
    closeNote() {
        this.noteWindow = false;
        this.addNoteForm.reset();
    }

    saveNote() {
        this.onSaveNote.emit(this.addNoteForm.value);
    }

    downloadFile(data: FEFQContributionsResponseModel) {
        return this.clientService.downloadBlobAttachment(this.urlService.blobAttachment +
                "?attachmentId=" + data.attachmentId).subscribe();
    }
    onViewReceipt(data: FEFQContributionsResponseModel): void {
        this.clientService.getBlobAttachment(this.urlService.blobAttachment +
            "?attachmentId=" + data.attachmentId).subscribe(res => {
                this.showPopup = true;
                this.file = res;   
            });
    }
}
